// import React from "react";
import { Link } from "react-router-dom";
// import { date } from "zod";

const recentOrderData = [
  {
    id: "1",
    product_id: "123456",
    customer_id: "456789",
    customer_name: "Mason Nash",
    order_date: "2022-05-17",
    order_total: "₱12354",
    order_status: "SHIPPED",
    shipment_address: "Tala Caloocan City",
  },
  {
    id: "2",
    product_id: "234567",
    customer_id: "456789",
    customer_name: "Balmond",
    order_date: "2022-05-17",
    order_total: "₱12354",
    order_status: "DELIVERED",
    shipment_address: "Bulacan",
  },
  {
    id: "3",
    product_id: "456781",
    customer_id: "456789",
    customer_name: "Dodong",
    order_date: "2022-05-17",
    order_total: "₱12354",
    order_status: "OUT_FOR_DELIVERY",
    shipment_address: "Bulacan",
  },
  {
    id: "4",
    product_id: "897845",
    customer_id: "456789",
    customer_name: "Juan",
    order_date: "2022-05-17",
    order_total: "₱12354",
    order_status: "SHIPPED",
    shipment_address: "Malaria",
  },
  {
    id: "5",
    product_id: "123458",
    customer_id: "456789",
    customer_name: "Jose",
    order_date: "2022-05-17",
    order_total: "₱12354",
    order_status: "SHIPPED",
    shipment_address: "Bagong Silang City",
  },
];

function RecentOrders() {
  return (
    <div className=" bg-white px-4 pt-3 pb-4 rounded-sm border border-gray-200 flex-1 flex-col">
      <strong className="text-gray-700 font-medium">Recent Order</strong>
      <div className=" mt-3">
        <table className="w-full text-gray-700">
          <thead>
            <tr>
              <td>ID</td>
              <td>Product ID</td>
              <td>Customer Name</td>
              <td>Order Date</td>
              <td>Order Total</td>
              <td>Shipped Address</td>
              <td>Order Status</td>
            </tr>
          </thead>
          <tbody>
            {recentOrderData.map((order) => (
              //   <div>{order.product_id}</div>
              <tr key={order.id}>
                <td>
                  <Link to={"/products/${order.id}"}>#{order.id}</Link>
                </td>
                <td>
                  <Link to={"/products/${order.product_id}"}>
                    {order.product_id}
                  </Link>
                </td>
                <td>{order.customer_name}</td>
                <td>{new Date(order.order_date).toLocaleDateString()}</td>
                <td>{order.order_total}</td>
                <td>{order.shipment_address}</td>
                <td>{order.order_status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default RecentOrders;

import { FaPlus } from "react-icons/fa6"
import { Button } from "../ui/button"
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "../ui/table"
import { useEffect, useState } from "react"
import { AccountBranchesModel, AccountModel } from "@/interfaces/AccountModel"
import { APICallParams, makeAuthenticatedAPICall } from "@/utils/apiCall"
import { useLocation } from "react-router";
import { useNavigate } from 'react-router';
import { toast } from "../ui/use-toast"
import { useAuth } from "@/hooks/useAuth";


function AccountBranches() {
    let login = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [branchData, setBranchData] = useState<AccountBranchesModel[]>()
    const accountDetails: AccountModel = location.state?.accountDetails;

    const loadBranchesWithPalengkeID = async () => {
        try {
            const paengkeID = accountDetails.id
            const uri = "/v1/admin/" + paengkeID + "/branches"
            // const encoded = encodeURI(uri);
            const apiParams: APICallParams = {
                verb: "GET",
                url: uri,
                params: {},
                body: {},
            };
            const response = await makeAuthenticatedAPICall(apiParams);

            if (response.status === 200) {
                setBranchData(response.data)
            }

        } catch (error) {
            console.error("Error during update password:", error);
        }
    }

    const handleRemoveClick = async (tx: number, branchID: number) => {

        const paengkeID = accountDetails.id
        const uri = "/v1/admin/" + paengkeID + "/branches"
        // const encoded = encodeURI(uri);
        const apiParams: APICallParams = {
            verb: "DELETE",
            url: uri,
            params: { tx_id: tx, branch_id: branchID },
            body: {},
        };
        const response = await makeAuthenticatedAPICall(apiParams);

        if (!response) {
            login?.logout();
        } else if (response.status !== 204) {
            toast({
                title: "Account Branches",
                description: "Failed remove",
            });
        } else {
            toast({
                title: "Account Branches",
                description: "Successfully remove.",
            });
            loadBranchesWithPalengkeID()
        }
    }

    const handleNewClick = () => {
        const fill = accountDetails
        navigate("/admin/accounts/" + accountDetails.id + "/branch", { state: { accountDetails: fill } })

    }
    const handlePermissionlick = () => {
        const fill = accountDetails
        navigate("/admin/accounts/" + accountDetails.id + "/permissions", { state: { accountDetails: fill } })
    }

    useEffect(() => {
        loadBranchesWithPalengkeID()

    }, []);

    return (
        <div className="flex flex-1 flex-col  gap-2 shadow-2xl h-screen">
            <div className="flex flex-row justify-between gap-2">
                <Button variant="destructive" onClick={() => handlePermissionlick()}>
                    Permission
                </Button>
                <Button onClick={() => handleNewClick()}>
                    <FaPlus className="mr-4 h-6 w-6" /> Branch
                </Button>
            </div>

            <Table>
                <TableCaption>A list of branch</TableCaption>
                <TableHeader>
                    <TableRow>
                        <TableHead className="w-[100px]">ID</TableHead>
                        <TableHead>Image</TableHead>
                        <TableHead>Branch</TableHead>
                        <TableHead>Address</TableHead>
                        <TableHead className="w-[100px]">Actions</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {branchData?.map((i) => (
                        <TableRow key={i.id.toString()}>
                            <TableCell className="font-medium">{i.id}</TableCell>
                            <TableCell>
                                <img
                                    src={i.img_url}
                                    className="w-[4rem] "
                                    alt={i.img_url}
                                />
                            </TableCell>
                            <TableCell>{i.name}</TableCell>
                            <TableCell>{i.address}</TableCell>
                            <TableCell>
                                <Button variant="destructive" onClick={() => handleRemoveClick(i.id, i.branch_id)}>Remove</Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}

export default AccountBranches




import { FaMagnifyingGlass, FaPlus } from "react-icons/fa6";
import { Input } from "../ui/input";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import { useNavigate } from 'react-router';
import { Button } from "../ui/button";
import { useEffect, useState } from "react";
import { APICallParams, makeAuthenticatedAPICall } from "@/utils/apiCall";
import { AccountModel } from "@/interfaces/AccountModel";
import { TitleModel } from "@/interfaces/TitleModel";

function Accounts() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [accountData, SetAccountData] = useState<AccountModel[]>([])
  // const [genderData, setGenderData] = useState<GenderModel[]>()
  const [title, setTitle] = useState<TitleModel[]>()


  const handleNewAccount = () => {
    navigate("/admin/accounts/new");
  }



  function getTitle(id: number): string {
    const itTitle = title?.find((i) => i.id === id);
    return itTitle?.title ?? ""
  }

  // const loadGender = async () => {
  //   try {
  //     const apiParams: APICallParams = {
  //       verb: "GET",
  //       url: "https://pickapps.net/dev/v1/genders/",
  //       params: { page: 0, limit: 100 },
  //       body: {},
  //     };
  //     const response = await makeAuthenticatedAPICall(apiParams);

  //     if (response.status === 200) {
  //       setGenderData(response.data)
  //       localStorage.setItem("genderData", JSON.stringify(response.data));
  //     }
  //   } catch (error) {
  //     setError((error as Error).message);
  //   } finally {
  //     setLoading(false);
  //   }
  // }

  const loadTitle = async () => {
    try {
      const apiParams: APICallParams = {
        verb: "GET",
        url: "/v1/admin-title/",
        params: { is_active: true, page: 0, limit: 100 },
        body: {},
      };
      const response = await makeAuthenticatedAPICall(apiParams);
      if (response.status === 200) {
        setTitle(response.data)
        localStorage.setItem("titleLS", JSON.stringify(response.data))
      }
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  }

  const loadAccount = async () => {
    try {
      const apiParams: APICallParams = {
        verb: "GET",
        url: "/v1/admin/",
        params: { is_active: true, page: 0, limit: 100 },
        body: {},
      };

      const response = await makeAuthenticatedAPICall(apiParams);
      if (!response) {
        // login?.logout();
      } else if (response.status === 200) {
        SetAccountData(response.data)
      }

    } catch (error) {
      setError((error as Error).message);
      console.log(error)
    } finally {
      setLoading(false);
    }
  }

  const handleClick = (accountID: number) => {
    const fill = accountData?.find((i) => i.id === accountID)

    navigate("/admin/accounts/" + accountID, { state: { accountDetails: fill } })

  };

  const handleActivities = (accountID: number) => {
    const fill = accountData?.find((i) => i.id === accountID)


    navigate("/admin/accounts/Activities", { state: { accountDetails: fill } })

  }

  // const handleupdate = () => {
  //   console.log("656")
  // }

  useEffect(() => {
    // if (!localStorage.getItem("genderLS")) {
    //   loadGender()
    // } else {
    //   const genderDataFromStorage = localStorage.getItem("genderLS");
    //   const parsedGenderData = genderDataFromStorage ? JSON.parse(genderDataFromStorage) : undefined;
    //   // Update the state with the parsed data
    //   setGenderData(parsedGenderData);
    // }

    if (!localStorage.getItem("titleLS")) {
      loadTitle()
    } else {
      const titleFromStorage = localStorage.getItem("titleLS");
      const parsedTitle = titleFromStorage ? JSON.parse(titleFromStorage) : undefined;
      setTitle(parsedTitle)
    }

    loadAccount()
  }, []);

  // if (isEdit) {
  //   return (
  //     <Dialog open={true}>
  //       <DialogContent className="sm:max-w-[425px]">
  //         <DialogHeader>
  //           <DialogTitle>Enter MobileOTP</DialogTitle>

  //           <DialogDescription>
  //             Please Select you want to change
  //           </DialogDescription>
  //         </DialogHeader>

  //         <div className="grid gap-2 py-4">
  //           <div className="grid grid-cols-4 items-center gap-4">
  //             <Select>
  //               <SelectTrigger className="w-[20rem]">
  //                 <SelectValue placeholder="Select a want to update" />
  //               </SelectTrigger>
  //               <SelectContent>
  //                 <SelectGroup>
  //                   <SelectItem value="password">Password</SelectItem>
  //                   <SelectItem value="email">Email</SelectItem>
  //                   <SelectItem value="mobile">Mobile</SelectItem>
  //                 </SelectGroup>
  //               </SelectContent>
  //             </Select>
  //           </div>
  //         </div>
  //         <DialogFooter>
  //           <Button onClick={() => handleupdate()} >Verify</Button>
  //         </DialogFooter>
  //       </DialogContent>
  //     </Dialog>
  //   )

  // }

  // const handleIsActive = async (userID: number) => {
  //   setIsActive(!isActive);
  //   // accountDetails.is_enabled = isActive
  //   // await isActiveSave(userID, isActive)

  //   try {
  //     const apiParams: APICallParams = {
  //       verb: "PATCH",
  //       url: "https://pickapps.net/dev/v1/admin/" + userID + "/enable",
  //       params: { is_enabled: isActive },
  //       body: {},
  //     };

  //     const response = await makeAuthenticatedAPICall(apiParams);

  //     if (response.status !== 200) {
  //       toast({
  //         title: "Actived",
  //         description: "Failed Actived",
  //       });
  //     } else {
  //       SetAccountData(response.data)
  //       toast({
  //         title: "Status",
  //         description: "Status: " + isActive,
  //       });
  //     }

  //   } catch (error) {
  //     setError((error as Error).message);
  //   } finally {
  //     setLoading(false);
  //   }
  // }

  // const isActiveSave = async (userID: number, isAct: boolean) => {
  //   const apiParams: APICallParams = {
  //     verb: "PATCH",
  //     url: "https://pickapps.net/dev/v1/admin/" + userID + "/enable",
  //     params: { is_enabled: isAct },
  //     body: {},
  //   };

  //   const response = await makeAuthenticatedAPICall(apiParams);

  //   if (response.status !== 200) {
  //     toast({
  //       title: "Actived",
  //       description: "Failed Actived",
  //     });
  //   } else {
  //     SetAccountData(response.data)
  //     toast({
  //       title: "Status",
  //       description: "Status: " + isActive,
  //     });
  //   }

  // }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  return (
    <div className="h-screen w-full bg-white p-4 rounded-sm border border-gray-200 flex flex-col">

      <div className="flex flex-row p-2 justify-between">
        <div className="relative">
          <FaMagnifyingGlass
            fontSize={20}
            className="absolute top-1/2 -translate-y-1/2 left-3"
          />
          <Input
            type="text"
            placeholder="Search..."
            className="text-sm lg:w-[24rem] pl-11 order-1"
          />
        </div>
        <Button onClick={() => handleNewAccount()}>
          <FaPlus className="mr-2 h-4 w-4" /> Account
        </Button>
      </div>
      <Table>
        <TableCaption>A list of your Product.</TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[60px]">ID</TableHead>
            <TableHead>Username</TableHead>
            <TableHead>Email</TableHead>
            <TableHead>First Name</TableHead>
            <TableHead>Middle Name</TableHead>
            <TableHead>Last Name</TableHead>
            <TableHead>Title</TableHead>
            <TableHead>is Enable</TableHead>
            {/* <TableHead>is frozen</TableHead> */}
            <TableHead className="w-[100px]">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {accountData?.map((i) => (
            <TableRow key={i.id.toString()}>
              <TableCell className="font-medium">{i.id}</TableCell>
              <TableCell>{i.username}</TableCell>
              <TableCell>{i.email}</TableCell>
              <TableCell>{i.first_name}</TableCell>
              <TableCell>{i.middle_name}</TableCell>
              <TableCell>{i.last_name}</TableCell>
              <TableCell>{getTitle(i.title_id)}</TableCell>
              <TableCell>{i.is_enabled.toString()}</TableCell>

              <TableCell>

                <div className="flex flex-row gap-2">
                  <Button onClick={() => handleClick(i.id)}>
                    View
                  </Button>
                  <Button variant="outline" onClick={() => handleActivities(i.id)}>
                    Activity
                  </Button>

                </div>

              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={11}>
              {/* <div className="flex flex-row justify-center gap-x-10">
                <Button
                  className={
                    startIndex === 0
                      ? "pointer-events-none opacity-50"
                      : undefined
                  }
                  onClick={() => {
                    setStartIndex(startIndex - rowPerPage);
                    setEndIndex(endIndex - rowPerPage);
                  }}
                >
                  Prev
                </Button>
                <Button
                  className={
                    endIndex === 0
                      ? "pointer-events-none opacity-50"
                      : undefined
                  }
                  onClick={() => {
                    setStartIndex(startIndex + rowPerPage);
                    setEndIndex(endIndex + rowPerPage);
                  }}
                >
                  Next
                </Button>
              </div> */}
              {/* <Button onClick={() => handleClick(1)}>Edit</Button> */}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
}

export default Accounts;

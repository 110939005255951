import { AccountModel } from "@/interfaces/AccountModel";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../ui/dialog";
import CustomLabel from "@/utils/customLabel";
import { Button } from "../ui/button";
import { useLocation } from "react-router";
import { ChangeEvent, useState } from "react";
import { toast } from "../ui/use-toast";
import { APICallParams, makeAuthenticatedAPICall } from "@/utils/apiCall";

interface ChangeNumberDialogProps {
    open: boolean;
    onClose: (data: AccountModel) => void;
}

const ChangeNumberDialog: React.FC<ChangeNumberDialogProps> = ({ open, onClose }) => {
    const location = useLocation();
    const [accountDetails, setAccountDetails] = useState<AccountModel>(location.state?.accountDetails);
    const [isValid, setIsValid] = useState(true);
    const [mobile, setMobile] = useState<string>('');

    const handleValidate = () => {
        if (!isValid) {
            toast({
                title: "Email Address",
                description: "Please enter mobile format",
            });
        } else {
            ///update mobile number
            updateMobile(accountDetails.id, mobile)
        }

    }

    const handleClose = () => {
        onClose(accountDetails);
    }

    const updateMobile = async (userID: number, mobile: string) => {
        try {
            const uri = "/v1/admin/" + userID + "/mobile"
            const apiParams: APICallParams = {
                verb: "PATCH",
                url: uri,
                params: { new_mobile: mobile },
                body: {},
            };
            const response = await makeAuthenticatedAPICall(apiParams);



            if (response.status !== 200) {
                toast({
                    title: "Mobile Number",
                    description: response.data.message,
                });
            } else {
                setAccountDetails(response.data)
                toast({
                    title: "Mobile Number",
                    description: "Successfully Update.",
                });
            }
        } catch (error) {
            console.error("Error during Mobile Number:", error);
        }


        onClose(accountDetails);
    }

    const validateMobile = (event: ChangeEvent<HTMLInputElement>) => {
        setMobile(event.target.value)
        const mobileregex = /^\+639\d{9}/;
        // const mobileregex = /^639\d{9}/;1

        setIsValid(mobileregex.test(event.target.value))
    }


    if (!open) return null; // If open is false, don't render the dialog


    return (
        <Dialog open={open}>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Enter New Mobile Numbwe</DialogTitle>
                </DialogHeader>

                <CustomLabel type="text"
                    placeholder="Mobile Number"
                    readOnly={false}
                    label="Mobile Number (+63 9XX XXXX XXX)"
                    htmlFor="mobile" // Pass htmlFor as a prop
                    value={mobile}
                    onChange={validateMobile}
                />
                {!isValid && <p style={{ color: 'red' }}>Please enter a mobile Number.</p>}

                <DialogFooter >

                    <Button onClick={handleValidate}>Update</Button>
                    <Button onClick={handleClose}> Close</Button>

                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}

export default ChangeNumberDialog;

import { Item } from "@/interfaces/OrdersModel";
import { useLocation } from "react-router";
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";


function Order() {
    const location = useLocation();
    const itme: Item[] = location.state.itmsData

    return (
        <div className="h-screen w-full bg-white p-4 rounded-sm border border-gray-200 flex flex-col">
            <Table>
                <TableCaption>A list of your Orders.</TableCaption>
                <TableHeader>
                    <TableRow>
                        <TableHead className="w-[100px]">ID</TableHead>
                        <TableHead>Image</TableHead>
                        <TableHead>Product</TableHead>
                        <TableHead>Qty</TableHead>
                        <TableHead>Amount</TableHead>
                        {/* <TableHead className="w-[100px]">Actions</TableHead> */}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {itme.map((i, index) => (
                        <TableRow key={i.product_name}>
                            <TableCell className="font-medium">{index}</TableCell>
                            <TableCell>
                                <img
                                    src={i.image_url}
                                    className="mx-auto w-10 items-center"
                                    alt={i.image_url}
                                />
                            </TableCell>
                            <TableCell>{i.product_name}</TableCell>
                            <TableCell>{i.item_qty}</TableCell>
                            <TableCell>{i.amount}</TableCell>
                            {/* <TableCell>
                                <div className="flex flex-row justify-center gap-x-10">
                                    <Button>Done</Button>
                                    <Button variant="outline" >
                                        Cancel
                                    </Button>

                                </div>
                            </TableCell> */}
                        </TableRow>
                    ))}
                </TableBody>

            </Table>

        </div>
    )
}

export default Order


function ACPermission() {
    // const location = useLocation();
    // const accountDetails: AccountModel = location.state?.accountDetails;

    return (
        <div className="flex flex-1 flex-col  gap-2 shadow-2xl h-screen">
            <strong className="text-gray-700 font-medium">Coming soon</strong>

        </div>
    )
}

export default ACPermission
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import React from 'react'; // Import IconType from react-icons for TypeScript support


// Define props interface for InputBase component
interface InputBaseProps {
    type: string;
    placeholder: string;
    readOnly: boolean;
    value?: string; // Added value prop
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

// Parent component
class InputBase extends React.Component<InputBaseProps> {
    render() {
        return (
            <Input
                type={this.props.type}
                placeholder={this.props.placeholder}
                readOnly={this.props.readOnly}
                value={this.props.value}
                onChange={this.props.onChange} // Pass onChange prop
                autoComplete='off'
            />

        );
    }
}

// Define props interface for UsernameInput component extending InputBaseProps
interface CustomLabelProps extends InputBaseProps {
    label: string;
    htmlFor: string;
}

// Child component inheriting from InputBase
class CustomLabel extends React.Component<CustomLabelProps> {
    render() {
        return (
            <div className='flex flex-col w-full gap-y-2'>
                <Label htmlFor={this.props.htmlFor}>{this.props.label}</Label>
                <InputBase {...this.props} />
            </div>
        );
    }
}

export default CustomLabel;

import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";

type RequestParams = Omit<AxiosRequestConfig, "url"> & { url: string };

const requestAWS = async <T,>({
  url,
  params,
  ...rest
}: RequestParams & { params?: Record<string, any> }): Promise<T> => {
  try {
    const response: AxiosResponse<T> = await axios({
      url,
      params,
      ...rest,
    });

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      throw new Error(
        `Request failed with status ${axiosError.response?.status}: ${axiosError.response?.data}`
      );
    } else {
      throw new Error(`Request failed: ${error}`);
    }
  }
};

export default requestAWS;

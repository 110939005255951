import { Outlet } from "react-router"

function TitleLayout() {
    return (
        <div className="flex flex-col gap-2">
            <div>{<Outlet />}</div>
        </div>
    )
}

export default TitleLayout;
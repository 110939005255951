import { API_BASE_URL, API_KEY } from "@/api/constant";
import axios, { AxiosError, Method } from "axios";
import { getSessionToken, getRefreshToken, getLoginToken } from "./getToken";

export interface APICallParams {
  verb: Method;
  url: string;
  params?: any; // You can define a specific type for params if needed
  body?: any; // You can define a specific type for body if needed
  token?: string | null;
}

export async function makeAuthenticatedAPICall({
  verb,
  url,
  params = {},
  body = {},
  token = getSessionToken(),
}: APICallParams): Promise<any> {
  try {
    // const decoded = decodeAndCheckExp(token);
    // if (decoded === null) {
    //   console.log("kuha ka na refresh")
    // }

    const uri = API_BASE_URL + url
    const encoded = encodeURI(uri);
    const response = await axios({
      method: verb,
      url: encoded,
      headers: {
        X_API_Key: API_KEY,
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      params: params,
      data: body,
    });
    return response;
  } catch (error) {
    const refreshError = error as AxiosError;
    if (refreshError.response?.status === 401) {
      const refToken = await refreshToken(
        "POST",
        "/v1/auth/admin/refresh"
      );
      if (refToken === 201) {
        const res = await makeAuthenticatedAPICall({
          verb,
          url,
          params,
          body,
          token: getSessionToken(),
        });
        return res
      }
    }
    else if (refreshError.response?.status === 409) {
      return refreshError.response
    }
    // else {
    //   return refreshError.response
    // }
  }
}

export async function refreshToken(
  verb: Method,
  url: string,
  params: any = {},
  body = {},
  token: string | null = getRefreshToken()
) {
  try {

    const uri = API_BASE_URL + url
    const encoded = encodeURI(uri);
    const response = await axios({
      method: verb,
      url: encoded,
      headers: {
        X_API_Key: API_KEY,
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      params,
      data: body,
    });

    localStorage.setItem("session_token", response.data.session_token);
    localStorage.setItem("refresh_token", response.data.refresh_token);

    return response.status;
  } catch (error) {
    localStorage.clear();
  }
}

export async function makeLoginAuthenticatedAPICall(
  verb: Method,
  url: string,
  params: any = {},
  body = {},
  token: string | null = getLoginToken()
): Promise<any> {
  try {
    const uri = API_BASE_URL + url
    const encoded = encodeURI(uri);
    const response = await axios({
      method: verb,
      url: encoded,
      headers: {
        X_API_Key: API_KEY,
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      params,
      data: body,
    });

    return response;
  } catch (error) {
    return error;
  }
}

export async function makeWithOutAuthenticatedAPICall({
  verb,
  url,
  params = {},
  body = {},
}: APICallParams): Promise<any> {
  try {
    const uri = API_BASE_URL + url
    const encoded = encodeURI(uri);
    const response = await axios({
      method: verb,
      url: encoded,
      headers: {
        X_API_Key: API_KEY,
      },
      params: params,
      data: body,
    });
    return response;
  } catch (error) {
    return error;
  }
}

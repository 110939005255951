
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useLocation, useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { ProductBetaRateModel, VariantBetaModel } from "@/interfaces/ProductRateModel";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../ui/form";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { APICallParams, makeWithOutAuthenticatedAPICall } from "@/utils/apiCall";
import { toast } from "../ui/use-toast";

const FormSchema = z.object({
    // imageurl: z.string(),
    variant: z.string(),
    rate: z.string()
})

export default function EVariantRate() {
    const location = useLocation();
    const variantRateData: VariantBetaModel = location.state.variantData;
    const pdata: ProductBetaRateModel = location.state.productRateData;
    const navigate = useNavigate();

    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            // imageurl: variantRateData.image_url ?? "",
            variant: variantRateData.variant_type ?? "",
            rate: variantRateData.orig_price ?? 0
        }

    })

    const handleUpdateClick = (data: z.infer<typeof FormSchema>) => {
        if (variantRateData) {
            const variantRateData: VariantBetaModel = location.state.variantData;
            UpdateProductPrice(pdata.branch_id, pdata.product_id)
            updateVariantPrice(variantRateData.variant_id, data.rate)

        }
    }
    const UpdateProductPrice = async (branchID: number, productID: number) => {
        try {
            const apiParams: APICallParams = {
                verb: "PUT",
                url: "/v1/wet_market/branches/" + branchID + "/products/" + productID,
                params: { b_id: branchID, p_id: productID },
                body: {
                    orig_price: 0,
                    discount_price: 0,
                    promo_id: 0,
                    is_enabled: true
                },
            };
            const response = await makeWithOutAuthenticatedAPICall(apiParams)

            if (response.status !== 204) {
                toast({
                    variant: "destructive",
                    title: "Branch Product Rate/Price",
                    description: response.response.data.message,
                });
            } else {
                pdata.orig_price = "0"
                pdata.discounted_price = "0"
                toast({
                    title: "Branch Product Rate/Price",
                    description: "successfully Update",
                });
                const fill = pdata
                navigate("/branch/" + branchID + "/products/" + productID, { state: { productRateData: fill } })

            }
        } catch (error) {
            throw new Error(`Error fetching data: ${error}`);
        }
    }

    const updateVariantPrice = async (vID: number, rate: string) => {
        try {
            const apiParams: APICallParams = {
                verb: "PUT",
                url: "/v1/wet_market/branches/" + pdata.branch_id + "/products/" + pdata.product_id + "/variants/" + vID,
                params: {
                    b_id: pdata.branch_id,
                    p_id: pdata.product_id,
                    v_id: vID
                },
                body: {
                    orig_price: rate,
                    discount_price: rate,
                    promo_id: 0,
                    is_enabled: true
                },
            };
            const response = await makeWithOutAuthenticatedAPICall(apiParams)
            if (response.status !== 204) {
                toast({
                    variant: "destructive",
                    title: "Variant Rate/Price",
                    description: response.response.data.message,
                });
            } else {
                pdata.orig_price = rate
                pdata.discounted_price = rate
                toast({
                    title: "Variant rate/price",
                    description: "successfully Update",
                });
                const fill = pdata
                navigate("/branch/" + pdata.branch_id + "/products/" + pdata.product_id, { state: { productRateData: fill } })
            }
        } catch (error) {
            throw new Error(`Error fetching data: ${error}`);
        }
    }


    return (
        <div className="p-2 rounded-sm border border-gray-200 flex flex-col flex-1 h-screen gap-2">
            <strong className="text-gray-700 font-medium ">Update Variant Rate/Pricing</strong>
            <Form {...form}>
                <form className="flex flex-col gap-5 w-1/4"
                    onSubmit={form.handleSubmit(handleUpdateClick)}>
                    <FormField
                        control={form.control}
                        name="variant"
                        render={({ field }) => {
                            return (
                                <FormItem>
                                    <FormLabel>Product Name</FormLabel>
                                    <FormControl>
                                        <Input placeholder="product Name" type="text" {...field} autoComplete="off" />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            );
                        }}
                    />
                    <FormField
                        control={form.control}
                        name="rate"
                        render={({ field }) => {
                            return (
                                <FormItem>
                                    <FormLabel>Rate/Price</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Rate/Price" type="number" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            );
                        }}
                    />
                    <Button>
                        Update
                    </Button>
                </form>
            </Form>
        </div>
    )
}

import requestAWS from "./awsApi";

// // GM - START
// import AWS from "aws-sdk";

// interface FileUpload {
//   name: string;
//   type: string;
//   size: number;
//   slice(start?: number, end?: number, contentType?: string): Blob;
// }

// const uploadToS3 = (file: FileUpload, fileName: string): Promise<string> => {
//   const bucketName = process.env.REACT_APP_AWS_BUCKET_NAME;
//   if (!bucketName) {
//     throw new Error("AWS bucket name is not defined.");
//   }

//   AWS.config.update({
//     region: process.env.REACT_APP_AWS_REGION,
//   });

//   const s3 = new AWS.S3();

//   const params: AWS.S3.PutObjectRequest = {
//     Bucket: bucketName,
//     Key: fileName,
//     Body: file,
//     ContentType: file.type,
//   };

//   return new Promise((resolve, reject) => {
//     s3.upload(params, (err: Error, data: AWS.S3.ManagedUpload.SendData) => {
//       if (err) {
//         reject(err);
//       } else {
//         resolve(data.Location);
//       }
//     });
//   });
// };

// export default uploadToS3;

// GM - END

export const generateAWSUrl = async (paramValue: any) => {
  try {
    const data = await requestAWS<any>({
      url: "https://amhop30zp8.execute-api.us-east-2.amazonaws.com/staging/",
      method: "POST",
      data: paramValue,
    });
    return data;
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`);
  }
};

export const saveImageAWSUrl = async (
  urlurlUpload: any,
  file: any,
  imageType: string
) => {
  try {
    const res = await requestAWS<any>({
      url: urlurlUpload,
      method: "PUT",
      data: file,
      headers: { "Content-Type": imageType },
    });
    return res;
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`);
  }
};

import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Fix for the default marker icon issue in Leaflet
delete (L.Icon.Default.prototype as any)._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

interface MapComponentProps {
    onLocationSelect: (lat: number, lng: number) => void;
}

const LocationMarker: React.FC<{ setMarker: React.Dispatch<React.SetStateAction<[number, number] | null>>, onLocationSelect: (lat: number, lng: number) => void }> = ({ setMarker, onLocationSelect }) => {
    useMapEvents({
        click(e) {
            const { lat, lng } = e.latlng;
            setMarker([lat, lng]);
            onLocationSelect(lat, lng);
        },
    });
    return null;
};

const MapComponent: React.FC<MapComponentProps> = ({ onLocationSelect }) => {
    const [position, setPosition] = useState<[number, number] | null>(null);
    const [marker, setMarker] = useState<[number, number] | null>(null);

    const getCurrentPosition = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setPosition([latitude, longitude]);
                    setMarker([latitude, longitude]);
                    onLocationSelect(latitude, longitude);
                },
                (error) => {
                    console.error('Error getting location:', error);
                },
                {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0,
                }
            );
        }
    }


    useEffect(() => {
        if (!position) {
            getCurrentPosition()
        }

    }, [onLocationSelect]);

    return (
        <div>
            {!position || <MapContainer
                center={position}
                zoom={position ? 200 : 200}
                style={{ height: '400px', width: '100%' }}

            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                {marker && (
                    <Marker position={marker}>
                        <Popup>Marker at {marker[0]}, {marker[1]}</Popup>
                    </Marker>
                )}
                <LocationMarker setMarker={setMarker} onLocationSelect={onLocationSelect} />
            </MapContainer>}
        </div >

    );
};

export default MapComponent;

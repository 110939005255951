import sha256 from "crypto-js/sha256";


export const decryptPassword = (pw: string) => {
    const salt1 = pw.substring(0, 3);
    const salt2 = pw.substring(pw.length - 3, pw.length);

    const combineSalt = salt2 + pw + salt1;
    const digest = sha256(combineSalt)

    return digest
}
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form";
import { Form, FormControl, FormField, FormItem, FormMessage } from "../ui/form";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "../ui/select";
import { useAuth } from "@/hooks/useAuth";
import { Button } from "../ui/button";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { AccountBranch } from "@/interfaces/AccountModel";
import { useEffect, useState } from "react";
import { APICallParams, makeWithOutAuthenticatedAPICall } from "@/utils/apiCall";
import { toast } from "../ui/use-toast";
import { useNavigate } from "react-router";

const FormSchema = z.object({
    branchID: z.string(),
})
export default function OrderReceivedBranches() {
    const login = useAuth();
    const uBranch = login?.user.branch_id
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [branches, setBranches] = useState<AccountBranch[]>();
    const navigate = useNavigate();

    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        // defaultValues: {
        //     search: ""
        // }

    })

    const handleSearchClick = (data: z.infer<typeof FormSchema>) => {
        // toast({
        //     variant: "destructive",
        //     title: "Brandch",
        //     description: "Failed load Branch",
        // });
        navigate("/branch/" + data.branchID + "/order_received", { state: { sBrandID: data.branchID } })
    }

    const loadBranch = async () => {
        try {
            const apiParams: APICallParams = {
                verb: "GET",
                url: "/wet_market/branches_load",
                params: {},
                body: {},
            };
            const response = await makeWithOutAuthenticatedAPICall(apiParams);
            if (response.status !== 200) {
                toast({
                    variant: "destructive",
                    title: "Brandch",
                    description: "Failed load Branch",
                });
            } else {
                setBranches(response.data)
                const branchesString = JSON.stringify(response.data);
                localStorage.setItem('branchesLS', branchesString);
            }
        } catch (error) {
            setError((error as Error).message);
        } finally {
            setLoading(false);
        }
    }

    function getBranchName(branchID: number) {
        const data = branches?.find((i) => i.idx === branchID)
        return data?.branch_name ?? ""
    }


    useEffect(() => {
        if (!localStorage.getItem("branchesLS")) {
            loadBranch()
        } else {
            const branchesFromStorage = localStorage.getItem("branchesLS")
            const parsedbranches = branchesFromStorage ? JSON.parse(branchesFromStorage) : undefined;
            setBranches(parsedbranches)
            setLoading(false);
        }
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }
    return (
        <div className="flex h-screen items-center justify-center">
            <Card >
                <CardHeader>
                    <CardTitle>Select Branch</CardTitle>
                </CardHeader>
                <CardContent>
                    <Form {...form}>
                        <form className="flex  gap-x-5"
                            onSubmit={form.handleSubmit(handleSearchClick)}>
                            <FormField
                                control={form.control}
                                name="branchID"
                                render={({ field }) => {
                                    return (
                                        <FormItem className="flex flex-row gap-5 items-center">

                                            <Select onValueChange={field.onChange}>
                                                <FormControl>
                                                    <SelectTrigger>
                                                        <SelectValue placeholder="Select Branch" />
                                                    </SelectTrigger>
                                                </FormControl>

                                                <SelectContent >
                                                    <SelectGroup>
                                                        <SelectLabel>Select Branch</SelectLabel>
                                                        {uBranch?.map((i: number) => (
                                                            <SelectItem key={i} value={i.toString()}>
                                                                {getBranchName(i)}
                                                            </SelectItem>
                                                        ))}
                                                    </SelectGroup>
                                                </SelectContent>
                                            </Select>
                                            <FormMessage />
                                        </FormItem>
                                    );
                                }}
                            />
                            <Button className="p-5 " type="submit" >
                                <FaMagnifyingGlass className="mr-6 h-4 w-4" />
                                Select
                            </Button>
                        </form>
                    </Form>
                </CardContent>
            </Card>
        </div>
    )
}


import { ProductBase, VariantBaseData } from "@/data/ProductData";
import { useLocation } from "react-router";
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../ui/form";
import { useForm } from "react-hook-form";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { APICallParams, makeWithOutAuthenticatedAPICall } from "@/utils/apiCall";
import { toast } from "../ui/use-toast";

const FormSchema = z.object({
    variant: z.string(),
    rate: z.string(),

    // discount_price: z.string(),
    // promo_id: z.string(),
    // is_enabled: z.boolean()
})

export default function VariantRateEdit() {
    const location = useLocation();
    const sBProductData: ProductBase = location.state.sBproductRateData;
    const sBVariantData: VariantBaseData = location.state.sBVariantData;
    const sbranchID: number = location.state.sbBranchID;
    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            variant: sBVariantData.variant_type ?? "",
            rate: '0',
        }

    })

    const handleSaveClick = (data: z.infer<typeof FormSchema>) => {
        putProductPrice()
        putVariantPrice(parseInt(data.rate))
    }

    const putProductPrice = async () => {
        try {
            const apiParams: APICallParams = {
                verb: "PUT",
                url: "/v1/wet_market/branches/" + sbranchID + "/products/" + sBProductData.id,
                params: {
                    b_id: sbranchID,
                    p_id: sBProductData.id
                },
                body: {
                    orig_price: 0,
                    discount_price: 0,
                    promo_id: 0,
                    is_enabled: true
                },
            };
            const response = await makeWithOutAuthenticatedAPICall(apiParams)

            if (response.status !== 204) {
                toast({
                    variant: "destructive",
                    title: "Branch Product Rate/Price",
                    description: response.response.data.message,
                });
            } else {
                toast({
                    title: "Branch Product Rate/Price",
                    description: "successfully Update",
                });
            }

        } catch (error) {
            throw new Error(`Error fetching data: ${error}`);
        }
    }

    const putVariantPrice = async (rate: number) => {
        try {
            const apiParams: APICallParams = {
                // verb: "POST",
                // url: "/wet_market/product_price_save",
                verb: "PUT",
                url: "/v1/wet_market/branches/" + sbranchID + "/products/" + sBProductData.id + "/variants/" + sBVariantData.id,
                params: {
                    b_id: sbranchID,
                    p_id: sBProductData.id,
                    v_id: sBVariantData.id
                },
                body: {
                    orig_price: rate,
                    discount_price: rate,
                    promo_id: 0,
                    is_enabled: true
                }
            };
            const response = await makeWithOutAuthenticatedAPICall(apiParams);


            if (response.status !== 200) {
                toast({
                    variant: "destructive",
                    title: "Variant Rate/Price",
                    description: response.response.data.message,
                });
            } else {
                if (response.data[0]["Already exists"] === "Already exists") {
                    toast({
                        variant: "destructive",
                        title: "Variant Rate/Price",
                        description: "Already exists",
                    });
                } else {
                    toast({
                        title: "Variant Rate/Price",
                        description: "Successfully save.",
                    });
                }

            }
        } catch (error) {
            throw new Error(`Error fetching data: ${error}`);
        }

    }

    return (
        <div className="bg-white p-4 rounded-sm border border-gray-200 flex flex-col flex-1 h-screen">
            <strong className="text-gray-700 font-medium ">Update Product Rate/Pricing</strong>
            <Form {...form}>
                <form className="flex flex-col gap-5 w-1/4"
                    onSubmit={form.handleSubmit(handleSaveClick)}>
                    <img
                        src={sBVariantData.img_url}
                        className="w-[4rem] "
                        alt={sBVariantData.img_url}
                    />
                    <FormField
                        control={form.control}
                        name="variant"
                        render={({ field }) => {
                            return (
                                <FormItem>
                                    <FormLabel>Variant</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Rate/Price" type="text" {...field} disabled />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            );
                        }}
                    />
                    <FormField
                        control={form.control}
                        name="rate"
                        render={({ field }) => {
                            return (
                                <FormItem>
                                    <FormLabel>Rate/Price</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Rate/Price" type="number" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            );
                        }}
                    />
                    <Button>
                        Save
                    </Button>
                </form>
            </Form>

        </div>
    )
}


import { Item } from "@/interfaces/OrdersModel";
import { useLocation } from "react-router";
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";
import { useEffect, useRef, useState } from "react";
import ReactToPrint from 'react-to-print';
import Receipt from '@/components/receipt/ReceiptOrders';
import { AccountBranch } from "@/interfaces/AccountModel";
import { APICallParams, makeWithOutAuthenticatedAPICall } from "@/utils/apiCall";
import { toast } from "../ui/use-toast";
import { useAuth } from "@/hooks/useAuth";


function OrderReceive() {
    const login = useAuth();
    const location = useLocation();
    const items: Item[] = location.state.itmsData
    const sOrderID: number = location.state.sOrderID
    const receiptRef = useRef<HTMLDivElement>(null);
    const sBrandID: number = location.state.sBrandID;
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [branches, setBranches] = useState<AccountBranch[]>();

    // Sample data to be passed to the Receipt component
    // const receiptData = {
    //     address: 'Blk 2 Lot 4 Phase 3 Classica Northgate\nBrgy. San Jose del Monte City, Bulacan, 3023',
    //     contact: "+6385-158-5375",
    //     email: "pickappss22@gmail.com",
    //     date: new Date().toLocaleDateString(),
    //     time: new Date().toLocaleTimeString(), // Add time here
    //     cashier: "Mohasan E. Abedin",
    //     branch: "Phase 5 Kanlaon",
    //     items: items,
    //     price: "$100",
    //     message: "No return on meat, products, milk products\n Thank you for your business!!!",
    // };

    const getBranchName = (branchID: number) => {
        const data = branches?.find((i) => i.idx === parseInt(branchID.toString()))
        return data
    }

    const getCashierName = () => {
        const data = login?.user.first_name + " " + login?.user.middle_name.charAt(0).toUpperCase() + ". " + login?.user.last_name

        return data
    }

    const loadBranch = async () => {
        try {
            const apiParams: APICallParams = {
                verb: "GET",
                url: "/wet_market/branches_load",
                params: {},
                body: {},
            };
            const response = await makeWithOutAuthenticatedAPICall(apiParams);
            if (response.status !== 200) {
                toast({
                    variant: "destructive",
                    title: "Brandch",
                    description: "Failed load Branch",
                });
            } else {
                setBranches(response.data)
                const branchesString = JSON.stringify(response.data);
                localStorage.setItem('branchesLS', branchesString);
            }
        } catch (error) {
            setError((error as Error).message);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!localStorage.getItem("branchesLS")) {
            loadBranch()
        } else {
            const branchesFromStorage = localStorage.getItem("branchesLS")
            const parsedbranches = branchesFromStorage ? JSON.parse(branchesFromStorage) : undefined;
            setBranches(parsedbranches)
            setLoading(false);
        }
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }
    return (
        <div className="h-screen w-full bg-white p-4 rounded-sm border border-gray-200 flex flex-col gap-2">
            <p className="font-bold">Order No.: {sOrderID}</p>
            <Table>
                <TableCaption>A list of your item order received.</TableCaption>
                <TableHeader>
                    <TableRow>
                        <TableHead className="w-[100px]">ID</TableHead>
                        <TableHead>Image</TableHead>
                        <TableHead>Product</TableHead>
                        <TableHead>Qty</TableHead>
                        <TableHead>Amount</TableHead>
                        {/* <TableHead className="w-[100px]">Actions</TableHead> */}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {items.map((i, index) => (
                        <TableRow key={i.product_name}>
                            <TableCell className="font-medium">{index}</TableCell>
                            <TableCell>
                                <img
                                    src={i.image_url}
                                    className="mx-auto w-10 items-center"
                                    alt={i.image_url}
                                />
                            </TableCell>
                            <TableCell>{i.product_name}</TableCell>
                            <TableCell>{i.item_qty}</TableCell>
                            <TableCell>{i.amount}</TableCell>
                            {/* <TableCell>
                                <div className="flex flex-row justify-center gap-x-10">
                                    <Button>Done</Button>
                                    <Button variant="outline" >
                                        Cancel
                                    </Button>

                                </div>
                            </TableCell> */}
                        </TableRow>
                    ))}
                </TableBody>

            </Table>
            <div className="flex">
                <ReactToPrint
                    trigger={() => <button className="bg-blue-500 text-white px-4 py-2 rounded">Print Receipt</button>}
                    content={() => receiptRef.current}
                    documentTitle="Receipt"
                />
                <div className="hidden">
                    <Receipt
                        ref={receiptRef}
                        orderID={sOrderID}
                        address={getBranchName(sBrandID)?.address ?? ""}
                        email={"pickappss22@gmail.com"}
                        date={new Date().toLocaleDateString()}
                        time={new Date().toLocaleTimeString()}
                        cashier={getCashierName()}
                        branch={getBranchName(sBrandID)?.branch_name ?? ""}
                        items={items}
                        message={"No return on meat, products, milk products\n Thank you for your business!!!"}
                    />
                </div>
            </div>
        </div>
    )
}

export default OrderReceive
import request from "@/api/Api"
import { API_KEY } from "@/api/constant"
import { CommissionModel } from "@/interfaces/CommisionModel";


export const fetchCommission = async () => {
    try {
        const data = await request<CommissionModel[]>({
            url: "/admin/rate_commission",
            method: "GET",
            data: {},
            headers: {
                X_API_Key: API_KEY,
            },
        });
        const exdata = data[0].itm
        const cleanedJsonString = JSON.parse(exdata);
        return cleanedJsonString[0]
    } catch (error) {
        throw new Error(`Error fetching data: ${error}`);
    }
}
import { FaPlus } from "react-icons/fa6";
import { Button } from "../ui/button";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import { useNavigate } from "react-router";
import { getBranch } from "@/api/services/branchServices";
import { useEffect, useState } from "react";
import { BranchData } from "@/data/BranchData";
import { ComingSoon } from "@/utils/ComingSoon";

function BranchList() {
  const navigate = useNavigate();
  const [branch, setBranch] = useState<BranchData[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const handleClickNew = () => {
    navigate("/admin/branch/new");
  };


  const handleEnableDisable = () => {
    ComingSoon("Branch")
  }

  const fetchBranch = async () => {
    try {
      const result = await getBranch();

      // setProducts(result);
      setBranch(result);
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBranch();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  return (
    <div className="bg-white p-4 rounded-sm border border-gray-200 flex  flex-col flex-1 h-screen">
      <div className="flex justify-end items-center pb-4">
        <Button onClick={() => handleClickNew()}>
          <FaPlus className="mr-2 h-4 w-4 " />
          New Branch
        </Button>
      </div>
      <Table>
        <TableCaption>A list of your Product.</TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[100px]">ID</TableHead>
            <TableHead>Image</TableHead>
            <TableHead>Branch Name</TableHead>
            <TableHead>Address</TableHead>
            <TableHead className="w-[100px]">Actions</TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          {branch?.map((branch, index) => (
            <TableRow key={branch.idx.toString() + index}>
              <TableCell className="font-medium">{branch.idx}</TableCell>
              <TableCell>
                <img
                  src={branch.img_url}
                  className="mx-auto w-10 items-center"
                  alt={branch.img_url}
                />
              </TableCell>
              <TableCell>{branch.branch_name}</TableCell>
              <TableCell>{branch.address}</TableCell>
              <TableCell>
                <div className="flex flex-row gap-4">
                  {/* <Button
                    variant="outline"
                    onClick={() => handleClickEdit()}
                  >
                    Edit
                  </Button> */}
                  <Button variant="destructive" onClick={() => handleEnableDisable()}>Disable/Enable</Button>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export default BranchList;

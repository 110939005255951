import { AccountBranch, AccountModel } from "@/interfaces/AccountModel"
import { fetchBranches } from "@/services/AccountServices";
import { useEffect, useState } from "react"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../ui/form";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "../ui/select";
import { Button } from "../ui/button";
import { useLocation } from "react-router";
import { APICallParams, makeAuthenticatedAPICall } from "@/utils/apiCall";
import { toast } from "../ui/use-toast";
import { useAuth } from "@/hooks/useAuth";

const FormSchema = z.object({
    branchID: z.string(),
})

export default function AccountBranchNew() {
    let login = useAuth();
    const location = useLocation();
    const [branachData, setBranchData] = useState<AccountBranch[]>();
    const accountDetails: AccountModel = location.state?.accountDetails;

    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
    })

    const loadBranch = async () => {
        try {

            const response = await fetchBranches()

            setBranchData(response)

        } catch (error) {
            console.error("Error during update password:", error);
        }
    }

    const handleUpdate = (data: z.infer<typeof FormSchema>) => {

        if (parseInt(data.branchID) !== 0) {
            branchTagSave(parseInt(data.branchID))
        }

    }

    const branchTagSave = async (brandhID: number) => {
        try {
            const pAdminID = accountDetails.id
            const uri = "/v1/admin/" + pAdminID + "/branches"
            // const encoded = encodeURI(uri);
            const apiParams: APICallParams = {
                verb: "POST",
                url: uri,
                params: { branch_id: brandhID },
                body: {},
            };
            const response = await makeAuthenticatedAPICall(apiParams);

            if (!response) {
                login?.logout();
            } else if (response.status !== 204) {
                toast({
                    title: "Branch",
                    description: "Failed Save.",
                });
            } else {
                toast({
                    title: "Branch",
                    description: "Successfully Save.",
                });
            }
        } catch (error) {
            console.error("Error during Branch:", error);
        }
    }

    useEffect(() => {
        loadBranch()
    }, [])

    return (
        <div className="flex flex-1 flex-col  gap-2 shadow-2xl h-screen">
            <strong className="text-gray-700 font-medium">New Branch</strong>

            <div className="flex flex-1 flex-col w-[32rem]">
                <Form {...form}>
                    <form className="w-full gap-3"
                        onSubmit={form.handleSubmit(handleUpdate)}>
                        <FormField
                            control={form.control}
                            name="branchID"
                            render={({ field }) => {
                                return (
                                    <FormItem className="w-full px-10 py-2">
                                        <FormLabel>Job Title</FormLabel>
                                        <Select onValueChange={field.onChange}>
                                            <FormControl>
                                                <SelectTrigger>
                                                    <SelectValue placeholder="Select Branch" />
                                                </SelectTrigger>
                                            </FormControl>

                                            <SelectContent>
                                                <SelectGroup>
                                                    <SelectLabel>Select Title</SelectLabel>
                                                    {branachData?.map((i) => (
                                                        <SelectItem key={i.idx} value={i.idx.toString()}>
                                                            {i.branch_name}
                                                        </SelectItem>
                                                    ))}
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                        <FormMessage />
                                    </FormItem>
                                );
                            }}
                        />
                        <div className="pl-10">
                            <Button type="submit" >Save</Button>
                        </div>
                    </form>
                </Form>
            </div>
        </div>
    )
}

import { createContext, useContext, useMemo } from "react";

import { useLocalStorage } from "./useLocalStorage";
import { AccountData } from "@/data/LoginData";
import { useNavigate } from "react-router";
// Define the type for the context value
interface AuthContextType {
  user: any; // You can replace 'any' with the actual type of your user object
  login: (data: AccountData) => void; // Define the type for the login function
  logout: () => void; // Define the type for the logout function
}

// Create the context with a default value of null
const AuthContext = createContext<AuthContextType | null>(null);

export default AuthContext;
export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useLocalStorage("user", null);
  // const [user, setUser] = useLocalStorage<AccountData[]>("user", []); // 'user' is the key, [] is the default value

  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  // const login = async (data: any) => {
  //   setUser(data);
  //   navigate("/profile");
  // };
  const login = async (data: AccountData) => {
    if (data) {
      setUser(data);
      // localStorage.setItem("login_token", access_Token);
      localStorage.setItem("userDetails", JSON.stringify(data));
      localStorage.setItem("session_token", data.session_token);
      localStorage.setItem("refresh_token", data.refresh_token);
      // navigate("/");
      if (data.title_id === 1 || data.title_id === 2) {
        navigate("/admin");
      } else if (data.title_id > 2) {
        navigate("/branch");
      }
    } else {
      setUser(null);
      localStorage.removeItem("userDetails");
      localStorage.removeItem("session_token");
      localStorage.removeItem("refresh_token");
      navigate("/", { replace: true });
    }
  };

  // const login = (data: any) => {
  //   setUser(data);
  //   if (data) {
  //     navigate("/admin", { replace: true });
  //   } else {
  //     navigate("/", { replace: true });
  //   }
  // };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    localStorage.clear();
    navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

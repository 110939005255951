import ProductView from "@/components/product/product/ProductView";
import VariantList from "@/components/product/variant/VariantList";
// interface ProductEditProps {
//   productFiltered: ProductsData[];
// }

// const VariantLayout: React.FC<ProductProps> = ({ productFiltered }) => {
const VariantLayout = () => {
  return (
    <div >
      <ProductView />
      <VariantList />
    </div>
  );
};

export default VariantLayout;

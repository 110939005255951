
import { Button } from "@/components/ui/button";
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { toast } from "@/components/ui/use-toast";
import { AccountBranch } from "@/interfaces/AccountModel";
import { ProductBetaRateModel, VariantBetaModel } from "@/interfaces/ProductRateModel";
import { APICallParams, makeWithOutAuthenticatedAPICall } from "@/utils/apiCall";
import { useEffect, useState } from "react";
import { FaPen } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router";

export default function BProductRate() {
    const location = useLocation();
    const productRateData: ProductBetaRateModel = location.state.productRateData;
    const [branches, setBranches] = useState<AccountBranch[]>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [variantRate, setVariantRate] = useState<VariantBetaModel[]>();
    const navigate = useNavigate();

    function getBranchName() {
        const data = branches?.find((i) => i.idx === productRateData.branch_id)
        return data?.branch_name
    }
    const loadVariantRate = async () => {
        try {
            const apiParams: APICallParams = {
                verb: "POST",
                url: "/beta_testing/branch_load_variants",
                params: {},
                body: {
                    branch_id: productRateData.branch_id,
                    product_id: productRateData.product_id
                },
            };

            const response = await makeWithOutAuthenticatedAPICall(apiParams)
            setVariantRate(response.data)
        } catch (error) {
            setError((error as Error).message);
        } finally {
            setLoading(false);
        }
    }

    const handleProductEditClick = () => {
        const fill = productRateData
        navigate("/branch/" + productRateData.branch_id + "/products/" + productRateData.product_id + "/edit", { state: { productPriceData: fill } })

    }

    const handleVariantClick = (vid: number) => {
        const fill = variantRate?.find((i) => i.variant_id === vid)
        const fill2 = productRateData
        navigate("/branch/" + productRateData.branch_id + "/products/" + productRateData.product_id + "/variant", { state: { variantData: fill, productRateData: fill2 } })

    }

    const loadBranch = async () => {
        try {
            // const response = await fetchBranches()
            const apiParams: APICallParams = {
                verb: "GET",
                url: "/wet_market/branches_load",
                params: {},
                body: {},
            };
            const response = await makeWithOutAuthenticatedAPICall(apiParams)
            if (response.status !== 200) {
                toast({
                    variant: "destructive",
                    title: "Branch",
                    description: response.response.data.message,
                });
            } else {
                setBranches(response.data)
                const branchesString = JSON.stringify(response);
                localStorage.setItem('branchesLS', branchesString);
            }

        } catch (error) {
            setError((error as Error).message);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadVariantRate()
        if (!localStorage.getItem("branchesLS")) {
            loadBranch()
        } else {
            const branchesFromStorage = localStorage.getItem("branchesLS")
            const parsedbranches = branchesFromStorage ? JSON.parse(branchesFromStorage) : undefined;
            setBranches(parsedbranches)
            setLoading(false);
        }
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }
    return (
        <div className="p-2 rounded-sm border border-gray-200 flex flex-col flex-1 h-screen gap-2">
            <div className="flex flex-row gap-2">
                <p>Branch </p>  <strong className="bg-white">{getBranchName()}</strong><p> Product Pricing</p>

            </div>

            <div className="bg-white flex flex-row h-[14rem] p-2 gap-2">
                <div className="flex flex-col w-[150px] gap-2">
                    <img className="h-[150px] w-[150px]" src={productRateData?.img_url} alt={productRateData?.img_url} />
                    <strong >
                        <p className="max-w-xs">
                            {productRateData.product_name}
                        </p>
                    </strong>
                </div>
                <div className="flex flex-col w-[32rem] gap-2">
                    <div className="border border-gray-200 h-[150px] p-2">
                        {/* Description */}
                    </div>
                    {variantRate?.length ? (
                        <></>
                    ) : (
                        <div className="flex flex-row justify-end items-center">
                            <strong className="font-extrabold">₱ {productRateData.orig_price}</strong>
                            <Button variant="ghost" onClick={() => handleProductEditClick()}>
                                <FaPen />
                            </Button>
                        </div>
                    )
                    }

                </div>



            </div>
            <div className="flex flex-1 flex-col bg-white h-screen overflow-y-auto pb-[5rem]  p-2">
                <Table>
                    <TableCaption>A list of your Product.</TableCaption>
                    <TableHeader>
                        <TableRow>
                            <TableHead className="w-[100px]">ID</TableHead>
                            <TableHead>Image</TableHead>
                            <TableHead>Variant</TableHead>
                            <TableHead>Rate</TableHead>
                            <TableHead className="w-[100px]">Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {variantRate?.map((i) => (
                            <TableRow key={i.variant_id}>
                                <TableCell className="w-[100px]">{i.variant_id}</TableCell>
                                <TableCell>
                                    <img
                                        src={i.image_url}
                                        className="mx-auto w-10 items-center"
                                        alt={i.image_url}
                                    />
                                </TableCell>
                                <TableCell>{i.variant_type}</TableCell>
                                <TableCell>{i.orig_price}</TableCell>
                                <TableCell>
                                    <Button onClick={() => handleVariantClick(i.variant_id)}>
                                        Edit
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </div>
    )
}
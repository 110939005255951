import { APICallParams, makeWithOutAuthenticatedAPICall } from "@/utils/apiCall";
import { useEffect, useState } from "react"
import { toast } from "../ui/use-toast";
import { RiderStatusModel } from "@/interfaces/RiderModel";
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";
import { FaCircle, FaCircleXmark } from "react-icons/fa6";
import { Button } from "../ui/button";
import { useNavigate } from "react-router";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../ui/tooltip";



export default function RidersStatus() {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [riderStatus, setRiderStatus] = useState<RiderStatusModel[]>([])
    const navigate = useNavigate();

    const getRiderStatus = async () => {
        try {
            const apiParams: APICallParams = {
                verb: "GET",
                url: "/rider/screening_status",
                params: {},
                body: {},
            };
            const response = await makeWithOutAuthenticatedAPICall(apiParams)
            console.log(response.data)
            if (response.status !== 200) {
                toast({
                    variant: "destructive",
                    title: "New Product Rate/Price",
                    description: response.response.data.message,
                });
            } else {
                if (response.data[0]['itm']) {
                    const unescapedJsonStr = response.data[0]['itm'].replace(/\\"/g, '"');
                    const jsonData = JSON.parse(unescapedJsonStr);
                    setRiderStatus(jsonData)
                }
            }
        } catch (error) {
            setError((error as Error).message);
        } finally {
            setLoading(false);
        }
        // } catch (error) {
        //     throw new Error(`Error fetching data: ${error}`);
        // }
    }
    const handleRiderStatusClick = () => {
        navigate("/admin/riders")
    }

    const handleViewClick = (userID: number) => {
        const fill = riderStatus.find((i) => i.user_id === userID)
        navigate("/admin/riders/status/" + userID, { state: { riderData: fill } });
    }

    useEffect(() => {
        getRiderStatus();
    }, [riderStatus]);


    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }
    return (
        <div className="bg-white pb-[5rem] rounded-sm border border-gray-200 flex flex-col flex-1 h-screen gap-2">
            <div className="p-2 items-center gap-4 flex flex-row justify-between pr-4">
                <strong className="text-gray-700 font-medium">Rider applications accepted and decline </strong>
                {/* <div className="items-center gap-4 flex flex-row justify-between">
                    <p>Status :</p>
                    <FaCircle style={{ color: 'green' }} />
                    <p>Accepted</p>
                    <FaCircleXmark style={{ color: 'red' }} />
                    <p>Decline</p>
                </div> */}
                <Button variant="destructive" onClick={() => handleRiderStatusClick()}>
                    Pending Applications
                </Button>
            </div>
            <Table>
                <TableCaption>A list of your Rrider Applicant.</TableCaption>
                <TableHeader>
                    <TableHead className="w-[100px]">ID</TableHead>
                    {/* <TableHead>Image</TableHead> */}
                    <TableHead>First Name</TableHead>
                    <TableHead>Last Name</TableHead>
                    <TableHead>Vehicle</TableHead>
                    <TableHead className="w-[100px]">Status</TableHead>
                    <TableHead className="w-[100px]">Action</TableHead>
                </TableHeader>
                <TableBody>
                    {riderStatus.map((i) => (
                        <TableRow key={i.user_id}>
                            <TableCell className="font-medium">{i.user_id}</TableCell>
                            {/* <TableCell>
                                <img
                                    src={i.selfie}
                                    className="mx-auto w-10 items-center"
                                    alt={i.selfie}
                                />
                            </TableCell> */}
                            <TableCell>{i.fname}</TableCell>
                            <TableCell>{i.lname}</TableCell>
                            <TableCell>
                                <img
                                    src={i.vehicle_or}
                                    className="mx-auto w-10 items-center"
                                    alt={i.vehicle_or}
                                />
                            </TableCell>
                            <TableCell>
                                {/* {i.isaccepted === 1 ? <FaCircle style={{ color: 'green' }} /> : <FaCircleXmark style={{ color: 'red' }} />}
                            */}
                                <TooltipProvider>
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <p> {i.isaccepted === 1 ? <FaCircle style={{ color: 'green', filter: 'drop-shadow(0px 2px 2px darkgreen)' }} /> : <FaCircleXmark style={{ color: 'red', filter: 'drop-shadow(0px 2px 2px darkred)' }} />}
                                            </p>
                                        </TooltipTrigger>
                                        <TooltipContent className="bg-white text-black border border-gray-200">
                                            {i.isaccepted === 1 ? <p>Accepted</p> : <p>Decline</p>}
                                        </TooltipContent>
                                    </Tooltip>
                                </TooltipProvider>
                            </TableCell>

                            <TableCell>
                                <Button variant="outline" onClick={() => handleViewClick(i.user_id)}>
                                    View
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}

import request from "@/api/Api"
import { API_KEY } from "@/api/constant";
import { AccountBranch } from "@/interfaces/AccountModel";


export const fetchBranches = async () => {
    try {
        const data = await request<AccountBranch[]>({
            url: "/wet_market/branches_load",
            method: "GET",
            data: {},
            headers: {
                X_API_Key: API_KEY,
            },
        });
        return data
    } catch (error) {
        throw new Error(`Error fetching data: ${error}`);
    }
}
import classNames from "classnames";
import {
  SIDEBAR_BUTTOM_LINKS,
  SIDEBAR_TOP_LINKS,
} from "@/lib/constants/navigation";
import { Component } from "react";
import { HiOutlineLibrary } from "react-icons/hi";
import { Link, useLocation } from "react-router-dom";

const linkClasses =
  "flex item-center gap-2 font-light px-3 py-2 hover:bg-neutral-700 hover:no-underline active: bg-[#212121] rounded-sm text-base";

export default class Sidebar extends Component {
  render() {
    return (
      <div className="bg-[#212121] flex flex-col w-60 p-3 ">
        <div className="flex items-center gap-2 px-1 py-3 text-white">
          <HiOutlineLibrary fontSize={24} />
          <p className="text-neutral-100 text-lg">Branch Name</p>
        </div>
        <div className="flex-1 py-8 flex flex-col gap-0.5 pt-2 border-t  border-neutral-700">
          {SIDEBAR_TOP_LINKS?.map((item) => (
            <SidebarLink key={item.key} item={item} />
          ))}
        </div>
        <div className="flex flex-col gap-0.5 pt-2 border-t cursor-pointer border-neutral-700">
          {SIDEBAR_BUTTOM_LINKS.map((item) => (
            <SidebarLink key={item.key} item={item} />
          ))}
        </div>
      </div>
    );
  }
}

function SidebarLink({ item }: any) {
  const { pathname } = useLocation();
  return (
    <Link
      to={item.path}
      className={classNames(
        pathname === item.path ? "text-white" : "text-gray-400",
        linkClasses
      )}
    >
      <span className="text-xl">{item.icon}</span>
      {item.label}
    </Link>
  );
}

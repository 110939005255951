import CustomLabel from "@/utils/customLabel";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../ui/dialog";
import React, { ChangeEvent, useState } from "react";
import { Button } from "../ui/button";
import { toast } from "../ui/use-toast";
import { APICallParams, makeAuthenticatedAPICall } from "@/utils/apiCall";
import { useLocation } from 'react-router';
import { AccountModel } from "@/interfaces/AccountModel";

interface ChangeEmailDialogProps {
    open: boolean;
    onClose: (data: AccountModel) => void; // onClose function prop to close the dialog
}

const ChangeEmailDialog: React.FC<ChangeEmailDialogProps> = ({ open, onClose }) => {
    const location = useLocation();
    // const accountDetails: AccountModel = location.state?.accountDetails;

    const [email, setEmail] = useState<string>("");
    const [email2, setEmail2] = useState<string>("");
    const [isValid, setIsValid] = useState(true);
    const [accountDetails, setAccountDetails] = useState<AccountModel>(location.state?.accountDetails);


    if (!open) return null; // If open is false, don't render the dialog

    const handleClose = () => {
        onClose(accountDetails);
    }
    const handleVerify = () => {

        if (!isValid) {
            toast({
                title: "Email Address",
                description: "Please enter email address",
            });
        } else {

            if (email !== email2) {
                toast({
                    title: "Email Address",
                    description: "New email not match",
                });
            } else if (!email) {
                toast({
                    title: "Email Address",
                    description: "Please input email address",
                });

            } else {
                updateEmail(accountDetails.id, email)

                onClose(accountDetails);
            }
        }
    }

    const updateEmail = async (userID: number, email: string) => {
        const apiParams: APICallParams = {
            verb: "PATCH",
            url: "/v1/admin/" + userID + "/email",
            params: { email_address: email },
            body: {},
        };
        const response = await makeAuthenticatedAPICall(apiParams);


        if (response.status !== 200) {
            toast({
                title: "Email Address",
                description: "Failed Update.",
            });
        } else {
            setAccountDetails(response.data)
            toast({
                title: "Email Address",
                description: "Successfully Update.",
            });
        }
    }

    const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value)
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValid(emailRegex.test(event.target.value));
    }


    const handleEmail2Change = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail2(event.target.value)
    }

    return (
        <Dialog open={open}>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Enter New Email Address</DialogTitle>
                </DialogHeader>

                <CustomLabel type="text"
                    placeholder="Email"
                    readOnly={false}
                    label="Email Address"
                    htmlFor="email" // Pass htmlFor as a prop
                    value={email}
                    onChange={handleEmailChange}
                />
                {!isValid && <p style={{ color: 'red' }}>Please enter a valid email address.</p>}

                <CustomLabel type="text"
                    placeholder="Re-type email"
                    readOnly={false}
                    label="Re-type your email"
                    htmlFor="email2" // Pass htmlFor as a prop
                    value={email2}
                    onChange={handleEmail2Change}
                />

                <DialogFooter >

                    <Button onClick={handleVerify}>Update</Button>
                    <Button onClick={handleClose}> Close</Button>

                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}

export default ChangeEmailDialog;
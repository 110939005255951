import { HiOutlineBell, HiOutlineChatAlt2 } from "react-icons/hi";
import { Label } from "./ui/label";
import { Button } from "./ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { Avatar, AvatarImage } from "./ui/avatar";
import { Link } from "react-router-dom";
import { useAuth } from "@/hooks/useAuth";

function Header() {
  let user = useAuth();

  const logout = async () => {
    await user?.logout();
  };

  return (
    <div className="bg-white h-16  px-4 flex justify-between items-center border-b border-gray-200">
      <div className="relative"></div>
      <div className="flex items-center gap-4 mr-2">
        <Popover>
          <PopoverTrigger asChild>
            <Button variant="ghost">
              <HiOutlineChatAlt2 fontSize={24} />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-72 origin-top-right z-10 absolute -right-7">
            <div className="grid gap-4 ">
              <div className="space-y-2">
                <h4 className="font-medium leading-none">Message</h4>
                <p className="text-sm text-muted-foreground">
                  This is the message panel
                </p>
              </div>
              <div className="grid gap-2 cursor-pointer"></div>
            </div>
          </PopoverContent>
        </Popover>
        <Popover>
          <PopoverTrigger asChild>
            <Button variant="ghost" className="hover:bg-none">
              <HiOutlineBell fontSize={24} />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-72 origin-top-right z-10 absolute -right-7">
            <div className="grid gap-4 ">
              <div className="space-y-2">
                <h4 className="font-medium leading-none">Notification</h4>
                <p className="text-sm text-muted-foreground">
                  This is the notification panel
                </p>
              </div>
              <div className="grid gap-2 cursor-pointer"></div>
            </div>
          </PopoverContent>
        </Popover>
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              className="border-none hover:bg-transparent"
            >
              <Avatar>
                <AvatarImage
                  src="https://github.com/shadcn.png"
                  alt="@shadcn"
                />
                {/* <AvatarFallback>CN</AvatarFallback> */}
              </Avatar>
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-80 origin-top-right z-10 absolute -right-7">
            <div className="grid gap-4 ">
              <div className="space-y-2">
                <h4 className="font-medium leading-none">
                  {user?.user.first_name} {user?.user.middle_name + " "}
                  {user?.user.last_name}
                </h4>
                <p className="text-sm text-muted-foreground">Branch Manager</p>
              </div>
              <div className="grid gap-2 cursor-pointer">
                <Link
                  to="/account/settings"
                  className="grid grid-cols-3 items-center gap-4 py-1 flex-col hover:bg-gray-50"
                >
                  <Label htmlFor="settings" className="cursor-pointer">
                    Settings
                  </Label>
                </Link>

                <Link
                  to="/"
                  onClick={logout}
                  className="cursor-pointer text-red-500"
                >
                  <Label
                    htmlFor="logout"
                    className="cursor-pointer text-red-500"
                  >
                    Log Out
                  </Label>
                </Link>
              </div>
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
}

export default Header;

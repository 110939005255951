import { Route, Routes } from "react-router";
import "/app/globals.css";
import Layout from "@/layouts/Layout";
import NotFound from "@/pages/NotFound";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "@/hooks/useAuth";
import ProductList from "@/components/product/product/ProductList";
import Products from "@/pages/Products";
import ProductLayout from "@/layouts/ProductLayout";
import VariantPage from "@/pages/VariantPage";
import Variant from "@/components/product/variant/Variant";
import DashboardAdminPage from "@/pages/DashboardAdminPage";
import BranchLayout from "@/layouts/BranchLayout";
import BranchList from "@/components/branch/Branches";
import BranchNew from "@/components/branch/Branch";
import BranchEdit from "@/components/branch/BranchEdit";
import ProductEdit from "@/components/product/product/ProductEdit";
import AccountLayout from "@/layouts/AccountLayout";
import Accounts from "@/components/accounts/Accounts";
import { Login } from "@/pages/LoginPage";
import VariantEdit from "@/components/product/variant/VariantEdit";
import Account from "@/components/accounts/Account";
import TitleLayout from "@/layouts/TitleLayout";
import JobTitles from "@/components/jobTitle/JobTitles";
import JobTitle from "@/components/jobTitle/JobTitle";
import JobTitleEdit from "@/components/jobTitle/JobTitleEdit";
import Riders from "@/components/rider/Riders";
import Applicants from "@/components/branch/Applicants";
import Activities from "@/components/accounts/Activities";
import RateLayout from "@/layouts/RateLayout";
import ProductRateBase from "@/components/productRate/ProductRateBase";
import VariantRateBase from "@/components/productRate/VariantRateBase";
import VariantRateEdit from "@/components/productRate/VariantRateEdit";
import UserLayout from "@/layouts/UserLayout";
import AccountBranches from "@/components/accounts/AccountBranches";
import AccountBranchNew from "@/components/accounts/AccountBranchNew";
import { DashboardPage } from "@/pages/DashboardPage";
import ProtectedRouteAdmin from "./ProtectedRouteAdmin";
import ProtectedRouteUser from "./ProtectedRouteUser";
import ACPermissions from "@/components/accounts/ACPermissions";
import ACPermission from "@/components/accounts/ACPermission";
import Permissions from "@/components/permission/Permissions";
import Permission from "@/components/permission/Permission";
import PermissionsLayout from "@/layouts/PermissionsLayout";
import PermissionEdit from "@/components/permission/PermissionEdit";
import CommissionLayout from "@/layouts/CommissionLayout";
import RateAndCommissions from "@/components/rate_and_commission/RateAndCommissions";
import RateAndCommission from "@/components/rate_and_commission/RateAndCommission";
import Orders from "@/components/order/Orders";
import OrderLayout from "@/layouts/OrderLayout";
import Order from "@/components/order/Order";
import BProductRates from "@/components/productRate/BProductRates";
import BranchSelect from "@/components/productRate/BranchSelect";
import BProductLayout from "@/layouts/BProductLayout";
import EProductRate from "@/components/productRate/EProductRate";
import BProductRate from "@/components/productRate/BProductRate";
import EVariantRate from "@/components/productRate/EVariantRate";
import EBProductLayout from "@/layouts/EBProductLayout";
import DefaultLayout from "@/layouts/DefaultLayout";
import EBProductRate from "@/components/productRate/EBProductRate";
import OrderBranches from "@/components/order/OrderBranches";
import OrderReceivedBranches from "@/components/order/OrderReceivedBranches";
import OrderReceived from "@/components/order/OrderReceived";
import OrderReceive from "@/components/order/OrderReceive";
import OrderProcessBranches from "@/components/order/OrderProcessBranches";
import OrderProcesses from "@/components/order/OrderProcesses";
import OrderProcess from "@/components/order/OrderProcess";
import RiderLayout from "@/layouts/RiderLayout";
import Rider from "@/components/rider/Rider";
import RidersStatus from "@/components/rider/RidersStatus";
import RiderStatusLayout from "@/layouts/RiderStatusLayout";
import RiderStatus from "@/components/rider/RiderStatus";

const Router = () => {

  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          {/* <Route path="/" element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }          >

            <Route index element={<DashboardAdminPage />} />

            <Route path="/admin/rateandcommission" element={<RateAndCommission />} />
            <Route path="/admin/riders" element={<Riders />} />
            <Route path="/admin/Applicants" element={<Applicants />} />

            //done
            <Route path="/admin/branch" element={<BranchLayout />}>
              <Route index element={<BranchList />} />
              <Route path="/admin/branch/new" element={<BranchNew />} />
              <Route path="/admin/branch/:id" element={<BranchEdit />} />
            </Route>

            //done
            <Route path="/admin/products" element={<ProductLayout />}>
              <Route index element={<ProductList />} />
              <Route path="/admin/products/new" element={<Products />} />
              <Route path="/admin/products/:id" element={<VariantPage />} />
              <Route path="/admin/products/Edit/:id" element={<ProductEdit />} />
              <Route path="/admin/products/:id/variant" element={<Variant />} />
              <Route path="/admin/products/:id/variant/:id" element={<VariantEdit />} />
            </Route>

            //done
            <Route path="/admin/accounts" element={<AccountLayout />}>
              <Route index element={<Accounts />} />
              <Route path="/admin/accounts/new" element={<Account />} />
              <Route path="/admin/accounts/Activities" element={<Activities />} />
              <Route path="/admin/accounts/:id" element={<UserLayout />} >
                <Route index element={<AccountBranches />} />
                <Route path="/admin/accounts/:id/new" element={<AccountBranchNew />} />
              </Route>
            </Route>
            
            //done
            <Route path="/admin/title" element={<TitleLayout />} >
              <Route index element={<JobTitles />} />
              <Route path="/admin/title/new" element={<JobTitle />} />
              <Route path="/admin/title/:id" element={<JobTitleEdit />} />
            </Route>


            //done
            <Route path="/branch/product/rates" element={<RateLayout />}>
              <Route index element={<ProductRateBase />} />
              <Route path="/branch/product/rates/base" element={<ProductRateBase />} />
              <Route path="/branch/product/rates/Variantbase" element={<VariantRateBase />} />
              <Route path="/branch/product/rates/Edit/:id" element={<VariantRateEdit />} />
            </Route>
          </Route> */}

          {/* <Route path="/sample" element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }>

            <Route index element={<DashboardPage />} />
            <Route path="/sample/rateandcommission" element={<RateAndCommission />} />

          </Route> */}

          <Route element={<ProtectedRouteAdmin />}>
            <Route path="/admin" element={<Layout />} >
              <Route index element={<DashboardAdminPage />} />
              <Route path="dashboard" element={<DashboardAdminPage />} />

              <Route path="title" element={<TitleLayout />} >
                <Route index element={<JobTitles />} />
                <Route path="new" element={<JobTitle />} />
                <Route path=":id" element={<JobTitleEdit />} />
              </Route>

              <Route path="branch" element={<BranchLayout />}>
                <Route index element={<BranchList />} />
                <Route path="new" element={<BranchNew />} />
                <Route path=":id" element={<BranchEdit />} />
              </Route>

              <Route path="accounts" element={<AccountLayout />}>
                <Route index element={<Accounts />} />
                <Route path="new" element={<Account />} />
                <Route path="activities" element={<Activities />} />

                <Route path=":id" element={<UserLayout />}>
                  <Route index element={<AccountBranches />} />
                  <Route path="branch" element={<AccountBranchNew />} />
                  <Route path="permissions" element={<ACPermissions />} />
                  <Route path="permission" element={<ACPermission />} />
                </Route>
              </Route>

              <Route path="products" element={<ProductLayout />}>
                <Route index element={<ProductList />} />
                <Route path="new" element={<Products />} />
                <Route path=":id" element={<VariantPage />} />
                <Route path="edit/:id" element={<ProductEdit />} />
                <Route path=":id/variant" element={<Variant />} />
                <Route path=":id/variant/:id" element={<VariantEdit />} />
              </Route>

              {/* <Route path="riders" element={<Riders />} /> */}
              <Route path="riders" element={<RiderLayout />}>
                <Route index element={<Riders />} />
                <Route path=":id" element={<Rider />} />
                <Route path="status" element={<RiderStatusLayout />} >
                  <Route index element={<RidersStatus />} />
                  <Route path=":id" element={<RiderStatus />} />
                </Route>
              </Route>

              <Route path="permissions" element={<PermissionsLayout />} >
                <Route index element={<Permissions />} />
                <Route path="New" element={<Permission />} />
                <Route path=":id" element={<PermissionEdit />} />
              </Route>


              <Route path="commissions" element={<CommissionLayout />}>
                <Route index element={<RateAndCommissions />} />
                <Route path="Edit" element={<RateAndCommission />} />
              </Route>
              <Route path="Applicants" element={<Applicants />} />


            </Route>
          </Route>

          <Route element={<ProtectedRouteUser />}>
            <Route path="/branch" element={<Layout />} >
              <Route index element={<DashboardPage />} />
              <Route path="dashboard" element={<DashboardPage />} />

              {/* <Route path="product/rates" element={<RateLayout />}>
                <Route index element={<BranchSelect />} />
                {/* <Route index element={<BProductRates />} /> */}
              {/* <Route path=":id" element={<BProductRate />} /> */}
              {/* <Route path="new" element={<ProductRateBase />} />
                <Route path="variantbase" element={<VariantRateBase />} /> */}
              {/* <Route path=":id" element={<ProductRateBaseEdit />} />
                <Route path="base" element={<ProductRateBase />} />
                <Route path="variantbase" element={<VariantRateBase />} />
                <Route path="edit/:id" element={<VariantRateEdit />} /> */}
              {/* </Route>  */}

              <Route path="choose" element={<BranchSelect />} />
              {/* <Route path="select" element={<RateLayout />}>
                <Route index element={<BranchSelect />} />
                <Route path=":id" element={<RateLayout />} />BProductRateLayout
              </Route> */}
              <Route path=":id" element={<EBProductLayout />} >
                <Route index element={<RateLayout />} />
                <Route path="products" element={<BProductLayout />} >
                  <Route index element={<BProductRates />} />
                  <Route path=":id" element={<BProductLayout />} >
                    <Route index element={<BProductRate />} />
                    <Route path="edit" element={<EProductRate />} />
                    <Route path="variant" element={<EVariantRate />} />
                  </Route>
                </Route>
                <Route path="baseproduct" element={<BProductLayout />}>
                  <Route index element={<ProductRateBase />} />
                  {/* <Route path=":id/variants" element={<VariantRateBase />} >
                    <Route path=":id" element={<VariantRateEdit />} />
                  </Route> */}
                  <Route path=":id" element={<DefaultLayout />} >
                    <Route index element={<DefaultLayout />} />
                    <Route path="rate" element={<EBProductRate />} />
                    <Route path="variants" element={<DefaultLayout />} >
                      <Route index element={<VariantRateBase />} />
                      <Route path=":id" element={<VariantRateEdit />} />
                    </Route>
                  </Route>

                </Route>


              </Route>
              <Route path="order_branches" element={<OrderBranches />} />
              <Route path=":id/orders" element={<OrderLayout />} >
                <Route index element={<Orders />} />
                <Route path=":id" element={<Order />} />
              </Route>
              <Route path="order_received_branches" element={<OrderReceivedBranches />} />
              <Route path=":id/order_received" element={<DefaultLayout />} >
                <Route index element={<OrderReceived />} />
                <Route path=":id" element={<OrderReceive />} />
              </Route>
              <Route path="order_process_branches" element={<OrderProcessBranches />} />
              <Route path=":id/order_process" element={<DefaultLayout />} >
                <Route index element={<OrderProcesses />} />
                <Route path=":id" element={<OrderProcess />} />
              </Route>
            </Route>
            {/* <Route path="orders" element={<OrderLayout />} >
                <Route index element={<Orders />} />
                <Route path=":id" element={<Order />} />
              </Route> */}


          </Route>



          <Route path="/" element={<Login />} />
          {/* <Route path="/" element={<BranchSelect />} /> */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter >
  );
};

export default Router;


import { Button } from '../ui/button'
import { FaPlus } from 'react-icons/fa6'
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from '../ui/table'
import { useNavigate } from 'react-router';
import { APICallParams, makeAuthenticatedAPICall } from '@/utils/apiCall';
import { useEffect, useState } from 'react';
import { TitleModel } from '@/interfaces/TitleModel';

function JobTitles() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [title, setTitle] = useState<TitleModel[]>()

    const loadTitle = async () => {
        try {
            const apiParams: APICallParams = {
                verb: "GET",
                url: "/v1/admin-title/",
                params: { is_active: true, page: 0, limit: 100 },
                body: {},
            };

            const response = await makeAuthenticatedAPICall(apiParams);

            if (response.status === 200) {
                setTitle(response.data)
            }
        } catch (error) {
            setError((error as Error).message);
        } finally {
            setLoading(false);
        }

    }

    const handleClickNew = () => {
        navigate("/admin/title/new");
    };

    const handleEditClick = (titleID: number) => {
        const fill = title?.find((i) => i.id === titleID)

        navigate("/admin/title/" + titleID, { state: { titleD: fill } });

    }


    useEffect(() => {
        loadTitle();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }
    return (
        <div className="w-full bg-white p-4 rounded-sm border border-gray-200 flex  flex-col h-screen">
            <div className="flex justify-end items-center pb-4">
                <Button onClick={() => handleClickNew()}>
                    <FaPlus className="mr-2 h-4 w-4 " />
                    New Job Title
                </Button>
            </div>
            <Table>
                <TableCaption>A list of Job Title.</TableCaption>
                <TableHeader>
                    <TableRow>
                        <TableHead className="w-[100px]">ID</TableHead>
                        <TableHead>Job Title</TableHead>
                        <TableHead className="w-[100px]">Actions</TableHead>
                    </TableRow>
                </TableHeader>

                <TableBody>
                    {title?.map((i) => (
                        <TableRow key={i.id.toString()}>
                            <TableCell className="font-medium">{i.id}</TableCell>
                            <TableCell>{i.title}</TableCell>
                            <TableCell className="flex gap-2 text-right">
                                <Button variant="outline" onClick={() => handleEditClick(i.id)}>
                                    Edit
                                </Button>

                                <Button variant="destructive" >
                                    Disable/Enable
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}

export default JobTitles
import { ProductBase, ProductsData } from "@/data/ProductData";
import request from "@/api/Api"; // Adjust the path as needed
import { CategoryModel, ProductBetaRateModel, ProductRateModel, VariantBetaModel } from "@/interfaces/ProductRateModel";
import { API_KEY } from "@/api/constant";

export async function fetchDataWithParams(paramValue: any) {
  try {
    const data = await request<ProductsData[]>({
      url: "/beta_testing/branch_load_items",
      method: "GET",
      params: paramValue,
      headers: {
        X_API_Key:
          "8QQcf8UDS3W2Irx1LuENk6euFo12W23iqXpfGmojEJowIwfg41jPY9nQyZhEYfzl",
      },
    });
    return data;
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`);
  }
}

export async function fetchBaseProduct(parameter: any) {
  try {
    const data = await request<ProductBase[]>({
      url: "/v1/wet_market/products",
      method: "GET",
      params: parameter,
      headers: {
        X_API_Key:
          "8QQcf8UDS3W2Irx1LuENk6euFo12W23iqXpfGmojEJowIwfg41jPY9nQyZhEYfzl",
      },
    });
    return data;
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`);
  }
}

export const fetchPrductRate = async (paramValue: any) => {
  try {
    const data = await request<ProductRateModel[]>({
      url: "/wet_market/product_price_load",
      method: "POST",
      data: paramValue,
      headers: {
        X_API_Key: API_KEY,
      },
    });
    return data
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`);

  }
}


export const fetchBetaProductRate = async (paramValue: any) => {
  try {
    const data = await request<ProductBetaRateModel[]>({
      url: "/beta_testing/branch_load_items",
      method: "GET",
      params: paramValue,
      headers: {
        X_API_Key: API_KEY,
      },
    });
    return data
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`);

  }
}

export const fetchBetaVariantRate = async (paramValue: any) => {
  try {
    const data = await request<VariantBetaModel[]>({
      url: "/beta_testing/branch_load_variants",
      method: "POST",
      data: paramValue,
      headers: {
        X_API_Key: API_KEY,
      },
    });
    return data
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`);
  }
}


export const fetchCategory = async () => {
  try {
    const data = await request<CategoryModel[]>({
      url: "/wet_market/product_category_load",
      method: "GET",
      data: {},
      headers: {
        X_API_Key: API_KEY,
      },
    });
    return data
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`);
  }
}

export const fetchBProductRates = async () => {
  try {
    const data = await request<ProductBetaRateModel[]>({
      url: "/wet_market/product_category_load",
      method: "GET",
      data: {},
      headers: {
        X_API_Key: API_KEY,
      },
    })
    return data;
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`);
  }
}

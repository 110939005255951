interface viber_svg_props {
  width: string;
  height: string;
}

const Viber_svg: React.FC<viber_svg_props> = ({ width, height }) => {
  const containerStyle: React.CSSProperties = {
    width: width,
    height: height,
  };
  return (
    <div style={containerStyle}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
      >
        <g fill="#8e24aa">
          <path d="M22 0H2a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm-1.539 13.42c-.516 4.379-3.707 4.801-4.271 4.991-.244.081-2.389.636-5.182.394-.31.37-.717.854-1.013 1.185-.54.601-.874 1.16-1.438.977-.469-.148-.442-.883-.442-.883l.005-1.814h-.004c-4.081-1.188-4.023-5.568-3.976-7.905.049-2.337.486-4.225 1.73-5.512 2.236-2.124 6.827-1.839 6.827-1.839 3.894.017 5.642 1.329 6.073 1.74l-.001.001c1.434 1.291 2.23 4.145 1.692 8.665z" />
          <path d="M12.626 4.283S8.728 4.02 6.835 5.795c-1.053 1.076-1.41 2.675-1.448 4.626s-.241 5.68 3.218 6.672c0 0-.014 2.77-.014 3.012-.003.369.194.386.374.2.55-.573 2.318-2.777 2.318-2.777 2.368.158 4.255-.327 4.458-.394.476-.158 3.062-.39 3.5-4.046v-.001c.452-3.771-.167-6.345-1.38-7.42-.365-.344-1.942-1.37-5.235-1.384zm3.795 10.284v.005c-.367.668-1.053 1.406-1.76 1.171l-.006-.01c-.717-.208-2.406-1.108-3.473-1.986a9.07 9.07 0 0 1-1.44-1.49c-.35-.454-.703-.991-1.047-1.638-.724-1.354-.885-1.958-.885-1.958-.227-.732.483-1.442 1.132-1.821h.007c.312-.169.611-.113.812.137 0 0 .421.52.602.777.17.239.397.622.516.837.207.383.078.773-.125.935l-.408.337c-.207.172-.18.492-.18.492s.605 2.366 2.865 2.964c0 0 .31.029.476-.186l.326-.422c.157-.211.534-.344.904-.13.5.292 1.135.745 1.556 1.156.237.2.291.506.128.83zm-3.788-6.709c-.289-.021-.258-.475.03-.454 1.971.148 3.021 1.55 2.984 3.252-.004.302-.445.288-.439-.011.035-1.49-.866-2.657-2.575-2.787zm1.885 2.391c.014.302-.425.323-.439.021-.038-.773-.387-1.15-1.108-1.192-.292-.017-.265-.471.024-.454.949.053 1.476.615 1.523 1.625zm1.849.871c-.02-2.864-1.865-4.426-4.105-4.443-.289-.004-.289-.454 0-.454 2.505.017 4.519 1.807 4.543 4.893h.001c.003.303-.439.306-.439.004z" />
        </g>
      </svg>
    </div>
  );
};

export default Viber_svg;

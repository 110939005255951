
import React, { ChangeEvent, useState } from 'react';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../ui/dialog';
import { Button } from '../ui/button';
import CustomLabel from '@/utils/customLabel';
import { toast } from '../ui/use-toast';
import { decryptPassword } from '@/utils/decrypt';
import { APICallParams, makeAuthenticatedAPICall } from '@/utils/apiCall';
import { AccountModel } from '@/interfaces/AccountModel';
import { useLocation } from 'react-router';

interface ChangePasswordDialogProps {
    open: boolean;
    onClose: () => void; // onClose function prop to close the dialog

}
const ChangePasswordDialog: React.FC<ChangePasswordDialogProps> = ({ open, onClose }) => {

    const location = useLocation();
    const accountDetails: AccountModel = location.state?.accountDetails;
    const [nPassword, setNPassword] = useState<string>("");
    const [nPassword2, setNPassword2] = useState<string>("");

    if (!open) return null; // If open is false, don't render the dialog

    const handleClose = () => {
        onClose();
    }

    const handleVerify = () => {
        // onClose();        
        // toast({
        //     title: "Check",
        //     description: nPassword2,
        // });

        if (nPassword !== nPassword2) {
            toast({
                title: "New Password",
                description: "New password did not match",
            });
        } else {
            const digest = decryptPassword(nPassword);
            updatePassword(accountDetails.id, digest.toString())

            onClose();
        }
    };


    const updatePassword = async (userID: number, digest: string) => {
        try {
            const apiParams: APICallParams = {
                verb: "PATCH",
                url: "/v1/admin/" + userID + "/password",
                params: { password: digest.toString() },
                body: {},
            };
            const response = await makeAuthenticatedAPICall(apiParams);
            if (response.status !== 204) {
                toast({
                    title: "Update Account",
                    description: "Failed Update.",
                });
            } else {
                toast({
                    title: "Update Account",
                    description: "Successfully Update.",
                });

            }
        } catch (error) {
            console.error("Error during update password:", error);
        }
    }



    const handlePassChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNPassword(event.target.value);
    };

    const handlePass2Change = (event: ChangeEvent<HTMLInputElement>) => {
        setNPassword2(event.target.value)
    }


    return (
        <Dialog open={open}>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Enter New Password</DialogTitle>
                </DialogHeader>

                <CustomLabel type="password"
                    placeholder="New Password"
                    readOnly={false}
                    label="New Password"
                    htmlFor="password" // Pass htmlFor as a prop
                    value={nPassword}
                    onChange={handlePassChange}
                />
                <CustomLabel type="password"
                    placeholder="Re-type password"
                    readOnly={false}
                    label="Re-type your password"
                    htmlFor="password" // Pass htmlFor as a prop
                    value={nPassword2}
                    onChange={handlePass2Change}
                />
                <DialogFooter >

                    <Button onClick={handleVerify}>Update</Button>
                    <Button onClick={handleClose}> Close</Button>

                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default ChangePasswordDialog;

import { AccountModel, AccountPermission } from "@/interfaces/AccountModel";
import { useLocation, useNavigate } from "react-router";
import { Button } from "../ui/button";
import { FaPlus } from "react-icons/fa6";
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";
import { APICallParams, makeAuthenticatedAPICall } from "@/utils/apiCall";
import { useEffect, useState } from "react";


function Permissions() {
    const navigate = useNavigate();
    const location = useLocation();
    const accountDetails: AccountModel = location.state?.accountDetails;
    const [permission, setPermission] = useState<AccountPermission[]>();

    const handleNBranchClick = () => {
        const fill = accountDetails
        navigate("/admin/accounts/" + accountDetails.id, { state: { accountDetails: fill } })
    }
    const handlePermissionClick = () => {
        const fill = accountDetails
        navigate("/admin/accounts/" + accountDetails.id + "/permission", { state: { accountDetails: fill } })

    }

    const loadPermission = async () => {
        try {

            const uri = "/v1/permission/" + accountDetails.id
            const apiParams: APICallParams = {
                verb: "GET",
                url: uri,
                params: {},
                body: {},
            };
            const response = await makeAuthenticatedAPICall(apiParams);

            if (response.status === 200) {
                setPermission([response.data])
            }
        } catch (error) {
            console.error("Error during Mobile Number:", error);
        }
    }
    useEffect(() => {
        loadPermission()
    }, []);

    return (
        <div className="flex flex-1 flex-col  gap-2 shadow-2xl h-screen">
            <div className="flex flex-row justify-between gap-2">
                <Button variant="destructive" onClick={() => handleNBranchClick()}>
                    Branch
                </Button>
                <Button onClick={() => handlePermissionClick()} >
                    <FaPlus className="mr-4 h-6 w-6" /> Permission
                </Button>
            </div>
            <Table>
                <TableCaption>A list of Permissions</TableCaption>
                <TableHeader>
                    <TableRow>
                        <TableHead className="w-[100px]">ID</TableHead>
                        <TableHead>API Actions</TableHead>
                        <TableHead>Group</TableHead>
                        <TableHead className="w-[100px]">Actions</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {permission?.map((i) => (
                        <TableRow key={i.id.toString()}>
                            <TableCell className="font-medium">{i.id}</TableCell>
                            <TableCell>{i.api_action}</TableCell>
                            <TableCell>{i.api_action_group}</TableCell>
                            <TableHead className="w-[100px]">Actions</TableHead>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}

export default Permissions
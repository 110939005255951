
import { Button } from "../ui/button";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { AccountModel } from "@/interfaces/AccountModel";
import { TitleModel } from "@/interfaces/TitleModel";
import { APICallParams, makeAuthenticatedAPICall } from "@/utils/apiCall";
import { GenderModel } from "@/interfaces/GenderModel";
import { FaPen } from "react-icons/fa6";
import "@/utils/customLabel"
import CustomLabel from "@/utils/customLabel";
import ChangePasswordDialog from "./ChangePassword";
import { toast } from "../ui/use-toast";
import ChangeEmailDialog from "./ChangeEmail";
import ChangeNumberDialog from "./ChangeNmber";
import ChangeTitleDialog from "./ChangeTitle"
import { Switch } from "../ui/switch";
import { useAuth } from "@/hooks/useAuth";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "../ui/alert-dialog";


// const formSchema = z.object({
//     username: z.string().min(8),
//     password: z.string().min(6),
//     email: z.string().email({ message: "Please enter a valid email address" }),
//     first_name: z.string().min(1).max(64),
//     middle_name: z.string().min(1).max(64),
//     last_name: z.string().min(1).max(64),
//     birthday: z.string().refine((value) => {
//         // Parse the input value as a Date object
//         const date = new Date(value);
//         // Check if the date is valid
//         if (isNaN(date.getTime())) {
//             throw new Error("Invalid date");
//         }
//         // Format the date to "yyyy-MM-dd"
//         const formattedDate = date.toISOString().slice(0, 10);
//         // Check if the formatted date matches the original input
//         if (formattedDate !== value) {
//             throw new Error("Invalid date format. Please use yyyy-MM-dd");
//         }
//         return true;
//     }),
//     gender_id: z.number(),
//     mobile: z.string(),
//     title_id: z.number(),
// });

function AccountEdit() {
    let login = useAuth();
    const location = useLocation();
    const [title, setTitle] = useState<TitleModel[]>()
    const [genderData, setGenderData] = useState<GenderModel[]>()
    const [open, setOpen] = useState(false);
    const [isActive, setIsActive] = useState<boolean>(false);
    const [accountDetails, setAccountDetails] = useState<AccountModel>(location.state?.accountDetails);
    const [emailOpen, setEmailOpen] = useState(false);
    const [numberOpen, setNumber] = useState(false);
    const [titleOpen, setTitleOpen] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    // let isActiveString = ""

    const handleClose = () => {
        setOpen(false);
    };

    const handleSwitchCancelClick = () => {
        setIsActive((prevIsActive) => !prevIsActive);
        setIsDialogOpen(false)
    }


    const handleSwitchClick = async () => {
        // isActiveString = isActive.toString();
        await setIsActive((prevIsActive) => !prevIsActive);
        setIsDialogOpen(true)
    }

    const handleSwitchActiveClick = () => {
        setIsDialogOpen(false)

        isActiveSave()
    }


    function enableDisable(): String {
        let opt = "Disabled"
        if (!isActive) {
            opt = "Enable"
        } else {
            opt = "Disabled"
        }
        return opt
    }


    const handleMobileChange = async (newAccountDetails: AccountModel) => {
        await setAccountDetails(newAccountDetails)
        setNumber(false)
    }


    const handleEmailClose = async (newAccountDetails: AccountModel) => {

        await setAccountDetails(newAccountDetails)

        setEmailOpen(false)
    }
    // const handleOpen = () => {
    //     setOpen(true);
    // };

    const handleTitleClose = () => {

        setTitleOpen(false)
    }

    const handleTitleChange = () => {
        setTitleOpen(true)
    }
    const handlePasswordChange = () => {
        setOpen(true);
    }

    // const handleIsActive = () => {
    //     setIsActive(!isActive);
    //     // isActiveSave()
    //     toast({
    //         title: "TOggle",
    //         description: "asd." + isActive,
    //     });
    // }

    // const handleIsActive = () => {
    //     setIsActive((prevIsActive) => !prevIsActive);
    //     isActiveSave()
    // };

    const handleEmalChange = () => {
        setEmailOpen(true)
    }

    const handleNumberChange = () => {
        setNumber(true)
    }


    function getGender(id: number): string {
        const itGender = genderData?.find((i) => i.id === id);
        return itGender?.name ?? ""
    }

    function getTitle(id: number): string {
        const itTitle = title?.find((i) => i.id === id);
        return itTitle?.title ?? ""
    }


    const isActiveSave = async () => {
        const apiParams: APICallParams = {
            verb: "PATCH",
            url: "/v1/admin/" + accountDetails.id + "/enable",
            params: { is_enabled: isActive },
            body: {},
        };

        const response = await makeAuthenticatedAPICall(apiParams);
        if (!response) {
            login?.logout();
        } else if (response.status !== 200) {
            toast({
                title: "Actived",
                description: response.data.message
            });
        } else {
            setAccountDetails(response.data)
            toast({
                title: "Status",
                description: "Successfully update status.",
            });
        }

    }




    const loadGender = async () => {

        try {
            const apiParams: APICallParams = {
                verb: "GET",
                url: "/v1/genders/",
                params: { page: 0, limit: 100 },
                body: {},
            };

            const response = await makeAuthenticatedAPICall(apiParams);

            if (response.status === 200) {
                setGenderData(response.data)
                localStorage.setItem("genderLS", JSON.stringify(response.data));
            }
        } catch (error) {

            console.error("Error during Mobile Number:", error);
        }
    }

    const loadTitle = async () => {

        try {
            const apiParams: APICallParams = {
                verb: "GET",
                url: "/v1/admin-title/",
                params: { is_active: true, page: 0, limit: 100 },
                body: {},
            };
            const response = await makeAuthenticatedAPICall(apiParams);

            if (response.status === 200) {
                setTitle(response.data)
            }
        } catch (error) {

            console.error("Error during Mobile Number:", error);
        }
    }

    // const loadBranch = async () => {
    //     try {
    //         const response = await fetchBranches()
    //         setBranchs(response)
    //     } catch (error) {
    //         console.error("Error during Mobile Number:", error);
    //     }
    // }

    useEffect(() => {
        if (!localStorage.getItem("genderLS")) {
            loadGender()
        } else {
            const genderDataFromStorage = localStorage.getItem("genderLS");
            const parsedGenderData = genderDataFromStorage ? JSON.parse(genderDataFromStorage) : undefined;
            // Update the state with the parsed data
            setGenderData(parsedGenderData);
        }
        if (!localStorage.getItem("titleLS")) {
            loadTitle()
        } else {
            const titleFromStorage = localStorage.getItem("titleLS");
            const parsedTitle = titleFromStorage ? JSON.parse(titleFromStorage) : undefined;
            setTitle(parsedTitle)
        }
        // loadBranch()
        setIsActive(accountDetails.is_enabled)
    }, []);

    // if (loading) {
    //     return <div>Loading...</div>;
    // }

    // if (error) {
    //     return <div>Error: {error}</div>;
    // }
    return (
        // <div className="bg-white p-4 rounded-sm border border-gray-200 flex flex-col flex-1 h-screen gap-2">
        //     <strong className="text-gray-700 font-medium">Account Details</strong>
        <div className="flex flex-1 flex-row  gap-2">
            <div className="flex flex-1 flex-col px-10 py-4 gap-2 shadow-2xl">
                <div className="flex flex-col pb-5">
                    <p>Account Status</p>
                    <div className="flex flex-row gap-2">
                        <p>Enable</p>
                        <Switch
                            checked={isActive}
                            onCheckedChange={() => handleSwitchClick()}
                        />
                        <AlertDialog open={isDialogOpen}>
                            <AlertDialogContent>
                                <AlertDialogHeader>
                                    <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                                    <AlertDialogDescription>
                                        are you sure to {enableDisable()} this account {accountDetails.username}?
                                    </AlertDialogDescription>
                                </AlertDialogHeader>
                                <AlertDialogFooter>
                                    <AlertDialogCancel onClick={() => handleSwitchCancelClick()}>Cancel</AlertDialogCancel>
                                    <AlertDialogAction onClick={() => handleSwitchActiveClick()}>Continue</AlertDialogAction>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialog>
                    </div>
                </div>
                <CustomLabel type="text"
                    placeholder="Username"
                    readOnly={true}
                    label="Username"
                    htmlFor="username" // Pass htmlFor as a prop
                    value={accountDetails.username}
                />

                <div className="flex flex-row items-center">
                    <CustomLabel type="password"
                        placeholder="Password"
                        readOnly={true}
                        label="Password"
                        htmlFor="password" // Pass htmlFor as a prop
                        value={accountDetails.password}
                    />
                    <div className="pt-5">
                        <Button variant="outline" onClick={() => handlePasswordChange()} >
                            <FaPen />
                        </Button>

                    </div>
                </div>

                <div className="flex flex-row items-center">
                    <CustomLabel type="email"
                        placeholder="Email Address"
                        readOnly={true}
                        label="Email Address"
                        htmlFor="email" // Pass htmlFor as a prop
                        value={accountDetails.email}
                    />
                    <div className="pt-6">
                        <Button variant="outline" onClick={() => handleEmalChange()} >
                            <FaPen />
                        </Button>

                    </div>
                </div>
                <div className="flex flex-row items-center">
                    <CustomLabel type="text"
                        placeholder="Mobile Number (+63 9XX XXXX XXX)"
                        readOnly={true}
                        label="Mobile Number (+63 9XX XXXX XXX)"
                        htmlFor="mobile" // Pass htmlFor as a prop
                        value={accountDetails.mobile}
                    />
                    <div className="pt-6">
                        <Button variant="outline" onClick={() => handleNumberChange()} >
                            <FaPen />
                        </Button>

                    </div>
                </div>
                {/* <div className="flex flex-row items-center">

                    <CustomLabel type="text"
                        placeholder="is Enabled"
                        readOnly={true}
                        label="is Enabled"
                        htmlFor="status" // Pass htmlFor as a prop
                        value={accountDetails.is_enabled.toString()}
                    />

                    <div className="pt-6">
                        
                        <Switch
                            checked={!isActive}
                            onCheckedChange={() => handleIsActive()}
                        />

                    </div>

                </div> */}

            </div>
            <div className="flex flex-1 flex-col px-10 py-4 gap-2 shadow-2xl">
                <CustomLabel type="text"
                    placeholder="First Name"
                    readOnly={true}
                    label="First Name"
                    htmlFor="first_name" // Pass htmlFor as a prop
                    value={accountDetails.first_name}
                />
                <CustomLabel type="text"
                    placeholder="Middle Name"
                    readOnly={true}
                    label="Middle Name"
                    htmlFor="middle_name" // Pass htmlFor as a prop
                    value={accountDetails.middle_name}
                />
                <CustomLabel type="text"
                    placeholder="Last Name"
                    readOnly={true}
                    label="Last Name"
                    htmlFor="last_name" // Pass htmlFor as a prop
                    value={accountDetails.last_name}
                />
                <CustomLabel type="text"
                    placeholder="Birthday"
                    readOnly={true}
                    label="Birthday"
                    htmlFor="birthday" // Pass htmlFor as a prop
                    value={accountDetails.birthday}
                />
                <CustomLabel type="text"
                    placeholder="Gender"
                    readOnly={true}
                    label="Gender"
                    htmlFor="gender" // Pass htmlFor as a prop
                    value={getGender(accountDetails.gender_id)}
                />


                <div className="flex flex-row items-center">
                    <CustomLabel type="text"
                        placeholder="Title"
                        readOnly={true}
                        label="Title"
                        htmlFor="title" // Pass htmlFor as a prop
                        value={getTitle(accountDetails.title_id)}
                    />
                    <div className="pt-6">
                        <Button variant="outline" onClick={() => handleTitleChange()} >
                            <FaPen />
                        </Button>

                    </div>
                </div>
            </div>
            <ChangePasswordDialog open={open} onClose={handleClose} />
            <ChangeEmailDialog open={emailOpen} onClose={handleEmailClose} />
            <ChangeNumberDialog open={numberOpen} onClose={handleMobileChange} />
            <ChangeTitleDialog open={titleOpen} onClose={handleTitleClose} />

        </div>




        // </div>
    )
}

export default AccountEdit
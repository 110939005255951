import request from "../Api";
import { API_KEY } from "../constant";
import { BranchData } from "@/data/BranchData";

export const getBranch = async () => {
  try {
    const data = await request<BranchData[]>({
      url: "/wet_market/branches_load",
      method: "GET",
      headers: {
        X_API_Key: API_KEY,
      },
    });
    return data;
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`);
  }
};
export const saveBranch = async (param: any) => {
  try {
    const data = await request<any>({
      url: "/wet_market/branches_save",
      method: "POST",
      data: param,
      headers: {
        X_API_Key: API_KEY,
      },
    });
    return data;
  } catch (error) {
    return error;
  }
};

// export const updateProduct = async (param: any) => {
//     try {
//       const response = await request<any[] | undefined>({
//         url: "/wet_market/product_update",
//         method: "POST",
//         data: param,
//         headers: {
//           X_API_Key: API_KEY,
//         },
//       });
//       return response;
//     } catch (error) {
//       throw new Error(`Error fetching data: ${error}`);
//     }
//   };

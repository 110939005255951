import { FaMagnifyingGlass, FaPlus } from "react-icons/fa6"
import { Button } from "../ui/button"

import { useEffect, useState } from "react"
import { Input } from "../ui/input"
import { ProductBetaRateModel } from "@/interfaces/ProductRateModel"
import { fetchBetaProductRate } from "@/services/ProductServices"
import { Table, TableBody, TableCaption, TableCell, TableFooter, TableHead, TableHeader, TableRow } from "../ui/table"
import { useLocation } from "react-router";
import { getCategory } from "@/api/services/productServices"
import { CategoryBaseData } from "@/data/ProductData"
import { useNavigate } from "react-router";
import { fetchBranches } from "@/services/AccountServices"
import { AccountBranch } from "@/interfaces/AccountModel"


function BProductRates() {
    const location = useLocation();
    const sBrandID: number = location.state.sBrandID;
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [productBRates, setProductBRates] = useState<ProductBetaRateModel[]>()
    const rowPerPage: number = 20;
    const [startIndex, setStartIndex] = useState<number>(0);
    let maxLength = 0;
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [category, setCategory] = useState<CategoryBaseData[]>()
    const navigate = useNavigate();
    const [branches, setBranches] = useState<AccountBranch[]>();


    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const fetchProductCategory = async () => {
        try {
            const category = await getCategory();
            setCategory(category);
        } catch (error) {
            setError((error as Error).message);
        } finally {
            setLoading(false);
        }
    };

    function getCategoryName(categoryID: number) {
        const data = category?.find((i) => i.idx === categoryID)
        return data?.category_name
    }

    const handleSearchClick = () => {
        const inputElement = document.getElementById("searchID") as HTMLInputElement
        const inputValue = inputElement.value;
        setSearchTerm(inputValue)
        setProductBRates([])

        loadProductRates(sBrandID, inputValue)
    }

    const nextPage = async () => {
        setStartIndex(startIndex + 1);
        setProductBRates([])
        loadProductRates(sBrandID, searchTerm)
    };

    const prevPage = async () => {
        if (startIndex > 0) {
            setStartIndex(startIndex - 1);
            setProductBRates([])
            loadProductRates(sBrandID, searchTerm)
        }
    };

    const loadProductRates = async (branchID: number, search: string) => {
        try {
            const response = await fetchBetaProductRate({
                branch_id: branchID,
                s: search,
                page: startIndex,
                items_per_page: rowPerPage
            });

            setProductBRates(response)

            maxLength = response ? response.length : 0;
        } catch (error) {
            setError((error as Error).message);
        } finally {
            setLoading(false);
        }
    }

    // const handleEditClick = (productID: number) => {
    //     const fill = productBRates?.find((i) => i.product_id === productID)

    //     navigate("/branch/" + sBrandID + "/products/" + productID, { state: { productPriceData: fill } })
    // }

    const loadBranch = async () => {
        try {
            const response = await fetchBranches()
            setBranches(response)
            const branchesString = JSON.stringify(response);
            localStorage.setItem('branchesLS', branchesString);
        } catch (error) {
            setError((error as Error).message);
        } finally {
            setLoading(false);
        }
    }

    function getBranchName(bid: string) {
        const data = branches?.find((i) => i.idx === parseInt(bid))
        return data?.branch_name
    }

    // const handleViewClick = (productID: number) => {
    //     const fill = productBRates?.find((i) => i.product_id === productID)

    //     navigate("/branch/" + selectedBrandID + "/products/" + productID + "/variants", { state: { productPriceData: fill } })
    // }

    const handleRateClick = () => {

        navigate("/branch/" + sBrandID + "/baseproduct", { state: { sBrandID: sBrandID } })

    }

    const handleViewClick = (pid: number) => {
        const fill = productBRates?.find((i) => i.product_id === pid)
        navigate("/branch/" + sBrandID + "/products/" + pid, { state: { productRateData: fill } })
    }

    useEffect(() => {
        loadProductRates(sBrandID, searchTerm)
        fetchProductCategory()
        if (!localStorage.getItem("branchesLS")) {
            loadBranch()
        } else {
            const branchesFromStorage = localStorage.getItem("branchesLS")
            const parsedbranches = branchesFromStorage ? JSON.parse(branchesFromStorage) : undefined;
            setBranches(parsedbranches)
            setLoading(false);
        }
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }
    return (
        <div className="bg-white p-6 rounded-sm border border-gray-200 flex flex-col flex-1 h-screen gap-2">
            <strong> {getBranchName(sBrandID.toString())} </strong>
            <strong className="text-gray-700 font-medium">Product Pricing</strong>
            <div className="flex flex-row justify-between">
                <div className="flex flex-row gap-2 w-80">
                    <Input type="text" placeholder="Search products..." id="searchID" autoComplete='off' value={searchTerm} onChange={handleSearchChange} />
                    <Button onClick={() => handleSearchClick()}>
                        <FaMagnifyingGlass className="mr-2 h-4 w-4" /> Search
                    </Button>
                </div>
                <Button onClick={() => handleRateClick()}>
                    <FaPlus className="mr-2 h-4 w-4" /> Price/Rate
                </Button>
            </div>
            <Table>
                <TableCaption>A list of your Product.</TableCaption>
                <TableHeader>
                    <TableRow>
                        <TableHead className="w-[100px]">ID</TableHead>
                        <TableHead>Image</TableHead>
                        <TableHead>Product</TableHead>
                        <TableHead>Category</TableHead>
                        <TableHead className="w-[100px]">Actions</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {productBRates?.map((i, index) => (
                        <TableRow key={index}>
                            <TableCell className="font-medium">{i.product_id}</TableCell>
                            <TableCell>
                                <img
                                    src={i.img_url}
                                    className="mx-auto w-10 items-center"
                                    alt={i.img_url}
                                />
                            </TableCell>
                            <TableCell>{i.product_name}</TableCell>
                            <TableCell>{getCategoryName(i.category_id)}</TableCell>
                            <TableCell>
                                {/* <div className="flex flex-row gap-3">
                                    <Button variant="destructive" onClick={() => handleEditClick(i.product_id)}>Edit</Button>
                                    <Button variant="outline" onClick={() => handleViewClick(i.product_id)} >Variant</Button>
                                </div> */}
                                <Button onClick={() => handleViewClick(i.product_id)}>View</Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={6}>
                            <div className="flex flex-row justify-center gap-x-10">
                                <Button
                                    className={
                                        startIndex === 0
                                            ? "pointer-events-none opacity-50"
                                            : undefined
                                    }
                                    onClick={() => {
                                        prevPage();
                                    }}
                                >
                                    Prev
                                </Button>
                                <Button
                                    className={
                                        maxLength < rowPerPage
                                            ? "pointer-events-none opacity-50"
                                            : undefined
                                    }
                                    onClick={() => {
                                        nextPage();
                                    }}
                                >
                                    Next
                                </Button>
                            </div>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </div>
    )
}

export default BProductRates
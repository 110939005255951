import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../ui/form";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { APICallParams, makeAuthenticatedAPICall } from "@/utils/apiCall";
import { useEffect, useState } from "react";
import { decryptPassword } from "@/utils/decrypt";
import { nanoid } from 'nanoid';
import { toast } from "../ui/use-toast";
import { GenderModel } from "@/interfaces/GenderModel";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "../ui/select";
import { TitleModel } from "@/interfaces/TitleModel";
import { useAuth } from "@/hooks/useAuth";

const formSchema = z.object({
    username: z.string().min(8),
    password: z.string().min(6),
    email: z.string().email({ message: "Please enter a valid email address" }),
    first_name: z.string().min(1).max(64),
    middle_name: z.string().min(1).max(64),
    last_name: z.string().min(1).max(64),
    birthday: z.string().refine((value) => {
        // Parse the input value as a Date object
        const date = new Date(value);
        // Check if the date is valid
        if (isNaN(date.getTime())) {
            throw new Error("Invalid date");
        }
        // Format the date to "yyyy-MM-dd"
        const formattedDate = date.toISOString().slice(0, 10);
        // Check if the formatted date matches the original input
        if (formattedDate !== value) {
            throw new Error("Invalid date format. Please use yyyy-MM-dd");
        }
        return true;
    }),
    gender_id: z.string().min(1),
    mobile: z.string(),
    title_id: z.string()
});

function Account() {

    let login = useAuth();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [genderData, setGenderData] = useState<GenderModel[]>()
    const [title, setTitle] = useState<TitleModel[]>()


    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            username: ""
        },
    });

    const handleSave = async (data: z.infer<typeof formSchema>) => {
        try {
            // if (date) {
            const dpw = decryptPassword(data.password)
            const apiParams: APICallParams = {
                verb: "POST",
                url: "/v1/admin/",
                params: {},
                body: {
                    uuid: nanoid(36),
                    username: data.username,
                    password: dpw.toString(),
                    email: data.email,
                    first_name: data.first_name,
                    middle_name: data.middle_name,
                    last_name: data.last_name,
                    birthday: data.birthday,
                    gender_id: data.gender_id,
                    mobile: data.mobile,
                    title_id: data.title_id
                },
            };

            const response = await makeAuthenticatedAPICall(apiParams);

            // if (!response) {
            //     // login?.logout();
            //     toast({
            //         title: "Token",
            //         description: "Token Expired",
            //     });
            // } else if (response.status = 201) {
            //     toast({
            //         title: "Account",
            //         description: "Successfully save.",
            //     });
            // } else {
            //     toast({
            //         title: "Job Title",
            //         description: "Successfully save.",
            //     });
            // }

            if (!response) {
                login?.logout();
            } else if (response.status === 422) {
                toast({
                    title: "Token",
                    description: response.statusText,
                });
            } else if (response.status !== 201) {
                toast({
                    title: "Token",
                    description: response.data.message,
                });
            } else {
                toast({
                    title: "Account",
                    description: "Successfully save.",
                });
            }



        } catch (error) {
            setError((error as Error).message);
        } finally {
            setLoading(false);
        }
    }

    const loadGender = async () => {

        try {
            const apiParams: APICallParams = {
                verb: "GET",
                url: "/v1/genders/",
                params: { page: 0, limit: 100 },
                body: {},
            };

            const response = await makeAuthenticatedAPICall(apiParams);

            if (response.status === 200) {
                setGenderData(response.data)
                localStorage.setItem("genderLS", JSON.stringify(response.data));
            }
        } catch (error) {
            setError((error as Error).message);
        } finally {
            setLoading(false);
        }
    }

    const loadTitle = async () => {

        try {
            const apiParams: APICallParams = {
                verb: "GET",
                url: "/v1/admin-title/",
                params: { is_active: true, page: 0, limit: 100 },
                body: {},
            };
            const response = await makeAuthenticatedAPICall(apiParams);

            if (response.status === 200) {
                setTitle(response.data)
            }
        } catch (error) {
            setError((error as Error).message);
        } finally {
            setLoading(false);
        }
    }


    useEffect(() => {
        if (!localStorage.getItem("genderLS")) {
            loadGender()
        } else {
            const genderDataFromStorage = localStorage.getItem("genderLS");
            const parsedGenderData = genderDataFromStorage ? JSON.parse(genderDataFromStorage) : undefined;
            // Update the state with the parsed data
            setGenderData(parsedGenderData);
            setLoading(false);
        }
        if (!localStorage.getItem("titleLS")) {
            loadTitle()
        } else {
            const titleFromStorage = localStorage.getItem("titleLS");
            const parsedTitle = titleFromStorage ? JSON.parse(titleFromStorage) : undefined;
            setTitle(parsedTitle)
        }
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }
    return (
        <div className="bg-white p-4 rounded-sm border border-gray-200 flex flex-col flex-1 h-screen">
            <strong className="text-gray-700 font-medium">Account Registration</strong>
            <Form {...form}>
                <form
                    onSubmit={form.handleSubmit(handleSave)}
                >
                    <div className="flex flex-1 flex-row py-4">
                        <div className="flex flex-1 flex-col p-4 items-center shadow-2xl gap-2">

                            <FormField
                                control={form.control}
                                name="username"
                                render={({ field }) => {
                                    return (
                                        <FormItem className="w-full px-10 py-2">
                                            <FormLabel>Username</FormLabel>
                                            <FormControl>
                                                <Input
                                                    placeholder="Username"
                                                    type="text"
                                                    autoComplete="off"
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    );
                                }}
                            />
                            <FormField
                                control={form.control}
                                name="password"
                                render={({ field }) => {
                                    return (
                                        <FormItem className="w-full px-10 py-2">
                                            <FormLabel>Password</FormLabel>
                                            <FormControl>
                                                <Input
                                                    placeholder="password"
                                                    type="password"
                                                    autoComplete="off"
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    );
                                }}
                            />
                            <FormField
                                control={form.control}
                                name="email"
                                render={({ field }) => {
                                    return (
                                        <FormItem className="w-full px-10 py-2">
                                            <FormLabel>Email Address</FormLabel>
                                            <FormControl>
                                                <Input
                                                    placeholder="email Address"
                                                    type="text"
                                                    autoComplete="off"
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    );
                                }}
                            />
                            <FormField
                                control={form.control}
                                name="mobile"
                                render={({ field }) => {
                                    return (
                                        <FormItem className="w-full px-10 py-2">
                                            <FormLabel>Mobile Number (+63 9XX XXXX XXX)</FormLabel>
                                            <FormControl>
                                                <Input
                                                    placeholder="Mobile Number"
                                                    type="text"
                                                    autoComplete="off"
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    );
                                }}
                            />
                        </div>
                        <div className="flex flex-1 flex-col p-4 items-center shadow-2xl">
                            <FormField
                                control={form.control}
                                name="first_name"
                                render={({ field }) => {
                                    return (
                                        <FormItem className="w-full px-10 py-2">
                                            <FormLabel>First Name</FormLabel>
                                            <FormControl>
                                                <Input
                                                    placeholder="First Name"
                                                    type="text"
                                                    autoComplete="off"
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    );
                                }}
                            />
                            <FormField
                                control={form.control}
                                name="middle_name"
                                render={({ field }) => {
                                    return (
                                        <FormItem className="w-full px-10 py-2">
                                            <FormLabel>Middle Name</FormLabel>
                                            <FormControl>
                                                <Input
                                                    placeholder="Middle Name"
                                                    type="text"
                                                    autoComplete="off"
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    );
                                }}
                            />
                            <FormField
                                control={form.control}
                                name="last_name"
                                render={({ field }) => {
                                    return (
                                        <FormItem className="w-full px-10 py-2">
                                            <FormLabel>Last Name</FormLabel>
                                            <FormControl>
                                                <Input
                                                    placeholder="Last Name"
                                                    type="text"
                                                    autoComplete="off"
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    );
                                }}
                            />
                            <FormField
                                control={form.control}
                                name="birthday"
                                render={({ field }) => {

                                    return (
                                        <FormItem className="w-full px-10 py-2">
                                            <FormLabel>Birthday (yyyy-MM-dd)</FormLabel>
                                            <FormControl>
                                                <Input
                                                    placeholder="yyyy-MM-dd"
                                                    type="text"
                                                    autoComplete="off"
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    );
                                }}
                            />
                            <FormField
                                control={form.control}
                                name="gender_id"
                                render={({ field }) => {
                                    return (
                                        <FormItem className="w-full px-10 py-2">
                                            <FormLabel>Gender</FormLabel>
                                            <Select onValueChange={field.onChange}>
                                                <FormControl>
                                                    <SelectTrigger>
                                                        <SelectValue placeholder="Select Gender" />
                                                    </SelectTrigger>
                                                </FormControl>

                                                <SelectContent>
                                                    <SelectGroup>
                                                        <SelectLabel>Select Gender</SelectLabel>
                                                        {genderData?.map((i) => (
                                                            <SelectItem key={i.id} value={i.id.toString()}>
                                                                {i.name}
                                                            </SelectItem>
                                                        ))}
                                                    </SelectGroup>
                                                </SelectContent>
                                            </Select>
                                            <FormMessage />
                                        </FormItem>
                                    );
                                }}
                            />
                            <FormField
                                control={form.control}
                                name="title_id"
                                render={({ field }) => {
                                    return (
                                        <FormItem className="w-full px-10 py-2">
                                            <FormLabel>Job Title</FormLabel>
                                            <Select onValueChange={field.onChange}>
                                                <FormControl>
                                                    <SelectTrigger>
                                                        <SelectValue placeholder="Select Job Title" />
                                                    </SelectTrigger>
                                                </FormControl>

                                                <SelectContent>
                                                    <SelectGroup>
                                                        <SelectLabel>Select Job Title</SelectLabel>
                                                        {title?.map((i) => (
                                                            <SelectItem key={i.id} value={i.id.toString()}>
                                                                {i.title}
                                                            </SelectItem>
                                                        ))}
                                                    </SelectGroup>
                                                </SelectContent>
                                            </Select>
                                            <FormMessage />
                                        </FormItem>
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex flex-1 flex-row justify-end">
                        <Button className="flex flex-row">
                            Save
                        </Button>

                    </div>

                </form>
            </Form>
        </div>
    )
}

export default Account
import { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "Jan",
    Expense: 4000,
    Income: 2400,
  },
  {
    name: "Feb",
    Expense: 3000,
    Income: 1398,
  },
  {
    name: "Mar",
    Expense: 2000,
    Income: 9800,
  },
  {
    name: "May",
    Expense: 2780,
    Income: 3908,
  },
  {
    name: "Jun",
    Expense: 1890,
    Income: 4800,
  },
  {
    name: "Jul",
    Expense: 2390,
    Income: 3800,
  },
  {
    name: "Aug",
    Expense: 3490,
    Income: 4300,
  },
];

export default class TransactionChart extends PureComponent {
  render() {
    return (
      <div className="h-[18rem] bg-white p-4 rounded-sm border border-gray-200 flex  flex-col flex-1">
        <strong className="text-gray-700 font-medium">Transaction</strong>
        <div className="w-full mt-3 flex-1 text-xs">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="Income"
                fill="#8884d8"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
              />
              <Bar
                dataKey="Expense"
                fill="#82ca9d"
                activeBar={<Rectangle fill="gold" stroke="purple" />}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
}

import { Toaster } from "./components/ui/toaster";
import Router from "./routes/Router";

function App() {
  return <>
    <Router />
    <Toaster />
  </>;
}

export default App;


import { AccountData } from "@/data/LoginData";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router";

const ProtectedRouteUser: React.FC = () => {
    const userDetails = localStorage.getItem("userDetails");
    let udetails: AccountData | null = null;
    const navigate = useNavigate();
    function presentPage() {
        navigate(-1);
    }

    if (userDetails) {
        udetails = JSON.parse(userDetails) as AccountData;
    }

    if (!udetails) {
        navigate("/");
        return;
    }

    useEffect(() => {

        if (udetails) {
            if (udetails.title_id > 2 && udetails.title_id <= 2) {
                presentPage()
            }
        }
    }, [udetails, navigate])

    // if (udetails && udetails.title_id > 2) {
    //     return <Outlet />;
    // } else if (udetails && udetails.title_id > 2) {
    //     presentPage()
    // }

    if (udetails) {
        if (udetails.title_id > 2 && udetails.title_id <= 4) {
            return <Outlet />;
        } else {
            presentPage();
            return null; // or any other component you want to render
        }
    }

    return null;
}

export default ProtectedRouteUser;
import { CategoryBaseData, ProductBase } from "@/data/ProductData";
// import { useLocation, useLocation } from "react-router-dom";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { Input } from "@/components/ui/input";

import { getCategory, updateProduct } from "@/api/services/productServices";
import { useEffect, useState } from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { useLocation } from "react-router";
import { generateAWSUrl } from "@/api/services/awsServices";
import requestAWS from "@/api/services/awsApi";
import Compressor from "compressorjs";
import { toast } from "@/components/ui/use-toast";
import { Toaster } from "@/components/ui/toaster";

const formSchema = z.object({
  product_form: z.string().min(4),
  category_form: z.string().optional(),
  category_type: z.string(),
  description_form: z.string().optional(),
  file_form: z.instanceof(FileList).optional(),
});

function ProductEdit() {
  const location = useLocation();
  const prod: ProductBase = location.state?.prod;
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const [selectedOption, setSelectedOption] = useState<
    CategoryBaseData[] | undefined
  >();

  const fetchProductCategory = async () => {
    try {
      const category = await getCategory();
      setSelectedOption(category);
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      product_form:
        prod?.product_name === null || prod?.product_name === undefined
          ? ""
          : prod.product_name,
      category_form: "",
      category_type:
        prod?.category_id === null || prod?.category_id === undefined
          ? "1"
          : prod.category_id.toString(),
    },
  });

  const fileRef = form.register("file_form");

  useEffect(() => {
    fetchProductCategory();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleSubmit = async (data: z.infer<typeof formSchema>) => {
    // console.log(data);
    // console.log(prod);

    if (!imageUrl) {
      const uResult = await updateProduct({
        categoryid: data.category_type,
        productname: data.product_form,
        url: prod?.img_url,
        productid: prod?.id,
      });

      const res = uResult?.[0];
      if (res.Success === "Success") {
        toast({
          title: "Product",
          description: "Success to update.",
        });
      } else {
        toast({
          title: "Product",
          description: "Failed to update.",
        });
      }
    } else {
      //generate aws image URL
      const result = await generateAWSUrl({
        body: {
          fileType: data.file_form?.[0].type?.replace("image/", ".") || "",
        },
      });

      if (result.statusCode === 201) {
        //get Image URL
        const contentType = data.file_form?.[0].type;
        const file = data.file_form?.[0];
        if (file) {
          new Compressor(file, {
            quality: 0.8,
            success: async (compressedResult) => {
              const bods = JSON.parse(result.body);
              try {
                const res = await requestAWS<any>({
                  url: bods.uploadUrl,
                  method: "PUT",
                  data: compressedResult,
                  headers: { "Content-Type": contentType },
                });
                return res;
              } catch (error) {
                throw new Error(`Error fetching data: ${error}`);
              }
            },
          });
        }

        const dlUrl = JSON.parse(result.body);
        if (data.category_type !== undefined) {
          const prodID: number = prod.id; // If prod?.product_id is undefined, use 0 as the default value

          updateProductData(
            parseInt(data.category_type),
            data.product_form,
            dlUrl.downloadUrl,
            prodID
          );
        }
      }
    }
  };

  const updateProductData = async (
    categoryID: number,
    productName: string,
    imgUrl: string,
    productID: number
  ) => {
    const sResult = await updateProduct({
      categoryid: categoryID,
      productname: productName,
      url: imgUrl,
      productid: productID,
    });
    const res = sResult?.[0];
    if (res.Success === "Success") {
      toast({
        title: "Product",
        description: "Success to update.",
      });
    } else {
      toast({
        title: "Product",
        description: "Failed to update.",
      });
    }
  };

  return (
    <div className="h-full  w-full bg-white p-4 rounded-sm border border-gray-200 flex  flex-col">
      <strong className="text-gray-700 font-medium">Edit Product</strong>

      <div className="flex flex-row items-center">
        <div className="flex flex-1 items-center justify-center p-4">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(handleSubmit)}
              className="max-w-md  w-full flex flex-col gap-4"
            >
              <FormField
                control={form.control}
                name="product_form"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Product</FormLabel>
                      <FormControl>
                        <Input placeholder="product" type="text" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name="category_type"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Category</FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select Category" />
                          </SelectTrigger>
                        </FormControl>

                        <SelectContent>
                          <SelectGroup>
                            <SelectLabel>Select Category</SelectLabel>
                            {selectedOption?.map((category) => (
                              <SelectItem
                                key={category.idx}
                                value={category.idx.toString()}
                              >
                                {category.category_name}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name="description_form"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Desciption</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Description"
                          type="text"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name="file_form"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Product Image</FormLabel>
                      <FormControl>
                        <Input
                          type="file"
                          {...fileRef}
                          onChange={(event) => {
                            const file = event.target.files?.[0];
                            field.onChange(
                              event.target?.files?.[0] ?? undefined
                            );
                            if (file) {
                              const url = URL.createObjectURL(file);
                              setImageUrl(url);
                            } else {
                              setImageUrl(null);
                            }
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <Button type="submit" className="w-full">
                Update
              </Button>
            </form>
          </Form>
        </div>

        <div className="flex flex-1 items-center justify-start">
          {imageUrl && (
            <div>
              <p>Product Image</p>
              <img src={imageUrl} alt="Selected" className="w-[20rem]" />
            </div>
          )}
        </div>
      </div>

      <Toaster />
    </div>
  );
}

export default ProductEdit;

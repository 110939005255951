import Header from "@/components/Header";
import { Outlet } from "react-router";
import "@/index.css";
import { useAuth } from "@/hooks/useAuth";
import Sidebar from "@/components/sidebar/Sidebar";
import SideBarAdmin from "@/components/sidebar/SideBarAdmin";
import SidebarUploader from "@/components/sidebar/SidebarUploader";
import SidebarBranch from "@/components/sidebar/SidebarBranch";

function Layout() {
  const user = useAuth();
  const userDetail = user?.user
  return (
    <div className="flex flex-row bg-neutral-100 h-screen w-screen overflow-hidden">
      {userDetail.title_id === 1 ? (<SideBarAdmin />) :
        userDetail.title_id === 2 ? (<SidebarUploader />) :
          userDetail.title_id === 3 ? (<Sidebar />) :
            userDetail.title_id === 4 ? (<SidebarBranch />) :
              <div className="bg-[#212121] flex flex-col w-60 p-3 ">
                Invalid title ID
              </div>}
      <div className="flex-1">
        <Header />
        <div>{<Outlet />}</div>
      </div>
    </div>
  );
}

export default Layout;

//tempo
// 1 - super admin
// 2 - palengke admin
// 3 - Product uploader
// 3 - Product uploader
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../ui/form";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { saveCommission } from "@/api/services/adminServices";
import { toast } from "../ui/use-toast";
import { useLocation } from "react-router";
import { RiderRate, StoreRate } from "@/interfaces/CommisionModel";

const formSchema = z.object({
    rider_com: z.string(),
    store_com: z.string(),
    initial_km: z.string(),
    initial_km_rate: z.string(),
    succ_km_tick: z.string(),
    succ_km_rate: z.string()
})

function RateAndCommission() {
    const location = useLocation();
    const riderRateLocal: RiderRate = location.state?.riderRateD
    const storeRateLocal: StoreRate[] = location.state?.storeRated

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            initial_km: riderRateLocal.initial_km.toString() ?? "0",
            initial_km_rate: riderRateLocal.initial_km_rate.toString() ?? "0",
            succ_km_tick: riderRateLocal.succ_km_tick.toString() ?? "0",
            succ_km_rate: riderRateLocal.succ_km_rate.toString() ?? "0",

            rider_com: storeRateLocal[0].com_rate.toString(),
            store_com: storeRateLocal[1].com_rate.toString()
        },
    });

    const handleSaveClick = async (data: z.infer<typeof formSchema>) => {
        try {
            const response = await saveCommission({
                rider_com: data.rider_com,
                store_com: data.store_com,
                initial_km: data.initial_km,
                initial_km_rate: data.initial_km_rate,
                succ_km_tick: data.succ_km_tick,
                succ_km_rate: data.succ_km_rate
            });


            toast({
                title: "Commission rate",
                description: "Successfully " + response[0].msg,
            });

        } catch (error) {
            console.log((error as Error).message)
        }
    }




    return (
        <div className="bg-white p-4 rounded-sm border border-gray-200 flex gap-5 flex-col flex-1 h-screen">
            <strong className="text-gray-700 font-medium">Update Commission Rate.</strong>
            <Form {...form}>
                <form
                    onSubmit={form.handleSubmit(handleSaveClick)}
                >
                    <div className="flex flex-1 flex-row gap-5 p-5">
                        <div className="flex flex-1 flex-col gap-5">
                            <FormField
                                control={form.control}
                                name="rider_com"
                                render={({ field }) => {
                                    return (
                                        <FormItem>
                                            <FormLabel>Rider Commission</FormLabel>
                                            <FormControl>
                                                <Input
                                                    placeholder="Rider Commission"
                                                    type="number"
                                                    pattern="^\d*\.?\d+$"
                                                    autoComplete="off"
                                                    autoSave="off"
                                                    autoCorrect="off"
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    );
                                }}
                            />
                            <FormField
                                control={form.control}
                                name="store_com"
                                render={({ field }) => {
                                    return (
                                        <FormItem>
                                            <FormLabel>Store Commission</FormLabel>
                                            <FormControl>
                                                <Input
                                                    placeholder="Store Commission"
                                                    type="text"
                                                    pattern="^\d*\.?\d+$"
                                                    autoComplete="off"
                                                    autoSave="off"
                                                    autoCorrect="off"
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    );
                                }}
                            />
                        </div>
                        <div className="flex flex-1 flex-col gap-5">
                            <FormField
                                control={form.control}
                                name="initial_km"
                                render={({ field }) => {
                                    return (
                                        <FormItem>
                                            <FormLabel>Initial km</FormLabel>
                                            <FormControl>
                                                <Input
                                                    placeholder="Initial km"
                                                    type="text"
                                                    pattern="^\d*\.?\d+$"
                                                    autoComplete="off"
                                                    autoSave="off"
                                                    autoCorrect="off"
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    );
                                }}
                            />
                            <FormField
                                control={form.control}
                                name="initial_km_rate"
                                render={({ field }) => {
                                    return (
                                        <FormItem>
                                            <FormLabel>Initial km</FormLabel>
                                            <FormControl>
                                                <Input
                                                    placeholder="Initial km Rate"
                                                    type="text"
                                                    pattern="^\d*\.?\d+$"
                                                    autoComplete="off"
                                                    autoSave="off"
                                                    autoCorrect="off"
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    );
                                }}
                            />
                            <FormField
                                control={form.control}
                                name="succ_km_tick"
                                render={({ field }) => {
                                    return (
                                        <FormItem>
                                            <FormLabel>Success km</FormLabel>
                                            <FormControl>
                                                <Input
                                                    placeholder="Success km"
                                                    type="text"
                                                    pattern="^\d*\.?\d+$"
                                                    autoComplete="off"
                                                    autoSave="off"
                                                    autoCorrect="off"
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    );
                                }}
                            /><FormField
                                control={form.control}
                                name="succ_km_rate"
                                render={({ field }) => {
                                    return (
                                        <FormItem>
                                            <FormLabel>Success km rate</FormLabel>
                                            <FormControl>
                                                <Input
                                                    placeholder="Success km rate"
                                                    type="text"
                                                    pattern="^\d*\.?\d+$"
                                                    autoComplete="off"
                                                    autoSave="off"
                                                    autoCorrect="off"
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex flex-row  justify-end pr-5">
                        <Button className="flex flex-row">
                            Update
                        </Button>

                    </div>
                </form>
            </Form>
        </div>
    )
}

export default RateAndCommission
import Product from "@/components/product/product/Product";

function Products() {
  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-row gap-3 w-full ">
        {/* <Aggregate />
        <ProductList /> */}
        {/* <Category /> */}
        <Product />
        {/* <Variant /> */}
      </div>
      {/* <div className="flex flex-row gap-3 w-full">
        <Category />
        <Product />
        <Variant />
      </div> */}
    </div>
  );
}

export default Products;

import { FaPen } from "react-icons/fa6";

import { getCategory } from "@/api/services/productServices";
import { useEffect, useState } from "react";
import {
  ProductsData,
  CategoryBaseData,
} from "@/data/ProductData";
import { useLocation } from "react-router-dom";
import { Button } from "../../ui/button";
import { useNavigate } from "react-router";

const ProductView: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const product: ProductsData | undefined = location.state?.product;
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  // const [selectedOption, setSelectedOption] = useState<
  //   ProductCategoryData[] | undefined
  // >();
  const [prodCategory, setprodCategory] = useState<CategoryBaseData>();


  const fetchProductCategory = async () => {
    try {
      const category = await getCategory();
      const filter = category.find((cat) => cat.idx === product?.category_id);
      setprodCategory(filter);
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProductCategory();
  }, []);


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleClick = (productId: number) => {
    // const fill = products.find((product) => product.product_id === productId);
    // setfiltered(fill);

    const fill = product;

    // Navigate to another route and pass data using the state object
    // navigate("/products/Edit/" + productId, { state: { product: fill } });
    navigate("/admin/products/Edit/" + productId, { state: { prod: fill } });
  };

  return (
    <div className="bg-white p-4 rounded-sm border border-gray-200 flex flex-col flex-1 ">
      <div>
        <div className=" flex flex-row pt-2 gap-2">
          {product?.product_name}
          <Button
            variant="ghost"
            onClick={() => handleClick(product?.product_id ?? 0)}
          >
            <FaPen className="mr-2 h-4 w-4 " />
          </Button>
        </div>
        <img width="150" src={product?.img_url} alt={product?.img_url} />
        {prodCategory?.category_name}
        <p>Discription</p>
      </div>
    </div>
  );
};

export default ProductView;

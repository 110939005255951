import {
  HiOutlineViewGrid,
  HiOutlineCube,
  HiOutlineCog,
  HiQuestionMarkCircle,
} from "react-icons/hi";
import { FaCodeBranch, FaGear, FaListCheck, FaUsersGear } from "react-icons/fa6";

export const SIDEBAR_TOP_LINKS_ADMIN = [
  {
    key: "dashboard-admin",
    label: "Dashboard",
    path: "/admin/dashboard",
    icon: <HiOutlineViewGrid />,
  },
  {
    key: "title",
    label: "Title",
    path: "/admin/title",
    icon: <FaGear />,
  },
  {
    key: "permission",
    label: "Permission",
    path: "/admin/permissions",
    icon: <FaGear />,
  },
  {
    key: "branch",
    label: "Branch",
    path: "/admin/branch",
    icon: <FaCodeBranch />,
  },
  {
    key: "products",
    label: "Products",
    path: "/admin/products",
    icon: <HiOutlineCube />,
  },

  // {
  //   key: "store",
  //   label: "Store Screening",
  //   path: "/admin/Applicants",
  //   icon: <HiOutlineCube />,
  // },

  {
    key: "rider",
    label: "Rider",
    path: "/admin/riders",
    icon: <HiOutlineCube />,
  },
  {
    key: "ratecommision",
    label: "Rate and Commission",
    path: "/admin/commissions",
    icon: <HiOutlineCube />,
  },
  // {
  //   key: "vendor",
  //   label: "vendor Reports",
  //   path: "/branch/reports",
  //   icon: <HiOutlineCube />,
  // },
  {
    key: "accounts",
    label: "Account",
    path: "/admin/accounts",
    icon: <FaUsersGear />,
  },
];

export const SIDEBAR_TOP_LINKS = [
  {
    key: "dashboard",
    label: "Dashboard",
    path: "dashboard",
    icon: <HiOutlineViewGrid />,
  },
  {
    key: "pricing",
    label: "Product Rates",
    path: "choose",
    icon: <HiOutlineCube />,
  },
];

export const SIDEBAR_BUTTOM_LINKS = [
  {
    key: "settings",
    label: "Settings",
    path: "/settings",
    icon: <HiOutlineCog />,
  },
  {
    key: "support",
    label: "Help & Support",
    path: "/support",
    icon: <HiQuestionMarkCircle />,
  },
];

export const SIDEBAR_UPLOADER = [
  {
    key: "dashboard",
    label: "Dashboard",
    path: "dashboard",
    icon: <HiOutlineViewGrid />,
  },
  {
    key: "products",
    label: "Products",
    path: "/admin/products",
    icon: <HiOutlineCube />,
  },
]

export const SIDEBAR_BRANCH = [
  {
    key: "dashboard",
    label: "Dashboard",
    path: "dashboard",
    icon: <HiOutlineViewGrid />,
  },
  {
    key: "order_branches",
    label: "Orders",
    path: "order_branches",
    icon: <FaListCheck />,
  },
  {
    key: "order_received_branches",
    label: "Order Received",
    path: "order_received_branches",
    icon: <FaListCheck />,
  },
  {
    key: "processing",
    label: "Processing",
    path: "order_process_branches",
    icon: <FaListCheck />,
  },
  // {
  //   key: "ontransit",
  //   label: "On Transit",
  //   path: "ontransit",
  //   icon: <FaListCheck />,
  // },
]

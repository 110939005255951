import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
// import { Link } from "react-router-dom";
import { Input } from "../../ui/input";
import { Button, buttonVariants } from "../../ui/button";
import React, { useEffect, useState } from "react";

import { CategoryBaseData, ProductBase } from "@/data/ProductData";
import { useAuth } from "@/hooks/useAuth";
import { Link } from "react-router-dom";
import { getCategory } from "@/api/services/productServices";
import { APICallParams, makeAuthenticatedAPICall } from "@/utils/apiCall";
import { Toaster } from "@/components/ui/toaster";
import { useNavigate } from "react-router";
import { FaMagnifyingGlass } from "react-icons/fa6";

const ProductList: React.FC = () => {
  const navigate = useNavigate();
  let login = useAuth();

  // const [filtered, setfiltered] = useState<ProductBase>();
  // const ProductList: React.FC = () => {
  const [products, setProducts] = useState<ProductBase[]>();
  // const [productsprev, setproductsprev] = useState<ProductBase[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  // const [searchQuery, setSearchQuery] = useState("");
  const [selectedOption, setSelectedOption] = useState<CategoryBaseData[]>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const rowPerPage: number = 100;
  const [startIndex, setStartIndex] = useState<number>(0);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchClick = () => {
    const inputElement = document.getElementById("searchID") as HTMLInputElement
    const inputValue = inputElement.value;
    setSearchTerm(inputValue)
    setProducts([])
    getProductBaseWithSearch()
  }

  const getProductBaseWithSearch = async () => {
    try {
      const apiParams: APICallParams = {
        verb: "GET",
        url: "/v1/wet_market/products_search",
        params: { s: searchTerm, page: 0, limit: 100 },
        body: {},
      };
      const response = await makeAuthenticatedAPICall(apiParams);
      if (response.status === 200) {
        setProducts(response.data)
      }
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  }

  // const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
  //   const { value } = event.target;
  //   setSearchQuery(value);
  //   if (value === "") {
  //     setProducts(productsprev);
  //   } else {
  //     const searchData = productsprev?.filter((i) =>
  //       i.product_name.toLowerCase().includes(value.toLowerCase())
  //     );
  //     setProducts(searchData);
  //   }
  // };

  const fetchProductCategory = async () => {
    try {
      const category = await getCategory();
      setSelectedOption(category);
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  const loadProduct = async () => {
    try {
      const apiParams: APICallParams = {
        verb: "GET",
        url: "/v1/wet_market/products",
        params: { page: startIndex, limit: rowPerPage },
        body: {},
      };
      const result = await makeAuthenticatedAPICall(apiParams);

      if (!result) {
        login?.logout();
      } else {
        setProducts(result.data);
        // setproductsprev(result.data);
      }
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  const nextPage = async () => {
    setStartIndex(startIndex + 1);
    loadProduct();
  };

  const prevPage = () => {
    if (startIndex !== 0) {
      setStartIndex(startIndex - 1);
      loadProduct();
    }
  };

  useEffect(() => {
    fetchProductCategory();
    loadProduct();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  const handleClick = (productId: number) => {
    const fill = products?.find((product) => product.id === productId);
    // setfiltered(fill);

    navigate("/admin/products/" + productId, { state: { product: fill } });
  };

  function getCategoryName(CatID: Number): string {
    const asd = selectedOption?.find((i) => i.idx === CatID);
    return asd?.category_name ?? "";
  }

  return (
    <div className="bg-white p-4 rounded-sm border border-gray-200 flex flex-col flex-1 h-screen">
      <div className="p-4 flex justify-between items-center">
        <Link
          to={"/admin/products/New"}
          className={
            buttonVariants({ variant: "outline" }) +
            " order-2 no-underline hover:no-underline"
          }
        >
          New Product
        </Link>

        <div className="flex flex-row gap-2 w-80">
          <Input type="text" placeholder="Search products..." id="searchID" autoComplete='off' value={searchTerm} onChange={handleSearchChange} />
          <Button onClick={() => handleSearchClick()}>
            <FaMagnifyingGlass className="mr-2 h-4 w-4" /> Search
          </Button>
        </div>
      </div>
      <Table>
        <TableCaption>A list of your Product.</TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[100px]">ID</TableHead>
            <TableHead>Image</TableHead>
            <TableHead>Product</TableHead>
            <TableHead>Category</TableHead>
            <TableHead>Description</TableHead>
            <TableHead>Delete_at</TableHead>
            <TableHead className="w-[100px]">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {products?.map((product, index) => (
            <TableRow key={product.id.toString() + index}>
              <TableCell className="font-medium">{product.id}</TableCell>
              <TableCell className="">
                <img
                  src={product.img_url}
                  className="mx-auto w-10 items-center"
                  alt={product.img_url}
                />
              </TableCell>
              <TableCell>{product.product_name}</TableCell>
              <TableCell>{getCategoryName(product.category_id)}</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>{product.deleted_at}</TableCell>

              <TableCell className="flex gap-2 text-right">
                <Button
                  onClick={() => handleClick(product.id)}
                  variant="outline"
                >
                  Edit
                </Button>

                <Link
                  to={`#`}
                  className={
                    buttonVariants({ variant: "destructive" }) +
                    " order-2 no-underline hover:no-underline"
                  }
                >
                  Disable/Enable
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={7}>
              <div className="flex flex-row justify-center gap-x-10">
                <Button
                  className={
                    startIndex === 0
                      ? "pointer-events-none opacity-50"
                      : undefined
                  }
                  onClick={() => {
                    prevPage();
                  }}
                >
                  Prev
                </Button>
                <Button
                  onClick={() => {
                    nextPage();
                  }}
                >
                  Next
                </Button>
              </div>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
      <Toaster />
    </div>
  );
};

export default ProductList;

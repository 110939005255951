import { useEffect, useState } from "react";
import { Button } from "../ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../ui/dialog";
import { TitleModel } from "@/interfaces/TitleModel";
import { APICallParams, makeAuthenticatedAPICall } from "@/utils/apiCall";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "../ui/select";
import { AccountModel } from "@/interfaces/AccountModel";
import { useLocation } from "react-router";
import { toast } from "../ui/use-toast";
import { z } from "zod"
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../ui/form";


interface ChangeTitleDialogProps {
    open: boolean;
    onClose: () => void;
}

const FormSchema = z.object({
    titleID: z
        .string({
            required_error: "Please select an title.",
        }),
})

const ChangeTitleDialog: React.FC<ChangeTitleDialogProps> = ({ open, onClose }) => {
    const location = useLocation();
    const [accountDetails, setAccountDetails] = useState<AccountModel>(location.state?.accountDetails);
    const [title, setTitle] = useState<TitleModel[]>()

    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
    })



    const handleClose = () => {
        onClose();
    }

    const handleUpdate = (data: z.infer<typeof FormSchema>) => {
        // toast({
        //     title: "Title",
        //     description: data.titleID.toString(),
        // });
        UpdateData(parseInt(data.titleID))
    }

    const UpdateData = async (titleID: number) => {
        try {
            const uri = "/v1/admin/" + accountDetails.id + "/title"

            const apiParams: APICallParams = {
                verb: "PATCH",
                url: uri,
                params: {},
                body: { title_id: titleID },
            };
            const response = await makeAuthenticatedAPICall(apiParams);

            if (response.status !== 200) {
                toast({
                    title: "Title",
                    description: "Failed Update.",
                });
            } else {
                setAccountDetails(response.data)
                toast({
                    title: "Title",
                    description: "Successfully Update.",
                });
            }

        } catch (error) {
            console.error("Error during update password:", error);
        }


        onClose();
    }

    const loadTitle = async () => {

        try {
            const apiParams: APICallParams = {
                verb: "GET",
                url: "/v1/admin-title/",
                params: { is_active: true, page: 0, limit: 100 },
                body: {},
            };
            const response = await makeAuthenticatedAPICall(apiParams);

            if (response.status === 200) {
                setTitle(response.data)
            }
        } catch (error) {
            console.error("Error during update password:", error);
        }
    }
    useEffect(() => {
        if (!localStorage.getItem("titleLS")) {
            loadTitle()
        } else {
            const titleFromStorage = localStorage.getItem("titleLS");
            const parsedTitle = titleFromStorage ? JSON.parse(titleFromStorage) : undefined;
            setTitle(parsedTitle)
        }
    }, []);

    return (
        <Dialog open={open}>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Slect Title</DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form className="w-full gap-3"
                        onSubmit={form.handleSubmit(handleUpdate)}>
                        <FormField
                            control={form.control}
                            name="titleID"
                            render={({ field }) => {
                                return (
                                    <FormItem className="w-full px-10 py-2">
                                        <FormLabel>Job Title</FormLabel>
                                        <Select onValueChange={field.onChange}>
                                            <FormControl>
                                                <SelectTrigger>
                                                    <SelectValue placeholder="Select Title" />
                                                </SelectTrigger>
                                            </FormControl>

                                            <SelectContent>
                                                <SelectGroup>
                                                    <SelectLabel>Select Title</SelectLabel>
                                                    {title?.map((i) => (
                                                        <SelectItem key={i.id} value={i.id.toString()}>
                                                            {i.title}
                                                        </SelectItem>
                                                    ))}
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                        <FormMessage />
                                    </FormItem>
                                );
                            }}
                        />
                        <div className="flex flex-row justify-evenly pt-8">
                            <Button type="submit" >Update</Button>
                            <Button type="button" onClick={handleClose}> Close</Button>
                        </div>
                    </form>
                </Form>

            </DialogContent>
        </Dialog >
    );
}

export default ChangeTitleDialog;
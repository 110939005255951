export const OrderStatusData = [
    {
        id: 1,
        status: "Pending",
    },
    {
        id: 2,
        status: "Order received",
    },
    {
        id: 3,
        status: "Processing",
    },
    {
        id: 4,
        status: "On transit",
    },
    {
        id: 5,
        status: "Delivered",
    },
    {
        id: 6,
        status: "Delivery",
    },
    {
        id: 7,
        status: "Cancelled",
    },
];

import { AccountPermission } from "@/interfaces/AccountModel";
import { APICallParams, makeAuthenticatedAPICall } from "@/utils/apiCall";
import { useEffect, useState } from "react";
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";
import { Button } from "../ui/button";
import { FaPlus } from "react-icons/fa6";
import { useNavigate } from "react-router";


function Permissions() {
    const [permission, setPermission] = useState<AccountPermission[]>();
    // const location = useLocation();
    const navigate = useNavigate();
    // const [currentPage, setCurrentPage] = useState(0);
    // const itemsPerPage = 10;

    const loadPermission = async () => {
        try {
            const uri = "/v1/permission/"
            const encoded = encodeURI(uri);
            const apiParams: APICallParams = {
                verb: "GET",
                url: encoded,
                params: {},
                body: {},
            };
            const response = await makeAuthenticatedAPICall(apiParams);

            if (response.status === 200) {
                setPermission(response.data)
            }

        } catch (error) {
            console.error("Error during load premissions:", error);
        }
    }

    const handleNewClick = () => {
        navigate("/admin/permissions/new")
    }

    const handleEditClick = (pid: number) => {
        const fill = permission?.find((i) => i.id === pid)

        navigate("/admin/permissions/" + pid, { state: { uPermission: fill } })

    }

    useEffect(() => {
        loadPermission()
    }, []);
    return (
        <div className="h-screen w-full bg-white p-4 rounded-sm border border-gray-200 flex flex-col gap-2">
            <div className="flex flex-row justify-end gap-2">
                <Button onClick={() => handleNewClick()}>
                    <FaPlus className="mr-4 h-6 w-6" /> Permission
                </Button>
            </div>
            <Table>
                <TableCaption>A list of Permissions</TableCaption>
                <TableHeader>
                    <TableRow>
                        <TableHead className="w-[100px]">ID</TableHead>
                        <TableHead>API Actions</TableHead>
                        <TableHead>Group</TableHead>
                        <TableHead className="w-[100px]">Actions</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {permission?.map((i) => (
                        <TableRow key={i.id.toString()}>
                            <TableCell className="font-medium">{i.id}</TableCell>
                            <TableCell>{i.api_action}</TableCell>
                            <TableCell>{i.api_action_group}</TableCell>
                            <TableCell className="flex flex-1 flex-row gap-3">
                                <Button variant="outline" onClick={() => handleEditClick(i.id)}>
                                    Edit
                                </Button>
                                <Button variant="destructive">
                                    Delete
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}

export default Permissions
import * as z from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useLocation } from "react-router-dom";
import { VariantBaseData } from "@/data/ProductData";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { generateAWSUrl } from "@/api/services/awsServices";
import { updateBaseVariant } from "@/api/services/productServices";
import { toast } from "@/components/ui/use-toast";
import requestAWS from "@/api/services/awsApi";
import { Toaster } from "@/components/ui/toaster";
import Compressor from "compressorjs";

const formSchema = z.object({
  name_form: z.string().min(4),
  file_form: z.instanceof(FileList).optional(),
});

function VariantEdit() {
  const location = useLocation();
  const variant: VariantBaseData = location.state.variant;

  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name_form:
        variant.variant_type === null || variant.variant_type === undefined
          ? ""
          : variant.variant_type,
    },
  });

  const fileRef = form.register("file_form");

  const handleSubmit = async (data: z.infer<typeof formSchema>) => {
    if (!imageUrl) {
      updateNoImage(data);
    } else {
      updateNewImage(data);
    }
  };

  const updateNoImage = async (data: z.infer<typeof formSchema>) => {
    const response = await updateBaseVariant({
      variant_id: variant.id,
      variant: data.name_form,
      image_url: variant.img_url,
    });

    const res = response?.[0];
    if (res.Success === "Success") {
      toast({
        title: "Product Varinat",
        description: "Successfully save.",
      });
    } else {
      toast({
        title: "Product Varinat",
        description: "Failed to save.",
      });
    }
  };

  const updateNewImage = async (data: z.infer<typeof formSchema>) => {
    const formData = new FormData();
    if (data.file_form) {
      formData.append(
        "fileType",
        data.file_form[0].type.replace("image/", ".")
      );
    } else {
      throw new Error("No file selected");
    }
    const result = await generateAWSUrl({
      body: {
        fileType: data.file_form[0].type.replace("image/", "."),
      },
    });
    if (result.statusCode === 201) {
      const contentType = data.file_form[0].type;
      // const bods = JSON.parse(result.body);
      // const cFile = await compressImageData(data.file_form?.[0]);
      const file = data.file_form?.[0];
      if (file) {
        new Compressor(file, {
          quality: 0.8,
          success: async (compressedResult) => {
            const bods = JSON.parse(result.body);
            try {
              const res = await requestAWS<any>({
                url: bods.uploadUrl,
                method: "PUT",
                data: compressedResult,
                headers: { "Content-Type": contentType },
              });
              return res;
            } catch (error) {
              throw new Error(`Error fetching data: ${error}`);
            }
          },
        });
      }

      // await saveImageAWSUrl(bods.uploadUrl, cFile, contentType);

      const dlUrl = JSON.parse(result.body);
      const response = await updateBaseVariant({
        variant_id: variant.id,
        variant: data.name_form,
        image_url: dlUrl.downloadUrl,
      });

      const res = response?.[0];
      if (res.Success === "Success") {
        toast({
          title: "Product Varinat",
          description: "Successfully update.",
        });
      } else {
        toast({
          title: "Product Varinat",
          description: "Failed to update.",
        });
      }
    }
  };

  return (
    <div className="h-full  w-full bg-white p-4 rounded-sm border border-gray-200 flex  flex-col">
      <strong className="text-gray-700 font-medium ">Update Product</strong>
      <div className="flex flex-row items-center">
        <div className="flex flex-1 flex-col items-center justify-center p-4">
          <Form {...form}>
            <form
              className="max-w-md  w-full flex flex-col gap-4"
              onSubmit={form.handleSubmit(handleSubmit)}
            >
              <FormField
                control={form.control}
                name="name_form"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Variant</FormLabel>
                      <FormControl>
                        <Input placeholder="Variant" type="text" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name="file_form"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Product Image</FormLabel>
                      <FormControl>
                        <Input
                          type="file"
                          {...fileRef}
                          onChange={(event) => {
                            const file = event.target.files?.[0];
                            field.onChange(
                              event.target?.files?.[0] ?? undefined
                            );
                            if (file) {
                              const url = URL.createObjectURL(file);
                              setImageUrl(url);
                            } else {
                              setImageUrl(null);
                            }
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />

              <Button type="submit" className="w-full">
                Update
              </Button>
            </form>
          </Form>
        </div>
        <div className="flex flex-1 items-start justify-start">
          {imageUrl && (
            <div>
              <img src={imageUrl} alt="Selected" className="w-[20rem]" />
            </div>
          )}
        </div>
      </div>

      <Toaster />
    </div>
  );
}

export default VariantEdit;

// import VariantList from "@/components/product/VariantList";
import { Outlet } from "react-router";

export default function ProductLayout() {
  return (
    <div className="flex flex-col gap-2 h-screen">
      <div>{<Outlet />}</div>
    </div>
  );
}


import { Outlet } from 'react-router'

export default function RateLayout() {
    return (
        <div className="flex flex-col gap-2">
            <div>{<Outlet />}</div>
        </div>
    )
}

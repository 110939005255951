export const getLoginToken = (): string | null => {
  return localStorage.getItem("login_token");
};

export const getSessionToken = (): string | null => {
  return localStorage.getItem("session_token");
};

export const getRefreshToken = (): string | null => {
  return localStorage.getItem("refresh_token");
};

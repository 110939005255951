
import { ProductBase, VariantBaseData } from "@/data/ProductData";
import { APICallParams, makeAuthenticatedAPICall } from "@/utils/apiCall";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { toast } from "../ui/use-toast";
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";
// import { useAuth } from "@/hooks/useAuth";
// import { fetchBetaVariantRate } from "@/services/ProductServices";
// import { VariantBetaModel } from "@/interfaces/ProductRateModel";
import { Button } from "../ui/button";
import { useNavigate } from "react-router";



function VariantRateBase() {
    // const login = useAuth();
    // const branachData: number[] = login?.user.branch_id
    const location = useLocation();
    // const producD: number = location.state.pid;
    // const producName: string = location.state.productName;
    const branchID: number = location.state.sbBranchID;
    const bProdcuct: ProductBase = location.state.sBproductRateData;
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [variants, setVariants] = useState<VariantBaseData[]>();
    // const [variantRate, setVariantRate] = useState<VariantBetaModel[]>()
    const navigate = useNavigate();
    // const [branch2, setBranch2] = useState<number>();
    const sBrandID: number = location.state.sBrandID;

    // const form = useForm<z.infer<typeof FormSchema>>({
    //     resolver: zodResolver(FormSchema),

    // })

    // const handleSearchClick = (data: z.infer<typeof FormSchema>) => {


    //     // branchTagSave(parseInt(data.branchID))
    //     // console.log(data)
    //     // toast({
    //     //     title: "Branch",
    //     //     description: "Successfully Save.",
    //     // });
    //     setBranch2(parseInt(data.branchID))
    //     fectBaseVariantRate(parseInt(data.branchID))
    //     fectBaseVariant()
    // }


    const fectBaseVariant = async () => {
        try {
            const uri = "/v1/wet_market/products/" + bProdcuct.id + "/variants/"
            // const encoded = encodeURI(uri);
            const apiParams: APICallParams = {
                verb: "GET",
                url: uri,
                params: { page: 0, limit: 10 },
                body: {},
            };
            const response = await makeAuthenticatedAPICall(apiParams);
            if (response.status === 200) {
                setVariants(response.data)
            } else {
                toast({
                    title: "Variant",
                    description: "Failed load Variant",
                });
            }
        } catch (error) {
            setError((error as Error).message);
        } finally {
            setLoading(false);
        }
    }

    // const fectBaseVariantRate = async () => {
    //     try {
    //         const response = await fetchBetaVariantRate({
    //             branch_id: branchID,
    //             product_id: producD
    //         })

    //         setVariantRate(response)
    //     } catch (error) {
    //         setError((error as Error).message);
    //     } finally {
    //         setLoading(false);
    //     }
    // }

    // function getBranchName(bID: number) {
    //     const data = aBranachData?.find((i) => i.idx === bID)
    //     return data?.branch_name ?? "";
    // }


    // const loadBranch = async () => {
    //     try {

    //         const response = await fetchBranches()

    //         setaBranachData(response)

    //     } catch (error) {
    //         setError((error as Error).message);
    //     } finally {
    //         setLoading(false);
    //     }
    // }

    // function getVariantPricing(variantID: number) {
    //     const rate = variantRate?.find((i) => i.variant_id === variantID)

    //     return rate?.orig_price.toString() ?? "0.00"
    // }

    // const handleEditClick = (variantID: number, vname: string) => {

    //     const productID = bProdcuct.id
    //     navigate("/branch/" + sBrandID + "/baseproduct/" + productID + "/variants/" + variantID, { state: { bid: branch2, pid: productID, productName: producName, variantID: variantID, vname: vname, sBrandID: sBrandID } })
    // }

    const handleEEditClick = (variantID: number) => {
        const fill = variants?.find((i) => i.id === variantID)


        const productID = bProdcuct.id
        navigate("/branch/" + sBrandID + "/baseproduct/" + productID + "/variants/" + variantID, { state: { sBVariantData: fill, sBproductRateData: bProdcuct, sbBranchID: branchID } })
    }

    useEffect(() => {
        fectBaseVariant()
        // fectBaseVariantRate()
        // if (!producD) {
        //     toast({
        //         title: "Variant",
        //         description: "don't have Product id",
        //     });
        // } else {
        //     // fectBaseVariant();
        //     // fectBaseVariantRate();
        //     if (!localStorage.getItem("branchesLS")) {
        //         loadBranch()
        //     } else {
        //         const branchesFromStorage = localStorage.getItem("branchesLS")
        //         const parsedbranches = branchesFromStorage ? JSON.parse(branchesFromStorage) : undefined;
        //         setaBranachData(parsedbranches)
        //     }
        // }
        // if (branachData) {
        //     const uniqueBranchIds: number[] = branachData.filter((id, index) => branachData.indexOf(id) === index);
        //     setbranachData(uniqueBranchIds)
        //     setBranch2(uniqueBranchIds[0])
        // }
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }
    return (
        <div className="bg-white p-4 rounded-sm border border-gray-200 flex flex-col flex-1 h-screen">
            {/* <div className="flex flex-row w-1/2 p-4">
                <Form {...form}>
                    <form className="flex flex-row items-center"
                        onSubmit={form.handleSubmit(handleSearchClick)}>
                        <FormField
                            control={form.control}
                            name="branchID"
                            render={({ field }) => {
                                return (
                                    <FormItem className="flex flex-row gap-5 items-center">
                                        <FormLabel >Search</FormLabel>
                                        <Select onValueChange={field.onChange}>
                                            <FormControl>
                                                <SelectTrigger>
                                                    <SelectValue placeholder="Select Branch" />
                                                </SelectTrigger>
                                            </FormControl>

                                            <SelectContent >
                                                <SelectGroup>
                                                    <SelectLabel>Select Branch</SelectLabel>
                                                    {branachData?.map((i) => (
                                                        <SelectItem key={i} value={i.toString()}>
                                                            {getBranchName(i)}
                                                        </SelectItem>
                                                    ))}
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                        <FormMessage />
                                    </FormItem>
                                );
                            }}
                        />
                        <div className="flex px-5 pt-2">
                            <Button className="p-5 " type="submit" >
                                <FaMagnifyingGlass className="mr-6 h-4 w-4" />
                                Search
                            </Button>

                        </div>

                    </form>

                </Form>
            </div> */}



            <Table>
                <TableCaption>A list of Variant.</TableCaption>
                <TableHeader>
                    <TableRow>
                        <TableHead className="w-[100px]">ID</TableHead>
                        <TableHead>Variant</TableHead>
                        <TableHead>Image</TableHead>
                        <TableHead className="w-[100px]">Actions</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {variants?.map((i) => (
                        <TableRow key={i.id}>
                            <TableCell className="font-medium">{i.id}</TableCell>
                            <TableCell>{i.variant_type}</TableCell>
                            <TableCell>
                                <img
                                    src={i.img_url}
                                    className="w-[4rem] "
                                    alt={i.img_url}
                                />
                            </TableCell>
                            <TableCell>
                                {/* <Button onClick={() => handleEditClick(i.id, i.variant_type)}>Edit</Button> */}
                                <Button onClick={() => handleEEditClick(i.id)}>
                                    Edit
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))},

                </TableBody>
            </Table>
        </div>
    )
}

export default VariantRateBase
import { RiderModel } from "@/interfaces/RiderModel";
import { useLocation } from "react-router";
import { Button } from "../ui/button";
import { APICallParams, makeWithOutAuthenticatedAPICall } from "@/utils/apiCall";
import { toast } from "../ui/use-toast";
import { useNavigate } from "react-router";



export default function Rider() {
    const location = useLocation();
    const riderDetails: RiderModel = location.state.riderData;
    const navigate = useNavigate();

    const handleAcceptClick = async (userID: number) => {
        await acceptRider(userID)
        navigate("/admin/riders");
    }

    const handleDeclineClick = async (userID: number) => {
        await declineRider(userID)
        navigate("/admin/riders");
    }


    const declineRider = async (userID: number) => {
        try {
            const apiParams: APICallParams = {
                verb: "POST",
                url: "/rider/screening_approval",
                params: {},
                body: {
                    jsondata: { user_id: userID, isaccepted: 0 }
                }
            };
            const response = await makeWithOutAuthenticatedAPICall(apiParams)
            if (response.status !== 200) {
                toast({
                    variant: "destructive",
                    title: "Rider Application",
                    description: response.response.data.message,
                });
            } else {
                toast({
                    title: "Rider Application",
                    description: "Declined",
                });
            }
        } catch (error) {
            throw new Error(`Error fetching data: ${error}`);
        }
    }

    const acceptRider = async (userID: number) => {
        try {
            const apiParams: APICallParams = {
                verb: "POST",
                url: "/rider/screening_approval",
                params: {},
                body: {
                    jsondata: { user_id: userID, isaccepted: 1 }
                },
            };
            const response = await makeWithOutAuthenticatedAPICall(apiParams)
            if (response.status !== 200) {
                toast({
                    variant: "destructive",
                    title: "Rider Application",
                    description: response.response.data.message,
                });
            } else {
                toast({
                    title: "Rider Application",
                    description: "Accepted",
                });
            }
        } catch (error) {
            throw new Error(`Error fetching data: ${error}`);
        }
    }

    return (
        <div className="bg-white p-4 rounded-sm border border-gray-200 flex gap-5 flex-col flex-1 h-screen overflow-auto pb-20">
            <strong className="text-gray-700 font-medium">Rider details</strong>

            <div>
                <img
                    src={riderDetails.selfie}
                    alt={riderDetails.selfie}
                    className="mx-auto w-32 float-left"
                />

            </div>
            <div className=" flex flex-col w-1/2 gap-2">
                <div className="flex flex-row items-center justify-between w-full">
                    <div className="flex flex-col gap-2 px-2">
                        <p className="border border-spacing-1 rounded-lg p-2 w-[12rem]">{riderDetails.lname}</p>
                        <p className="text-sm">Last Name</p>
                    </div>
                    <div className="flex flex-col gap-2 px-2">
                        <p className="border border-spacing-1 rounded-lg p-2 w-[12rem]">{riderDetails.fname}</p>
                        <p className="text-sm">First Name</p>
                    </div>
                    <div className="flex flex-col gap-2 px-2">
                        <p className="border border-spacing-1 rounded-lg p-2 w-[10rem] text-gray-300">Middle Name</p>
                        <p className="text-sm">Middle Name</p>
                    </div>
                </div>
                <div className="flex flex-col gap-2 px-2">
                    <p className="border border-spacing-1 rounded-lg p-2">{riderDetails.contact}</p>
                    <p className="text-sm">Contact No.</p>
                </div>
                <div className="p-2">
                    <p className="text-lg">NBI Clearance</p>
                    <div className="flex flex-row justify-around p-2 border rounded-lg">
                        <div className="flex flex-col items-center">
                            <img
                                src={riderDetails.nbi}
                                alt={riderDetails.nbi}
                                className="mx-auto w-64 float-left"
                            />
                        </div>
                    </div>
                </div>
                <div className="p-2">
                    <p className="text-lg">Driver License</p>
                    <div className="flex flex-row justify-around p-2 border rounded-lg">
                        <div className="flex flex-col items-center">
                            <img
                                src={riderDetails.drivers_front}
                                alt={riderDetails.drivers_front}
                                className="mx-auto w-64 float-left"
                            />
                            <p className="text-sm">Front</p>
                        </div>

                        <div className="flex flex-col items-center">
                            <img
                                src={riderDetails.drivers_back}
                                alt={riderDetails.drivers_back}
                                className="mx-auto w-64 float-left"
                            />
                            <p className="text-sm">Back</p>
                        </div>
                    </div>
                </div>
                <div className="p-2">
                    <p className="text-lg">Vehicle</p>
                    <div className="flex flex-row justify-around p-2 border rounded-lg">
                        <div className="flex flex-col items-center">
                            <img
                                src={riderDetails.vehicle_img}
                                alt={riderDetails.vehicle_img}
                                className="mx-auto w-64 float-left"
                            />
                        </div>
                    </div>
                </div>
                <div className="p-2">
                    <p className="text-lg">Vehicle OR</p>
                    <div className="flex flex-row justify-around p-2 border rounded-lg">
                        <div className="flex flex-col items-center">
                            <img
                                src={riderDetails.vehicle_or}
                                alt={riderDetails.vehicle_or}
                                className="mx-auto w-64 float-left"
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-row gap-2 justify-between p-2">
                    <Button onClick={() => handleAcceptClick(riderDetails.user_id)}>
                        Accept
                    </Button>
                    <Button variant="destructive" onClick={() => handleDeclineClick(riderDetails.user_id)}>
                        Decline
                    </Button>

                </div>
            </div>
        </div>
    )
}

import React from "react";
import logo from '@/assets/pickappsbw.png';
import { Item } from "@/interfaces/OrdersModel";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";



type ReceiptProps = {
    orderID: number;
    address: string;
    email: string;
    date: string;
    time: string;
    cashier: string;
    branch: string;
    items: Item[];
    message: string;
};

const ReceiptOrders = React.forwardRef<HTMLDivElement, ReceiptProps>((props, ref) => {

    const calculateItemTotal = (item: Item) => {
        return item.item_qty * item.amount;
    };

    const calculateSubtotal = () => {
        return props.items.reduce((total, item) => total + calculateItemTotal(item), 0);

    };

    const calculateTotalQty = () => {
        return props.items.reduce((total, item) => total + item.item_qty, 0);
    };


    return (
        <div ref={ref} className="w-[80mm] p-3 border mx-auto text-left font-mono space-y-2">
            <img src={logo} alt="Logo" className="px-10" />
            {/* <div className="flex flex-col text-[10px] text-center line-clamp-2 px-3"> */}
            <div className="flex flex-col text-[10px] text-center px-3">
                <p>{props.address}</p>
                <div className="flex flex-row text-[10px]  justify-center">
                    <p className="line-clamp-1">Email: {props.email}</p>
                </div>
            </div>
            <div className="my-2 border-t border-b border-gray-400"></div>
            <div className="flex flex-row text-[9px] pr-2 justify-between">
                <div>
                    <p>Cashier: {props.cashier}</p>
                    <p>Branch: {props.branch}</p>
                </div>
                <div>
                    <p>Date: {props.date}</p>
                    <p>Time: {props.time}</p>
                </div>
            </div>
            <div className="my-2 border-t border-b border-gray-400"></div>
            {/* 
            <table>
                <thead className="h-10">
                    <tr>
                        <th className="px-4 py-1 text-[12px]">Item</th>
                        <th className="px-4 py-1 text-[12px]">Qty</th>
                        <th className="px-4 py-1 text-[12px]">Price</th>
                        <th className="px-4 py-1 text-[12px]">Amount</th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {props.items.map((item, index) => (
                        <tr key={index} className="flex justify-between">
                            <td className="px-4 py-2 line-clamp-2">{item.product_name}</td>
                            <td className="px-4 py-2">{item.item_qty}</td>
                            <td className="px-4 py-2">{item.amount}</td>
                            <td className="px-4 py-2">{calculateItemTotal(item)}</td>
                        </tr>
                    ))}
                </tbody>
            </table> */}
            <div className="flex flex-col text-[10px] text-center text-wrap">
                <p className="text-[14px] font-bold text-center">Order No.: {props.orderID}</p>
                <Table >
                    <TableHeader className="bg-white">
                        <TableRow>
                            <TableHead className="text-[10px] text-black font-bold p-0 h-min w-[180px]">Item</TableHead>
                            <TableHead className="text-[10px] text-black font-bold p-0 h-min w-[20px]">Qty</TableHead>
                            <TableHead className="text-[10px] text-black font-bold p-0 h-min w-[40px]">Price</TableHead>
                            <TableHead className="text-[10px] text-black font-bold p-0 h-min w-[40px]">Amount</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {props.items.map((i) => (
                            <TableRow key={i.product_name} className="">
                                {/* WRAP TEXT  */}
                                <TableCell className="text-[10px] p-0 h-min " >{i.product_name}</TableCell>

                                <TableCell className="text-[10px] p-0 h-min align-top">{i.item_qty}</TableCell>
                                <TableCell className="text-[10px] p-0 h-min align-top">{i.amount.toFixed(2)}</TableCell>
                                <TableCell className="text-[10px] p-0 pr-2 h-min align-top text-end">{calculateItemTotal(i).toFixed(2)}</TableCell>
                            </TableRow>
                        ))
                        }
                    </TableBody>
                </Table>

            </div>
            <div className="my-2 border-t border-b border-gray-400"></div>

            <div className="flex flex-col">

                <div className="flex flex-row text-[12px] font-bold justify-between px-2">
                    <p>Subtotal</p>
                    <p>{calculateSubtotal().toFixed(2)}</p>
                </div>
                <div className="flex flex-row text-[12px] font-bold justify-between px-2">
                    <p>Tax</p>
                    {/* <p>0</p> */}
                </div>
                <div className="flex flex-row text-[12px] font-bold justify-between px-2">
                    <p>Total</p>
                    <p>{calculateSubtotal().toFixed(2)}</p>
                </div>

                <div className="flex flex-row text-[12px] font-bold justify-between py-2 px-2">
                    <p>Total number of item/s</p>
                    <p>{calculateTotalQty()}</p>
                </div>
            </div>

            <p className="flex flex-col text-[8px] text-center line-clamp-2 px-3">{props.message}</p>
            <div className="my-2 border-t border-b border-gray-400"></div>
        </div>
    )
}
);

export default ReceiptOrders;
"use client";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../ui/form";
import { Input } from "../../ui/input";
import { Button } from "../../ui/button";
import { ProductBase } from "@/data/ProductData";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { generateAWSUrl } from "@/api/services/awsServices";
import requestAWS from "@/api/services/awsApi";
import { saveVariant } from "@/api/services/productServices";
import Compressor from "compressorjs";
import { Toaster } from "@/components/ui/toaster";
import { toast } from "@/components/ui/use-toast";

const formSchema = z.object({
  product_form: z.string().min(3),
  variant_form: z.string().min(3),
  file_form: z.instanceof(FileList).optional(),
});
// .refine(
//   (data) => {
//     if (data.product_type === "555 Tuna") {
//       return !!data.product_type;
//     }
//     return true;
//   },
//   {
//     message: "Product is required",
//     path: ["555 Sardines"],
//   }
// );

function Variant() {
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const product: ProductBase = location.state?.prod;

  const [imageUrl, setImageUrl] = useState<string | null>(null);
  // const [dlImage, setDLImage] = useState();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      product_form:
        product?.product_name === null || product?.product_name === undefined
          ? ""
          : product?.product_name,
      variant_form: "",
    },
  });

  const fileRef = form.register("file_form");

  const handleSubmit = (data: z.infer<typeof formSchema>) => {
    // console.log(data);
    uploadImage(data);
  };

  const uploadImage = async (data: z.infer<typeof formSchema>) => {
    try {
      const formData = new FormData();
      if (data.file_form) {
        formData.append(
          "fileType",
          data.file_form[0].type.replace("image/", ".")
        );
      } else {
        throw new Error("No file Selected");
      }
      const result = await generateAWSUrl({
        body: {
          fileType: data.file_form[0].type.replace("image/", "."),
        },
      });

      if (result.statusCode === 201) {
        const contentType = data.file_form[0].type;
        new Compressor(data.file_form[0], {
          quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
          success: async (compressedResult) => {
            const bods = JSON.parse(result.body);
            try {
              const res = await requestAWS<any>({
                url: bods.uploadUrl,
                method: "PUT",
                data: compressedResult,
                headers: { "Content-Type": contentType },
              });
              return res;
            } catch (error) {
              throw new Error(`Error fetching data: ${error}`);
            }
          },
        });

        const dlUrl = JSON.parse(result.body);
        const sResult = await saveVariant({
          productid: product.id,
          varianttype: data.variant_form,
          imageurl: dlUrl.downloadUrl,
        });

        if (sResult === "OK") {
          toast({
            title: "Variant",
            description: "Successfully save.",
          });
        } else {
          toast({
            title: "Variant",
            description: "Failed to save.",
          });
        }
      }
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    setError(null)
    setLoading(false);
  }, []);
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="h-screen  bg-white p-4 rounded-sm border border-gray-200 flex  flex-col">
      <strong className="text-gray-700 font-medium">New Variant</strong>

      <div className="flex flex-row items-center">
        <div className="flex flex-1 flex-col items-center justify-center p-4">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(handleSubmit)}
              className="max-w-md  w-full flex flex-col gap-4"
            >
              <FormField
                control={form.control}
                name="product_form"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Product </FormLabel>
                    <FormControl>
                      <Input
                        type="label"
                        placeholder="product"
                        {...field}
                        disabled
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="variant_form"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Variant</FormLabel>
                    <FormControl>
                      <Input type="text" placeholder="Variant" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="file_form"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>File</FormLabel>
                      <FormControl>
                        <Input
                          type="file"
                          {...fileRef}
                          onChange={(event) => {
                            const file = event.target.files?.[0];
                            field.onChange(
                              event.target?.files?.[0] ?? undefined
                            );
                            if (file) {
                              const url = URL.createObjectURL(file);
                              setImageUrl(url);
                            } else {
                              setImageUrl(null);
                            }
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <Button type="submit" className="w-full">
                Save
              </Button>
            </form>
          </Form>
        </div>
        <div className="flex flex-1 items-start justify-start">
          {imageUrl && (
            <div>
              <img src={imageUrl} alt="Selected" className="w-[20rem]" />
            </div>
          )}
        </div>
      </div>

      <Toaster />
    </div>
  );
}

export default Variant;

import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { TitleModel } from "@/interfaces/TitleModel";
import { useLocation } from "react-router";
import { useForm } from "react-hook-form";
import { Form, FormControl, FormField, FormItem, FormLabel } from "../ui/form";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { APICallParams, makeAuthenticatedAPICall } from "@/utils/apiCall";
import { toast } from "../ui/use-toast";

const formSchema = z.object({
    title: z.string()
})


function JobTitleEdit() {
    const location = useLocation();
    const title: TitleModel = location.state?.titleD

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            title: title.title ?? "",
        },
    });

    const handleUpdateClick = async (data: z.infer<typeof formSchema>) => {
        try {
            const apiParams: APICallParams = {
                verb: "PATCH",
                url: "/v1/admin-title/" + title.id,
                params: {},
                body: { title: data.title },
            };

            const response = await makeAuthenticatedAPICall(apiParams);

            if (response.status !== 200) {
                toast({
                    title: "Job Title",
                    description: response.data.message,
                });
            } else {
                toast({
                    title: "Job Title",
                    description: "Successfully Update.",
                });
            }
        } catch (error) {
            throw new Error(`Error fetching data: ${error}`);
        }
    }

    return (
        <div className="bg-white p-4 rounded-sm border border-gray-200 flex flex-col flex-1 h-screen gap-2">
            <strong className="text-gray-700 font-medium">New Job Title</strong>
            <div className="flex justify-center p-5">
                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(handleUpdateClick)}
                        className="max-w-md  w-full flex flex-col gap-4"
                    >
                        <FormField
                            control={form.control}
                            name="title"
                            render={({ field }) => {
                                return (
                                    <FormItem>
                                        <FormLabel>Title</FormLabel>
                                        <FormControl>
                                            <Input
                                                placeholder="Job title"
                                                type="text"
                                                autoComplete="off"
                                                {...field}
                                            />
                                        </FormControl>
                                    </FormItem>
                                );
                            }}
                        />

                        <Button type="submit" className="w-full">
                            Update
                        </Button>
                    </form>

                </Form>
            </div>

        </div>
    )
}

export default JobTitleEdit
"use client";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../ui/form";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";
import { getCategory } from "@/api/services/productServices";
import {
  CategoryBaseData,
  ProductsData,
} from "@/data/ProductData";
import { useEffect, useState } from "react";
import { generateAWSUrl } from "@/api/services/awsServices";
import requestAWS from "@/api/services/awsApi";
import Compressor from "compressorjs";
import { useLocation } from "react-router-dom";
import { toast } from "@/components/ui/use-toast";
import { Toaster } from "@/components/ui/toaster";
import { APICallParams, makeAuthenticatedAPICall } from "@/utils/apiCall";
import { useAuth } from "@/hooks/useAuth";

const formSchema = z.object({
  category_type: z.string(),
  category_form: z.string().optional(),
  product_form: z.string().min(4),
  product_file_form: z.instanceof(FileList).optional(),
});
// .refine(
//   (data) => {
//     if (data.category_type === "Pharmacy") {
//       return !!data.category_type;
//     }
//     return true;
//   },
//   {
//     message: "Category is required",
//     path: ["Grocery"],
//   }
// );

function Product() {
  let login = useAuth();
  const location = useLocation();
  const prod: ProductsData | undefined = location.state?.prod;
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedOption, setSelectedOption] = useState<CategoryBaseData[]>();

  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      product_form:
        prod?.product_name === null || prod?.product_name === undefined
          ? ""
          : prod.product_name,
      category_type: "",
    },
  });
  // const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.files && event.target.files.length > 0) {
  //     setImage(event.target.files[0]);
  //   }
  // };

  const fileRef = form.register("product_file_form");

  // const imageCheck = async (data: z.infer<typeof formSchema>) => {
  //   if (
  //     data.product_file_form &&
  //     Array.isArray(data.product_file_form) &&
  //     data.product_file_form.length > 0
  //   ) {
  //     const imageFile = data.product_file_form[0];

  //     const result = await compressImageData(imageFile);
  //     console.log(result);
  //   }
  // };

  const uploadImage = async (data: z.infer<typeof formSchema>) => {
    try {
      const formData = new FormData();
      if (data.product_file_form) {
        formData.append(
          "fileType",
          data.product_file_form[0].type.replace("image/", ".")
        );
      } else {
        throw new Error("No file selected");
      }
      const result = await generateAWSUrl({
        body: {
          fileType: data.product_file_form[0].type.replace("image/", "."),
        },
      });

      if (result.statusCode === 201) {
        const contentType = data.product_file_form[0].type;
        new Compressor(data.product_file_form[0], {
          quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
          success: async (compressedResult) => {
            const bods = JSON.parse(result.body);
            try {
              const res = await requestAWS<any>({
                url: bods.uploadUrl,
                method: "PUT",
                data: compressedResult,
                headers: { "Content-Type": contentType },
              });
              return res;
            } catch (error) {
              throw new Error(`Error fetching data: ${error}`);
            }
          },
        });

        const dlUrl = JSON.parse(result.body);
        // setDLImage(dlUrl.downloadUrl);
        // const sResult = await saveProduct({
        //   categoryid: parseInt(data.category_type),
        //   productname: data.product_form,
        //   url: dlUrl.downloadUrl,
        // });
        const apiParams: APICallParams = {
          verb: "POST",
          url: "/v1/wet_market/products",
          params: {},
          body: {
            category_id: parseInt(data.category_type),
            name: data.product_form,
            img_url: dlUrl.downloadUrl,
          },
        };
        const res = await makeAuthenticatedAPICall(apiParams);

        // if (res === "") {
        //   login?.logout();
        // } else {
        //   setProducts(result.data);
        //   setproductsprev(result.data);
        // }
        // if (sResult?.[0].Success === "Success") {
        //   toast({
        //     title: "Product",
        //     description: "Successfully save.",
        //   });
        // }
        if (!res) {
          login?.logout();
        } else if (res.status === 409) {
          toast({
            title: "Product",
            description: "Already exists",
          });
        } else if (res.status !== 204) {
          toast({
            title: "Product",
            description: "Failed to save.",
          });
        } else if (res.status === 409) {
          toast({
            title: "Product",
            description: "Already exists",
          });
        } else {
          toast({
            title: "Product",
            description: "Successfully save.",
          });
        }
      }
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (data: z.infer<typeof formSchema>) => {
    uploadImage(data);
    // imageCheck(data);
    // console.log(data);
  };

  const fetchProductCategory = async () => {
    try {
      const category = await getCategory();
      setSelectedOption(category);
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProductCategory();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="h-full  w-full bg-white p-4 rounded-sm border border-gray-200 flex  flex-col">
      <strong className="text-gray-700 font-medium ">New Product</strong>
      <div className="flex flex-row items-center">
        <div className="flex flex-1 flex-col items-center justify-center p-4">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(handleSubmit)}
              className="max-w-md  w-full flex flex-col gap-4"
            >
              <FormField
                control={form.control}
                name="product_form"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Product</FormLabel>
                      <FormControl>
                        <Input placeholder="product" type="text" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name="category_type"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Category</FormLabel>
                      <Select onValueChange={field.onChange}>
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select Category" />
                          </SelectTrigger>
                        </FormControl>

                        <SelectContent>
                          <SelectGroup>
                            <SelectLabel>Select Category</SelectLabel>
                            {selectedOption?.map((category) => (
                              <SelectItem
                                key={category.idx}
                                value={category.idx.toString()}
                              >
                                {category.category_name}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name="product_file_form"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Product Image</FormLabel>
                      <FormControl>
                        <Input
                          type="file"
                          {...fileRef}
                          onChange={(event) => {
                            const file = event.target.files?.[0];
                            field.onChange(
                              event.target?.files?.[0] ?? undefined
                            );
                            if (file) {
                              const url = URL.createObjectURL(file);
                              setImageUrl(url);
                            } else {
                              setImageUrl(null);
                            }
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <Button type="submit" className="w-full">
                Save
              </Button>
            </form>
          </Form>
        </div>
        <div className="flex flex-1 items-start justify-start">
          {imageUrl && (
            <div>
              <img src={imageUrl} alt="Selected" className="w-[20rem]" />
            </div>
          )}
        </div>
      </div>
      <Toaster />
    </div>
  );
}

export default Product;

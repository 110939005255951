import { useEffect, useState } from 'react'
import { Table, TableBody, TableCaption, TableCell, TableFooter, TableHead, TableHeader, TableRow } from '../ui/table'
import { Input } from '../ui/input'
import { Button } from '../ui/button'
import { FaMagnifyingGlass } from 'react-icons/fa6'
import { ProductBase } from '@/data/ProductData'
import { APICallParams, makeAuthenticatedAPICall } from '@/utils/apiCall'
import { fetchCategory } from '@/services/ProductServices'
import { CategoryModel } from '@/interfaces/ProductRateModel'
import { useLocation, useNavigate } from "react-router";
// import { useAuth } from "@/hooks/useAuth";
// import { z } from "zod"
// import { zodResolver } from "@hookform/resolvers/zod"
// import { useForm } from 'react-hook-form'
// import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from '../ui/select'
// import { toast } from '../ui/use-toast'

// const FormSchema = z.object({
//   branchID: z.string(),
//   search: z.string()
// })

function ProductRateBase() {
  const navigate = useNavigate();
  const [product, setProduct] = useState<ProductBase[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [category, setCategory] = useState<CategoryModel[]>()
  const [searchTerm, setSearchTerm] = useState<string>("");
  // const [aBranachData, setaBranachData] = useState<AccountBranch[]>();

  const location = useLocation();
  const selectedBrandID: number = location.state.sBrandID;

  // const form = useForm<z.infer<typeof FormSchema>>({
  //   resolver: zodResolver(FormSchema),
  //   defaultValues: {
  //     search: ""
  //   }

  // })

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const getProductBase = async () => {
    try {
      const apiParams: APICallParams = {
        verb: "GET",
        url: "/v1/wet_market/products",
        params: { page: 0, limit: 100 },
        body: {},
      };
      const response = await makeAuthenticatedAPICall(apiParams);
      if (response.status === 200) {
        setProduct(response.data)
        localStorage.setItem("ProductBase", JSON.stringify(response.data))
      }
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  }

  // const handleSearchClick = (data: z.infer<typeof FormSchema>) => {
  //   // const inputElement = document.getElementById("searchID") as HTMLInputElement
  //   // const inputValue = inputElement.value;
  //   // setSearchTerm(data.search)
  //   if (data.branchID) {
  //     setProduct([])
  //     getProductBaseWithSearch(data.search)

  //     setBranch2(parseInt(data.branchID))
  //   } else {
  //     toast({
  //       variant: "destructive",
  //       title: "Pricing",
  //       description: "Please Select Branch",
  //     });
  //   }
  // }

  const handleSearchClick = () => {
    const inputElement = document.getElementById("searchID") as HTMLInputElement
    const inputValue = inputElement.value;
    setSearchTerm(inputValue)


    getProductBaseWithSearch()
  }

  const getProductBaseWithSearch = async () => {
    try {
      const apiParams: APICallParams = {
        verb: "GET",
        url: "/v1/wet_market/products_search",
        params: { s: searchTerm, page: 0, limit: 100 },
        body: {},
      };
      const response = await makeAuthenticatedAPICall(apiParams);
      if (response.status === 200) {
        setProduct(response.data)
      }
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  }

  function getCategory(catid: number) {
    const cat = category?.find((i) => i.idx === catid)
    return cat?.category_name ?? ""
  }

  const getCategoryBase = async () => {
    try {
      const response = await fetchCategory()
      setCategory(response)
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  }

  // function getBranchName(bID: number) {
  //   const data = aBranachData?.find((i) => i.idx === bID)
  //   return data?.branch_name ?? "";
  // }

  const handleEditClick = (productID: number) => {
    const fill = product?.find((i) => i.id === productID)
    navigate("/branch/" + selectedBrandID + "/baseproduct/" + productID + "/rate", { state: { sBproductRateData: fill, sBrandID: selectedBrandID } })
  }

  const handleViewClick = (productID: number) => {
    const fill = product?.find((i) => i.id === productID)

    navigate("/branch/" + selectedBrandID + "/baseproduct/" + productID + "/variants", { state: { sBproductRateData: fill, sbBranchID: selectedBrandID } })
  }

  // const handleEditClick = (pid: number) => {
  //   navigate("/branch/product/rates/" + pid)
  // }

  // const loadBranch = async () => {
  //   try {
  //     const response = await fetchBranches()
  //     setaBranachData(response)
  //     const branchesString = JSON.stringify(response);
  //     localStorage.setItem('branchesLS', branchesString);
  //   } catch (error) {
  //     setError((error as Error).message);
  //   } finally {
  //     setLoading(false);
  //   }
  // }

  useEffect(() => {
    getProductBase();
    getCategoryBase();
    // if (!localStorage.getItem("branchesLS")) {
    //   loadBranch()
    // } else {
    //   const branchesFromStorage = localStorage.getItem("branchesLS")
    //   const parsedbranches = branchesFromStorage ? JSON.parse(branchesFromStorage) : undefined;
    //   setaBranachData(parsedbranches)
    // }
    // if (branachData) {
    //   const uniqueBranchIds: number[] = branachData.filter((id, index) => branachData.indexOf(id) === index);
    //   setbranachData(uniqueBranchIds)
    // }
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  return (
    <div className="bg-white p-4 rounded-sm border border-gray-200 flex flex-col flex-1 h-screen gap-5">

      <strong className="text-gray-700 font-medium">Product Pricing</strong>

      <div className='flex flex-row items-center'>
        <div className="flex flex-row gap-2 w-80">
          <Input type="text" placeholder="Search products..." id="searchID" autoComplete='off' value={searchTerm} onChange={handleSearchChange} />
          <Button onClick={() => handleSearchClick()}>
            <FaMagnifyingGlass className="mr-2 h-4 w-4" /> Search
          </Button>
        </div>
        {/* <div className="flex flex-row w-1/2">
          <Form {...form}>
            <form className="flex flex-row gap-x-5"
              onSubmit={form.handleSubmit(handleSearchClick)}>
              <FormField
                control={form.control}
                name="branchID"
                render={({ field }) => {
                  return (
                    <FormItem className="flex flex-row gap-5 items-center">
                      <FormLabel >Search</FormLabel>
                      <Select onValueChange={field.onChange}>
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select Branch" />
                          </SelectTrigger>
                        </FormControl>

                        <SelectContent >
                          <SelectGroup>
                            <SelectLabel>Select Branch</SelectLabel>
                            {branachData?.map((i) => (
                              <SelectItem key={i} value={i.toString()}>
                                {getBranchName(i)}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name="search"
                render={({ field }) => {
                  return (
                    <FormItem className="flex flex-row gap-5 items-center">
                      <FormControl>
                        <Input type="text" placeholder="Search products..." id="searchID" autoComplete='off' {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />

              <div className="flex pt-2">
                <Button className="p-5 " type="submit" >
                  <FaMagnifyingGlass className="mr-6 h-4 w-4" />
                  Search
                </Button>

              </div>

            </form>
          </Form>
        </div> */}

      </div>

      <Table>
        <TableCaption>A list of Variant.</TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[100px]">ID</TableHead>
            <TableHead>Category</TableHead>
            <TableHead>Product</TableHead>
            <TableHead>Image</TableHead>
            {/* <TableHead>Rate/Price</TableHead> */}
            <TableHead className="w-[100px]">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {product?.map((i) => (
            <TableRow key={i.id}>
              <TableCell className="font-medium">{i.id}</TableCell>
              <TableCell>{getCategory(i.category_id)}</TableCell>
              <TableCell>{i.product_name}</TableCell>
              <TableCell>
                <img
                  src={i.img_url}
                  className="w-[4rem] "
                  alt={i.img_url}
                />
              </TableCell>
              {/* <TableCell>00.00</TableCell> */}
              <TableCell>
                <div className='flex flex-row px-5 gap-x-5'>
                  <Button onClick={() => handleEditClick(i.id)}>Edit</Button>
                  <Button variant="outline" onClick={() => handleViewClick(i.id)}>View</Button>

                </div>

              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>

            <TableCell colSpan={6}>

              <div className="flex flex-row justify-center gap-x-10">
                <Button>
                  Prev
                </Button>

                <Button>
                  Next
                </Button>
              </div>

            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
      <div className='pb-10' />
    </div>
  )
}

export default ProductRateBase
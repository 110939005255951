
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { ProductBase } from "@/interfaces/ProductRateModel";
import { useLocation } from "react-router";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../ui/form";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { APICallParams, makeWithOutAuthenticatedAPICall } from "@/utils/apiCall";
import { toast } from "../ui/use-toast";

const FormSchema = z.object({
    product: z.string(),
    rate: z.string()
})

export default function EBProductRate() {
    const location = useLocation();
    const pdata: ProductBase = location.state.sBproductRateData;
    const pbranchID: number = location.state.sBrandID;

    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            product: pdata.product_name ?? "",
            rate: "0"
        }

    })

    const handleSaveClick = (data: z.infer<typeof FormSchema>) => {
        saveProductPrice(pbranchID, pdata.id, parseInt(data.rate))

    }

    const saveProductPrice = async (branchID: number, productID: number, rate: number) => {
        try {
            const apiParams: APICallParams = {
                verb: "PUT",
                url: "/v1/wet_market/branches/" + branchID + "/products/" + productID,
                params: { b_id: branchID, p_id: productID },
                body: {
                    orig_price: rate,
                    discount_price: rate,
                    promo_id: 0,
                    is_enabled: true
                },
            };
            const response = await makeWithOutAuthenticatedAPICall(apiParams)

            if (response.status !== 204) {
                toast({
                    variant: "destructive",
                    title: "New Product Rate/Price",
                    description: response.response.data.message,
                });
            } else {
                toast({
                    title: "New Product Rate/Price",
                    description: "successfully Update",
                });

            }
        } catch (error) {
            throw new Error(`Error fetching data: ${error}`);
        }
    }
    return (
        <div className="p-2 rounded-sm border border-gray-200 flex flex-col flex-1 h-screen gap-2">
            <strong className="text-gray-700 font-medium ">New Product Rate/Pricing</strong>

            <Form {...form}>
                <form className="flex flex-col gap-5 w-1/4"
                    onSubmit={form.handleSubmit(handleSaveClick)}>
                    <FormField
                        control={form.control}
                        name="product"
                        render={({ field }) => {
                            return (
                                <FormItem>
                                    <FormLabel>Product Name</FormLabel>
                                    <FormControl>
                                        <Input placeholder="product Name" type="text" {...field} disabled />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            );
                        }}
                    />
                    <FormField
                        control={form.control}
                        name="rate"
                        render={({ field }) => {
                            return (
                                <FormItem>
                                    <FormLabel>Rate/Price</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Rate/Price" type="text" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            );
                        }}
                    />
                    <Button>
                        Save
                    </Button>
                </form>
            </Form>
        </div>
    )
}


; import request from "../Api";
import { API_KEY } from "../constant";


export const saveCommission = async (param: any) => {
    try {
        const data = await request<any>({
            url: "/etc/commission_rate_update",
            method: "POST",
            data: { jsondata: param },
            headers: {
                X_API_Key: API_KEY,
            },
        });
        return data;
    } catch (error) {
        throw new Error(`Error fetching data: ${error}`);
    }
}
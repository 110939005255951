import { Form, FormControl, FormField, FormItem, FormLabel } from "../ui/form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "../ui/input";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { Button } from "../ui/button";
import { generateAWSUrl } from "@/api/services/awsServices";
import Compressor from "compressorjs";
import requestAWS from "@/api/services/awsApi";
import { saveBranch } from "@/api/services/branchServices";
import { toast } from "../ui/use-toast";
import MapComponent from "../map/MapComponent";

const formSchema = z.object({
  name_form: z.string(),
  address_form: z.string(),
  file_form: z.instanceof(FileList).optional(),
});
const Branch: React.FC = () => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [location, setLocation] = useState<[number, number] | null>(null);

  const handleLocationSelect = (lat: number, lng: number) => {
    setLocation([lat, lng]);
    // console.log(`Selected location: Latitude: ${lat}, Longitude: ${lng}`);
  };


  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name_form: "",
    },
  });

  const handleSubmit = async (data: z.infer<typeof formSchema>) => {

    const result = await generateAWSUrl({
      body: {
        fileType: data.file_form?.[0].type?.replace("image/", ".") || "",
      },
    });

    if (result.statusCode === 201) {
      const contentType = data.file_form?.[0].type;
      const file = data.file_form?.[0];
      if (file) {
        new Compressor(file, {
          quality: 0.8,
          success: async (compressedResult) => {
            const bods = JSON.parse(result.body);
            try {
              const res = await requestAWS<any>({
                url: bods.uploadUrl,
                method: "PUT",
                data: compressedResult,
                headers: { "Content-Type": contentType },
              });
              return res;
            } catch (error) {
              throw new Error(`Error fetching data: ${error}`);
            }
          },
        });
      }

      const dlUrl = JSON.parse(result.body);

      const sResult = await saveBranch({
        branchname: data.name_form,
        baddress: data.address_form,
        loclat: location?.[0] ?? 0,
        loclong: location?.[1] ?? 0,
        imgurl: dlUrl.downloadUrl,
      });
      const sres = sResult?.[0];
      if (sres.SUCCESS === "SUCCESS") {
        // navigate("/admin/branch");
        toast({
          title: "Mobile Number",
          description: "Successfully Update.",
        });
      } else {
        toast({
          title: "Mobile Number",
          description: "Failed Update.",
        });
      }
    }
  };



  const fileRef = form.register("file_form");

  // const branchSave = async () => {
  //   const apiParams: APICallParams = {
  //     verb: "GET",
  //     url: "https://pickapps.net/dev/v1/wet_market/products",
  //     params: {},
  //     body: {},
  //   };
  //   const result = await makeAuthenticatedAPICall(apiParams);

  //   try {

  //   } catch (error) {
  //     setError((error as Error).message);
  //   } finally {
  //     setLoading(false);
  //   }
  // }

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  // if (error) {
  //   return <div>Error: {error}</div>;
  // }
  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition((position) => {
  //     console.log(position)
  //       setLatitude(position.coords.latitude)
  //       setLongitude(position.coords.longitude)
  //   })

  // }, [])

  return (
    <div className="h-screen  w-full bg-white p-4 rounded-sm border border-gray-200 flex flex-1 flex-col overflow-auto pb-[4rem]">
      {/* <strong className="text-gray-700 font-medium">New Product</strong> */}

      <div className="flex flex-row items-center">
        <div className="flex flex-1 items-center justify-center p-2">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(handleSubmit)}
              className="max-w-md  w-full flex flex-col gap-2"
            >
              <FormField
                control={form.control}
                name="name_form"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Branch Name</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Branch Name"
                          type="text"
                          autoComplete="off"
                          {...field}
                        />
                      </FormControl>
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name="address_form"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Address</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Address"
                          type="text"
                          autoComplete="off"
                          {...field}
                        />
                      </FormControl>
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name="file_form"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Branch Image</FormLabel>
                      <FormControl>
                        <Input
                          type="file"
                          // placeholder="shadcn"
                          {...fileRef}
                          onChange={(event) => {
                            const file = event.target.files?.[0];
                            field.onChange(
                              event.target?.files?.[0] ?? undefined
                            );
                            if (file) {
                              const url = URL.createObjectURL(file);
                              setImageUrl(url);
                            } else {
                              setImageUrl(null);
                            }
                          }}
                        />
                      </FormControl>
                    </FormItem>
                  );
                }}
              />
              <p>Map</p>
              <div className="border border-gray-200 h-[32rem]">
                <div className="p-1">
                  <MapComponent onLocationSelect={handleLocationSelect} />
                  {location && (
                    <p>Selected location: Latitude: {location[0]}, Longitude: {location[1]}</p>
                  )}
                </div>

              </div>
              <Button type="submit" className="w-full">
                Save
              </Button>
            </form>
          </Form>

        </div>
        <div className="flex flex-1 items-center justify-start">

          {imageUrl && (
            <div>
              <p>Branch Image</p>
              <img src={imageUrl} alt="Selected" className="w-[20rem] " />
            </div>
          )}
        </div>

      </div>
    </div>
  );
};

export default Branch;

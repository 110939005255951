import { VariantBaseData, CategoryBaseData } from "@/data/ProductData";
import request from "../Api";
import { API_KEY } from "../constant";
import { ProductMode } from "@/interfaces/ProductRateModel";

export const getCategory = async () => {
  try {
    const data = await request<CategoryBaseData[]>({
      url: "/wet_market/product_category_load",
      method: "GET",
      headers: {
        X_API_Key: API_KEY,
      },
    });
    return data;
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`);
  }
};

export const getProduct = async () => {
  try {
    const data = await request<ProductMode[]>({
      url: "/wet_market/product_load",
      method: "GET",
      headers: {
        X_API_Key: API_KEY,
      },
    });
    return data;
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`);
  }
}

export const getVariantOld = async () => {
  try {
    const res = await request<any>({
      url: "/wet_market/product_variant_load",
      method: "GET",
      data: { query_string: "" },
      headers: {
        X_API_Key: API_KEY,
      },
    });

    return res;
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`);
  }
}


export const getVariant = async (paramValue: any) => {
  try {
    const data = await request<VariantBaseData[]>({
      url: "/beta_testing/branch_load_variants",
      method: "POST",
      data: paramValue,
      headers: {
        X_API_Key: API_KEY,
      },
    });
    return data;
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`);
  }
};

export const getVariantBase = async (paramValue: number) => {
  try {
    const data = await request<any>({
      url: "/v1/wet_market/products/" + paramValue,
      method: "GET",
      // params: paramValue,
      headers: {
        X_API_Key: API_KEY,
      },
    });
    return data;
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`);
  }
};

export const getBracnhProduct = async (param: any) => {
  try {
    const response = await request<any[] | undefined>({
      url: "/wet_market/branch_items_load",
      method: "POST",
      params: param,
      headers: {
        X_API_Key: API_KEY,
      },
    });
    return response;
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`);
  }
};

export const saveProduct = async (param: any) => {
  try {
    const response = await request<any[] | undefined>({
      url: "/wet_market/product_save",
      method: "POST",
      data: param,
      headers: {
        X_API_Key: API_KEY,
      },
    });
    return response;
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`);
  }
};

export const updateProduct = async (param: any) => {
  try {
    const response = await request<any[] | undefined>({
      url: "/wet_market/product_update",
      method: "POST",
      data: param,
      headers: {
        X_API_Key: API_KEY,
      },
    });
    return response;
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`);
  }
};

export const saveVariant = async (param: any) => {
  try {
    const response = await request<any>({
      url: "/wet_market/product_variant_save",
      method: "POST",
      data: param,
      headers: {
        X_API_Key: API_KEY,
      },
    });
    return response;
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`);
  }
};

export const updateBaseVariant = async (param: any) => {
  try {
    const response = await request<any>({
      url: "/wet_market/product_variant_update",
      method: "POST",
      data: param,
      headers: {
        X_API_Key: API_KEY,
      },
    });
    return response;
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`);
  }
};

export const saveProductRate = async (param: any) => {
  try {
    const response = await request<any>({
      url: "/wet_market/product_price_save",
      method: "POST",
      data: { "jsondata": param },
      headers: {
        X_API_Key: API_KEY,
      },
    });
    return response;
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`);
  }
}


export const savePRoductBaseRate = async (pathParameter: any, bodyShema: any) => {
  try {
    const response = await request<any>({
      url: "/v1/wet_market/branches/" + pathParameter.b_id + "/products/" + pathParameter.p_id,
      method: "PUT",
      params: pathParameter,
      data: bodyShema,
      headers: {
        X_API_Key: API_KEY,
      },
    });
    return response;
  } catch (error) {
    throw new Error(`Error fetching data: ${error}`);
  }
}
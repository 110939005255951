import { useEffect, useState } from "react"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../ui/table"
import { LoginHistoryModel } from "@/interfaces/ActivityModel"
import { APICallParams, makeAuthenticatedAPICall } from "@/utils/apiCall";
import { AccountModel } from "@/interfaces/AccountModel";
import { useLocation } from "react-router";

function Activities() {
    const location = useLocation();
    const [loginHistory, setLoginHistory] = useState<LoginHistoryModel[]>()
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const accountDetails: AccountModel = location.state?.accountDetails;


    const loadLoginHistory = async () => {
        try {
            const apiParams: APICallParams = {
                verb: "GET",
                url: "/v1/admin/" + accountDetails.id + "/login-history",
                params: { page: 0, limit: 100 },
                body: {},
            };


            const response = await makeAuthenticatedAPICall(apiParams);

            if (response.status === 200) {
                setLoginHistory(response.data)
            }
        } catch (error) {
            setError((error as Error).message);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        loadLoginHistory()
    }, [])

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="bg-white p-4 rounded-sm border border-gray-200 flex flex-col flex-1 h-screen gap-2">
            <strong className="text-gray-700 font-medium">Login History</strong>
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHead className="w-[80px]">ID</TableHead>
                        <TableHead>Reference Code</TableHead>
                        <TableHead>Create at</TableHead>
                        <TableHead>Validated at</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {loginHistory?.map((i, index) => (

                        <TableRow key={i.id.toString()}>
                            <TableCell className="font-medium">{index}</TableCell>
                            <TableCell>{i.ref_code}</TableCell>
                            <TableCell>{i.created_at}</TableCell>
                            <TableCell>{i.validated_at}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}

export default Activities
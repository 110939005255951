import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import { ProductBase, VariantBaseData } from "@/data/ProductData";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../ui/button";
import { FaPlus } from "react-icons/fa6";
import { APICallParams, makeAuthenticatedAPICall } from "@/utils/apiCall";
import { useAuth } from "@/hooks/useAuth";

const VariantList: React.FC = () => {

  const navigate = useNavigate();
  let login = useAuth();
  const location = useLocation();
  const product: ProductBase = location.state.product;
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [variants, setVariants] = useState<VariantBaseData[]>();


  const rowPerPage: number = 20;
  const [startIndex, setStartIndex] = useState<number>(0);

  const prevPage = () => {
    if (startIndex !== 0) {
      setStartIndex(startIndex - 1);
      fetchVariant();
    }
  };

  const nextPage = async () => {
    setStartIndex(startIndex + 1);
    fetchVariant();
  };


  const fetchVariant = async () => {
    try {
      let prodID: number = product.id;
      const apiParams: APICallParams = {
        verb: "GET",
        url: "/v1/wet_market/products/" + prodID + "/variants/",
        params: { page: startIndex, limit: rowPerPage },
        body: {},
      };
      const response = await makeAuthenticatedAPICall(apiParams);

      if (!response) {
        login?.logout();
      } else {
        setVariants(response.data);
      }
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVariant();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  const handleClick = (productId: number) => {
    // Navigate to another route and pass data using the state object
    // navigate("/products/Edit/" + productId, { state: { product: fill } });
    // navigate("/products/Edit/" + productId, { state: { prod: fill } });

    const fill = product;
    navigate("/admin/products/" + productId + "/variant", {
      state: { prod: fill },
    });
  };
  const handleEditClick = (variantID: number) => {
    // const fill = products?.find((product) => product.id === productId);
    const fill = variants?.find((i) => i.id === variantID);
    navigate("/admin/products/" + fill?.product_id + "/variant/" + fill?.id, {
      state: { variant: fill },
    });
  };

  return (
    <div className="flex flex-1 flex-col border-gray-200 bg-white h-screen pb-[6rem]">
      <div className="p-4 flex justify-between items-center">
        <strong className="text-gray-700 font-medium">Variant List</strong>
        <Button onClick={() => handleClick(product.id ?? 0)}>
          <FaPlus className="mr-2 h-4 w-4" /> New Variant
        </Button>
      </div>

      <Table>
        <TableCaption>A list of your recent Product.</TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[100px]">ID</TableHead>
            <TableHead>Image</TableHead>
            <TableHead>Variant</TableHead>
            <TableHead className="w-[100px]">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {variants?.map((variant) => (
            <TableRow key={variant.id}>
              <TableCell className="font-medium">{variant.id}</TableCell>
              <TableCell>
                <img
                  src={variant.img_url}
                  className="mx-auto w-10 items-center"
                  alt={variant.img_url}
                />
              </TableCell>
              <TableCell>{variant.variant_type}</TableCell>
              <TableCell className="flex flex-row gap-4">
                <Button
                  variant="outline"
                  onClick={() => handleEditClick(variant.id)}
                >
                  Edit
                </Button>
                <Button variant="destructive">Disable/Enable</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>

            <TableCell colSpan={7}>
              <div className="flex flex-row justify-center gap-x-10">
                <Button
                  className={
                    startIndex === 0
                      ? "pointer-events-none opacity-50"
                      : undefined
                  }
                  onClick={() => {
                    prevPage();
                  }}
                >
                  Prev
                </Button>
                <Button
                  onClick={() => {
                    nextPage();
                  }}
                >
                  Next
                </Button>
              </div>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
};

export default VariantList;

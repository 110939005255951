import BuyerProfileChart from "@/components/BuyerProfileChart";
import DashboardStatsGrid from "@/components/DashboardStatsGrid";
import PopularProduct from "@/components/PopularProduct";
import RecentOrders from "@/components/RecentOrders";
import TransactionChart from "@/components/TransactionChart";

export const DashboardPage = () => {
  return (
    <div className="flex flex-col gap-4">
      <DashboardStatsGrid />
      <div className="flex flex-row gap-4 w-full ">
        <TransactionChart />
        <BuyerProfileChart />
      </div>
      <div className="flex flex-row gap-4 w-full">
        <RecentOrders />
        <PopularProduct />
      </div>
    </div>
  );
};

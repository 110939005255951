import { RiderRate, StoreRate } from "@/interfaces/CommisionModel";
import { fetchCommission } from "@/services/CommissionServices";
import { useEffect, useState } from "react"
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";
import { Button } from "../ui/button";
import { useNavigate } from 'react-router';

function RateAndCommissions() {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [riderRate, setRiderRate] = useState<RiderRate[]>()
    const [storeRate, setStoreRate] = useState<StoreRate[]>()
    const navigate = useNavigate();

    const loadComissions = async () => {
        try {

            const result = await fetchCommission();

            setRiderRate(result.rider_rate)
            setStoreRate(result.store_rate)
        } catch (error) {
            setError((error as Error).message);
        } finally {
            setLoading(false);
        }
    }

    const handleEditClick = () => {

        const fill = riderRate ? riderRate[0] : undefined;

        navigate("/admin/commissions/edit", { state: { riderRateD: fill, storeRated: storeRate } })

    }

    useEffect(() => {
        loadComissions();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Error: {error}</div>;
    }


    return (
        <div className="bg-white p-4 rounded-sm border border-gray-200 flex flex-col flex-1 h-screen gap-2">
            <div className="flex flex-row justify-between items-center">
                <strong className="text-gray-700 font-medium">Commission Rate</strong>
                <Button onClick={() => handleEditClick()}>
                    Edit
                </Button>
            </div>
            <div className="flex flex-1">
                <Table>
                    <TableCaption>A list of Store Commission Rate.</TableCaption>
                    <TableHeader>
                        <TableRow>
                            <TableHead className="w-[100px]">ID</TableHead>
                            <TableHead>Initial km</TableHead>
                            <TableHead>initial km rate</TableHead>
                            <TableHead>success km tick</TableHead>
                            <TableHead>Success km rate</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {riderRate?.map((i, index) => (
                            <TableRow key={index}>
                                <TableCell className="font-medium">{index}</TableCell>
                                <TableCell>{i.initial_km}</TableCell>
                                <TableCell>{i.initial_km_rate}</TableCell>
                                <TableCell>{i.succ_km_tick}</TableCell>
                                <TableCell>{i.succ_km_rate}</TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
            <div className="flex flex-1">

                <Table>
                    <TableCaption>A list of Rider Commission Rate.</TableCaption>
                    <TableHeader>
                        <TableRow>
                            <TableHead className="w-[100px]">ID</TableHead>
                            <TableHead>Commission Type</TableHead>
                            <TableHead>Commission Rate</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {storeRate?.map((i, index) => (
                            <TableRow key={index}>
                                <TableCell className="font-medium">{index}</TableCell>
                                <TableCell>{i.com_type}</TableCell>
                                <TableCell>{i.com_rate}</TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </div>
    )
}

export default RateAndCommissions
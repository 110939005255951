interface gmail_svgProps {
  width: string;
  height: string;
}

const Gmail_svg: React.FC<gmail_svgProps> = ({ width, height }) => {
  const containerStyle: React.CSSProperties = {
    width: width,
    height: height,
  };

  return (
    <div style={containerStyle}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
      >
        <path
          fill="#4285F4"
          d="M23.761 12.273c0-.819-.067-1.634-.207-2.441H12.237v4.62h6.48a5.55 5.55 0 0 1-2.395 3.646v3.001h3.868c2.265-2.085 3.571-5.174 3.571-8.826z"
        />
        <path
          fill="#34A853"
          d="M12.237 24c3.238 0 5.966-1.062 7.953-2.898l-3.868-3.001c-1.077.73-2.462 1.147-4.085 1.147-3.129 0-5.786-2.109-6.736-4.952H1.518v3.092A11.993 11.993 0 0 0 12.237 24z"
        />
        <path
          fill="#FBBC04"
          d="M5.502 14.297a7.192 7.192 0 0 1 0-4.593V6.612H1.518a12.001 12.001 0 0 0 0 10.778l3.984-3.093z"
        />
        <path
          fill="#EA4335"
          d="M12.237 4.752a6.518 6.518 0 0 1 4.602 1.799l3.427-3.424A11.545 11.545 0 0 0 12.237.001c-4.535 0-8.684 2.56-10.72 6.611l3.984 3.092c.95-2.843 3.608-4.952 6.736-4.952z"
        />
      </svg>
    </div>
  );
};

export default Gmail_svg;

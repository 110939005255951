import { ProductBetaRateModel } from "@/interfaces/ProductRateModel";
import { useLocation, useNavigate } from "react-router";
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../ui/form";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { APICallParams, makeWithOutAuthenticatedAPICall } from "@/utils/apiCall";
import { toast } from "../ui/use-toast";

const FormSchema = z.object({
    // branchID: z.string(),
    product_name: z.string(),
    orig_price: z.string()
    ,
    // discount_price: z.string(),
    // promo_id: z.string(),
    // is_enabled: z.boolean()
})

export default function EProductRate() {
    const location = useLocation();
    const productRateData: ProductBetaRateModel = location.state.productPriceData;
    const navigate = useNavigate();

    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            product_name: productRateData.product_name ?? "",
            orig_price: productRateData.orig_price ?? 0
        }

    })


    const handleUpdateClick = (data: z.infer<typeof FormSchema>) => {
        if (productRateData) {
            UpdatePrice(productRateData.branch_id, productRateData.product_id, data.orig_price)

        }
    }



    const UpdatePrice = async (branchID: number, productID: number, rate: string) => {
        try {
            const apiParams: APICallParams = {
                verb: "PUT",
                url: "/v1/wet_market/branches/" + branchID + "/products/" + productID,
                params: { b_id: branchID, p_id: productID },
                body: {
                    orig_price: rate,
                    discount_price: rate,
                    promo_id: 0,
                    is_enabled: true
                },
            };
            const response = await makeWithOutAuthenticatedAPICall(apiParams)

            if (response.status !== 204) {
                toast({
                    variant: "destructive",
                    title: "Branch Product Rate/Price",
                    description: response.response.data.message,
                });
            } else {
                productRateData.orig_price = rate
                productRateData.discounted_price = rate
                toast({
                    title: "Branch Product Rate/Price",
                    description: "successfully Update",
                });
                const fill = productRateData
                navigate("/branch/" + branchID + "/products/" + productID, { state: { productRateData: fill } })

            }
        } catch (error) {
            throw new Error(`Error fetching data: ${error}`);
        }
    }

    return (
        <div className="bg-white p-2 rounded-sm border border-gray-200 flex flex-col flex-1 h-screen gap-5">
            <strong className="text-gray-700 font-medium ">Update Product Rate/Pricing</strong>
            <Form {...form}>
                <form className="flex flex-col gap-5 w-1/4"
                    onSubmit={form.handleSubmit(handleUpdateClick)}>
                    <FormField
                        control={form.control}
                        name="product_name"
                        render={({ field }) => {
                            return (
                                <FormItem>
                                    <FormLabel>Product Name</FormLabel>
                                    <FormControl>
                                        <Input placeholder="product Name" type="text" {...field} autoComplete="off" />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            );
                        }}
                    />
                    <FormField
                        control={form.control}
                        name="orig_price"
                        render={({ field }) => {
                            return (
                                <FormItem>
                                    <FormLabel>Rate/Price</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Rate/Price" type="number" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            );
                        }}
                    />
                    <Button>
                        Update
                    </Button>
                </form>
            </Form>

        </div>
    )
}

import AccountEdit from "@/components/accounts/AccountEdit";
import { Outlet } from "react-router";


export default function UserLayout() {
    return (
        <div className="bg-white p-4 rounded-sm border border-gray-200 flex flex-col flex-1 h-screen gap-5">
            <strong className="text-gray-700 font-medium">Account Details</strong>
            <AccountEdit />
            <div className="flex flex-1">
                <Outlet />
            </div>

        </div>
    );
}
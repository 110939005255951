"use client";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import Gmail_svg from "@/assets/svg_components/gmail_svg";
import sha256 from "crypto-js/sha256";
import { useAuth } from "@/hooks/useAuth";
import Fb_svg from "@/assets/svg_components/fb_svg";
import Viber_svg from "@/assets/svg_components/viber_svg";
import { useNavigate } from "react-router";
import { ChangeEvent, useEffect, useState } from "react";
import { TokenData } from "@/data/TokenData";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { APICallParams, makeLoginAuthenticatedAPICall, makeWithOutAuthenticatedAPICall } from "@/utils/apiCall";
import { useToast } from "@/components/ui/use-toast";
const formSchema = z.object({
  emailAddress: z.string().min(4),
  password: z.string().min(6),
});

export const Login = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const login = useAuth();
  const [token, setToken] = useState<TokenData>();
  const [pin, setPin] = useState<string>("");
  const [isDisabled, setIsDisabled] = useState(false);

  const handleMobileNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPin(event.target.value);
  };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      emailAddress: "",
    },
  });

  const handleSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      setIsDisabled(true);
      setTimeout(() => {
        setIsDisabled(false);
      }, 2000); // 30 seconds
      setPin("");
      const salt1 = values.password.substring(0, 3);
      const salt2 = values.password.substring(
        values.password.length - 3,
        values.password.length
      );
      const combinedPassword = salt2 + values.password + salt1;
      const digest = sha256(combinedPassword);


      const formData = new FormData();
      formData.append("username", values.emailAddress);
      formData.append("password", digest.toString());
      // const res = await fetch(`${API_BASE_URL}/v1/auth/admin/login`, {
      //   method: "POST",
      //   headers,
      //   body: formData,
      // });
      const apiParams: APICallParams = {
        verb: "POST",
        url: "/v1/auth/admin/login",
        params: {},
        body: formData,
      };
      const res = await makeWithOutAuthenticatedAPICall(apiParams)

      if (res.status !== 201) {
        // const message = "An error has occured";
        toast({
          variant: "destructive",
          title: "Login Failed",
          description: res.response.data.message,
        });
        // throw new Error(message);
      } else {
        const data = res.data
        if (!data) {
          navigate("/");
        }
        setToken(data);
      }
    } catch (error) {
      console.log(error)
    }
  };



  const handleSubmitOtp = async () => {
    if (token) {
      let response = await makeLoginAuthenticatedAPICall(
        "POST",
        "/v1/auth/admin/verify_otp",
        {
          pin: pin,
        },
        {},
        token.access_token
      );
      if (response.status === 201) {
        localStorage.setItem("login_token", token.access_token);
        await login?.login(response.data);

      } else {
        toast({
          title: "invalided OTP",
          description: response.response.data.message,
        });
      }
    }
  };


  if (token) {
    return (
      <Dialog open={true}>
        {/* <DialogTrigger asChild={true}>
          <Button className="w-full font-bold" type="submit">
            Login
          </Button>
        </DialogTrigger> */}
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Enter MobileOTP</DialogTitle>

            <DialogDescription>
              The OTP is received in this number {token.mobile}
            </DialogDescription>
          </DialogHeader>

          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="name" className="text-right">
                Mobile OTP
              </Label>
              <Input
                // id="name"
                value={pin}
                onChange={handleMobileNumberChange}
                className="col-span-3"
              />
            </div>
          </div>
          <DialogFooter>
            <Button onClick={() => handleSubmitOtp()}>Verify</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    )
  }
  if (login?.user) {
    useEffect(() => {
      if (login?.user.title_id <= 2) {
        navigate("/admin");
      } else if (login?.user.title_id <= 4 && login?.user.title_id >= 3) {
        navigate("/branch");
      }
    })
  }

  return (
    <div className="flex justify-center items-center w-screen h-screen">
      <Card className="  flex flex-col justify-center">
        <CardHeader>
          <CardTitle>
            <div className="grid md:grid-cols-7 gap-2">
              <div className="xl:col-span-6 justify-self-end">New user?</div>
              <div className="justify-end"> Sign Up</div>
            </div>
            <p className="font-bold ">Sign In</p>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(handleSubmit)}
              className="max-w-md w-full flex flex-col gap-4"
            >
              <FormField
                control={form.control}
                name="emailAddress"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Email Address</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Email Address or Username"
                          type="text"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Password</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="password"
                          type="password"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />

              <div className="grid grid-flow-col justify-items-end text-gray-500">
                forgot your password?
              </div>

              <Button className="w-full font-bold" type="submit" disabled={isDisabled}>
                {isDisabled ? 'Loading..' : 'Login'}
              </Button>

              {/* <Dialog>
                <DialogTrigger asChild>
                  <Button className="w-full font-bold" type="submit">
                    Login
                  </Button>
                </DialogTrigger>
                <DialogContent className="sm:max-w-[425px]">
                  <DialogHeader>
                    <DialogTitle>Enter MobileOTP</DialogTitle>

                    <DialogDescription>
                      Make changes to your profile here. Click save when you're
                      done.
                    </DialogDescription>
                  </DialogHeader>

                  <div className="grid gap-4 py-4">
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="name" className="text-right">
                        Mobile OTP
                      </Label>
                      <Input
                        // id="name"
                        value={pin}
                        onChange={handleMobileNumberChange}
                        className="col-span-3"
                      />
                    </div>
                  </div>
                  <DialogFooter>
                    <Button onClick={() => handleSubmitOtp()}>Verify</Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog> */}
            </form>
          </Form>

          <div className="grid grid-flow-col justify-items-center italic pt-5 text-gray-400 ">
            <Gmail_svg width="40px" height="40px" />
            <Fb_svg width="40px" height="40px" />
            <Viber_svg width="40px" height="40px" />
          </div>
        </CardContent>
      </Card>

    </div>
  );
};

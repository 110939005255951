import { useLocation } from "react-router";
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";
import { useEffect, useState } from "react";
import { OrdersModel } from "@/interfaces/OrdersModel";
import { APICallParams, makeAuthenticatedAPICall, makeWithOutAuthenticatedAPICall } from "@/utils/apiCall";
import { OrderStatusData } from "@/data/OrderData";
import { Button } from "../ui/button";
import { toast } from "../ui/use-toast";
import { useAuth } from "@/hooks/useAuth";
import { useNavigate } from "react-router";

export default function OrderReceived() {
    const login = useAuth();
    const location = useLocation();
    const sBrandID: number = location.state.sBrandID;
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [orderReceived, setoOderReceived] = useState<OrdersModel[]>()
    const navigate = useNavigate();



    const handleAcceptClick = async (orderID: number) => {
        try {
            const apiParams: APICallParams = {
                verb: "POST",
                url: "/wet_market/order_logs_update",
                params: {},
                body: {
                    orderid: orderID,
                    statusid: 3,
                    userid: login?.user.admin_id /// need asign userid
                },
            };

            const response = await makeWithOutAuthenticatedAPICall(apiParams);


            if (response.status !== 200) {
                toast({
                    variant: "destructive",
                    title: "Order",
                    description: "FaildAccept Order",
                });
            } else {
                toast({
                    title: "Order",
                    description: "Accepted Order",
                });
                loadOrderReceived()
            }
        } catch (error) {
            throw new Error(`Error fetching data: ${error}`);
        }

    }

    // const handleSetItems = (orderID: number) => {
    //     const fill = orderReceived?.find((i) => i.order_id === orderID)
    //     const data = fill?.items_list
    //     if (data) {
    //         setItems(data)
    //     }
    // }

    const handleViewClick = (orderID: number) => {
        const fill = orderReceived?.find((i) => i.order_id === orderID)
        const data = fill?.items_list
        navigate("/branch/" + sBrandID + "/order_received/" + orderID, { state: { sOrderID: orderID, itmsData: data, sBrandID: sBrandID } })
    }

    const loadOrderReceived = async () => {
        try {
            const apiParams: APICallParams = {
                verb: "POST",
                url: "/wet_market/order_load",
                params: {},
                body: { branchid: sBrandID, statusid: 2 },
            };

            const response = await makeAuthenticatedAPICall(apiParams);

            const newdata = JSON.parse(response.data[0].items)
            setoOderReceived(newdata)
        } catch (error) {
            setError((error as Error).message);
        } finally {
            setLoading(false);
        }
    }

    function getStatusName(statusid: number) {
        const data = OrderStatusData.find((i) => i.id === statusid)
        return data?.status
    }

    useEffect(() => {
        loadOrderReceived()

    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }
    return (
        <div className="h-screen w-full bg-white p-4 rounded-sm border border-gray-200 flex flex-col">
            <Table>
                <TableCaption>A list of order received.</TableCaption>
                <TableHeader>
                    <TableRow>
                        <TableHead className="w-[100px]">ID</TableHead>
                        <TableHead>Address</TableHead>
                        <TableHead>Paid</TableHead>
                        <TableHead>Status</TableHead>
                        <TableHead className="w-[100px]">Actions</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {orderReceived?.map((i) => (
                        <TableRow key={i.order_id}>
                            <TableCell className="font-medium">{i.order_id}</TableCell>
                            <TableCell>{i.address}</TableCell>
                            <TableCell>{i.is_paid}</TableCell>
                            <TableCell>{getStatusName(i.order_status)}</TableCell>
                            <TableCell>
                                <div className="flex flex-row justify-center gap-x-10">
                                    {/* <ReactToPrint
                                        trigger={() =>
                                            (<Button onClick={() => handleSetItems(i.order_id)}>Print Receipt</Button>)
                                        }
                                        content={() => receiptRef.current}
                                        documentTitle="new document"
                                        pageStyle='print'
                                    />
                                    <div className="hidden">
                                        <Receipt
                                            ref={receiptRef}
                                            address={receiptData.address}
                                            contact={receiptData.contact}
                                            email={receiptData.email}
                                            date={receiptData.date}
                                            time={receiptData.time}
                                            cashier={receiptData.cashier}
                                            branch={receiptData.branch}
                                            items={receiptData.items}
                                            price={receiptData.price}
                                            message={receiptData.message}
                                        />
                                    </div> */}
                                    <Button onClick={() => handleAcceptClick(i.order_id)}>Process</Button>
                                    <Button variant="outline" onClick={() => handleViewClick(i.order_id)}>
                                        View
                                    </Button>
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}

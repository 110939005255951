import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../ui/form";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { APICallParams, makeAuthenticatedAPICall } from "@/utils/apiCall";
import { toast } from "../ui/use-toast";

const formSchema = z.object({
    api_Action: z.string().min(1),
    api_action_group: z.string().min(1)
})

function Permission() {



    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            api_Action: "",
            api_action_group: ""
        },
    });

    const handleSaveClick = (data: z.infer<typeof formSchema>) => {

        savePermission({ api_action: data.api_Action, api_action_group: data.api_action_group })

    }

    const savePermission = async (paramValue: any) => {
        try {
            const uri = "/v1/permission/"
            // const encoded = encodeURI(uri);
            const apiParams: APICallParams = {
                verb: "POST",
                url: uri,
                params: {},
                body: paramValue,
            };
            const response = await makeAuthenticatedAPICall(apiParams);

            if (response.status === 201) {
                toast({
                    title: "Permission",
                    description: "Successfully Save.",
                });
            } else {
                toast({
                    title: "Permission",
                    description: "Failed to Save.",
                });
            }

        } catch (error) {
            console.error("Error during load premissions:", error);
        }
    }

    return (
        <div className="h-screen w-full bg-white p-4 rounded-sm border border-gray-200 flex flex-col items-center gap-5">
            <strong className="flex flex-row text-gray-700 font-medium pt-10">
                new permission
            </strong>

            <Form {...form}>
                <form
                    className="w-1/2 shadow-md p-5 "
                    onSubmit={form.handleSubmit(handleSaveClick)}
                >
                    <FormField
                        control={form.control}
                        name="api_Action"
                        render={({ field }) => {
                            return (
                                <FormItem className="w-full px-10 py-2">
                                    <FormLabel>API Action</FormLabel>
                                    <FormControl>
                                        <Input
                                            placeholder="API Actions"
                                            type="text"
                                            autoComplete="off"
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            );
                        }}
                    />
                    <FormField
                        control={form.control}
                        name="api_action_group"
                        render={({ field }) => {
                            return (
                                <FormItem className="w-full px-10 py-2">
                                    <FormLabel>API Action Group</FormLabel>
                                    <FormControl>
                                        <Input
                                            placeholder="API Action Group"
                                            type="text"
                                            autoComplete="off"
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            );
                        }}
                    />
                    <div className="px-10">

                        <Button className="flex flex-row w-full">
                            Save
                        </Button>
                    </div>
                </form>
            </Form>

        </div>
    )
}

export default Permission

function Applicants() {
    return (
        <div className="bg-white p-4 rounded-sm border border-gray-200 flex flex-col flex-1 h-screen gap-2">

            <strong className="text-gray-700 font-medium">Coming soon..</strong>
        </div>
    )
}

export default Applicants
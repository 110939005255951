import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { API_BASE_URL } from "./constant";

type RequestParams = Omit<AxiosRequestConfig, "url"> & { url: string };

const request = async <T,>({
  url,
  params,
  ...rest
}: RequestParams & { params?: Record<string, any> }): Promise<T> => {
  const baseURL = API_BASE_URL.concat(url);
  try {
    const response: AxiosResponse<T> = await axios({
      baseURL,
      params,
      ...rest,
    });
    if (response.status == 401) {
    }

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      throw new Error(
        `Request failed with status ${axiosError.response?.status}: ${axiosError.response?.data}`
      );
    } else {
      throw new Error(`Request failed: ${error}`);
    }
  }
};

export default request;


import { useEffect, useState } from "react";
import { Button } from "../ui/button";
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";
import { APICallParams, makeAuthenticatedAPICall, makeWithOutAuthenticatedAPICall } from "@/utils/apiCall";
import { OrdersModel } from "@/interfaces/OrdersModel";
import { OrderStatusData } from "@/data/OrderData";
import { useNavigate } from "react-router";
import { useLocation } from "react-router";
import { toast } from "../ui/use-toast";
import { useAuth } from "@/hooks/useAuth";

export default function Orders() {
    const login = useAuth();
    const location = useLocation();
    const sBrandID: number = location.state.sBrandID;
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [order, setOrders] = useState<OrdersModel[]>()
    // const [isDisabled, setIsDisabled] = useState(false);
    const navigate = useNavigate();

    // const loadOrders = async () => {
    //     try {
    //         const apiParams: APICallParams = {
    //             verb: "POST",
    //             url: "/wet_market/order_load",
    //             params: {},
    //             body: { branchid: 1, statusid: 0 },
    //         };

    //         const response = await makeAuthenticatedAPICall(apiParams);
    //         if (response.status !== 200) {
    //             toast({
    //                 title: "Orders",
    //                 description: response.data.message,
    //             });
    //         } else {
    //             setItemWithExpiry('lOrders', response.data, 3600000)
    //         }

    //     } catch (error) {
    //         setError((error as Error).message);
    //     } finally {
    //         setLoading(false);
    //     }
    // }

    const handleViewClick = (orderID: number) => {
        const fill = order?.find((i) => i.order_id === orderID)
        const data = fill?.items_list
        navigate("/branch/" + sBrandID + "/orders/" + orderID, { state: { itmsData: data } })
    }

    const handleAcceptClick = async (orderID: number) => {
        // toast({
        //     // variant: "destructive",
        //     title: "Order",
        //     description: "Accept Ordder",
        // });
        // updateStatus()
        try {
            const apiParams: APICallParams = {
                verb: "POST",
                url: "/wet_market/order_logs_update",
                params: {},
                body: {
                    orderid: orderID,
                    statusid: 2,
                    userid: login?.user.admin_id /// need asign userid
                },
            };

            const response = await makeWithOutAuthenticatedAPICall(apiParams);


            if (response.status !== 200) {
                toast({
                    variant: "destructive",
                    title: "Order",
                    description: "Accept Order",
                });
            } else {
                toast({
                    title: "Order",
                    description: "Accepted Order",
                });
                loadOrders()
            }
        } catch (error) {
            throw new Error(`Error fetching data: ${error}`);
        }
    }

    const loadOrders = async () => {
        try {
            const apiParams: APICallParams = {
                verb: "POST",
                url: "/wet_market/order_load",
                params: {},
                body: { branchid: sBrandID, statusid: 1 },
            };

            const response = await makeAuthenticatedAPICall(apiParams);

            const newdata = JSON.parse(response.data[0].items)
            setOrders(newdata)
        } catch (error) {
            setError((error as Error).message);
        } finally {
            setLoading(false);
        }
    }

    function getStatusName(statusid: number) {
        const data = OrderStatusData.find((i) => i.id === statusid)
        return data?.status
    }

    useEffect(() => {
        // const storedOrders = getItemWithExpiry<string[]>('lOrders');
        // if (storedOrders !== null) {
        //     setOrders(storedOrders)
        // } else {
        //     loadOrders()
        // }
        loadOrders()
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    // const handleClick = () => {
    //     setIsDisabled(true);
    //     setTimeout(() => {
    //         setIsDisabled(false);
    //     }, 1000); // 30 seconds
    // };

    return (
        <div className="h-screen w-full bg-white p-4 rounded-sm border border-gray-200 flex flex-col pb-[5rem]">
            {/*        
            <Button onClick={() => handleClick()} disabled={isDisabled}>
                {isDisabled ? 'Loading..' : 'Click Me'}
            </Button> */}
            <Table>
                <TableCaption>A list of your Product.</TableCaption>
                <TableHeader>
                    <TableRow>
                        <TableHead className="w-[100px]">ID</TableHead>
                        <TableHead>Address</TableHead>
                        <TableHead>Paid</TableHead>
                        <TableHead>Status</TableHead>
                        <TableHead className="w-[100px]">Actions</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {order?.map((i) => (
                        <TableRow key={i.order_id}>
                            <TableCell className="font-medium">{i.order_id}</TableCell>
                            <TableCell>{i.address}</TableCell>
                            <TableCell>{i.is_paid}</TableCell>
                            <TableCell>{getStatusName(i.order_status)}</TableCell>
                            <TableCell>
                                <div className="flex flex-row justify-center gap-x-10">
                                    <Button onClick={() => handleAcceptClick(i.order_id)}>Accept</Button>
                                    <Button variant="outline" onClick={() => handleViewClick(i.order_id)}>
                                        View
                                    </Button>
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}
